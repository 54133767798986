import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, TouchableOpacity, Image, Text, View, StyleSheet, ScrollView, useWindowDimensions, Platform, FlatList, SafeAreaView } from 'react-native';
import { MaterialCommunityIcons, Ionicons, Octicons } from '@expo/vector-icons';
import { connect } from 'react-redux';
import { useIsFocused, useNavigation, useScrollToTop } from '@react-navigation/native';

import API from '../../libs/API';
import StorageService from '../../libs/Storage';

import FeedScroller from './FeedScroller';
import ThemeSong from './ThemeSong';

import PostMedia from '../Post/PostMedia';
import PostFooter from '../Post/PostFooter';

import { getDiscoverPage, getFeed, getFeedPage, setActiveFeedIndex, setActiveFeedItem, setActiveChannel } from '../../actions/FeedActions';
import { setTabStyle } from '../../actions/SettingsActions';
import DiscoverTools from './DiscoverTools';
import { useRef } from 'react';
import { prettyTime } from '../../libs/Date';
import FeedMedia from './FeedMedia';
import FeedText from './FeedText';
import FeedLink from './FeedLink';
import FeedNewUser from './FeedNewUser';
import PostLink from '../Post/PostLink';
import PostNewUser from '../Post/PostNewUser';
import InViewport from '../common/InViewport';
import PostThemeSong from './PostThemeSong';
import PeopleOnSlyde from '../common/PeopleOnSlyde';
import { StatusBar } from 'expo-status-bar';
import events from '../../services/EventService';
import TextView from '../common/TextView';
import ContactSync from '../common/ContactSync';
import TwitterTextView from '../common/TextView';

const Feed = (props) => {
    const [fillWidth, setFillWidth] = useState(0);
    const [fillHeight, setFillHeight] = useState(0);
    const [activeItem, setActiveItem] = useState(null);
    const [activeDiscoverItem, setActiveDiscoverItem] = useState(null);
    const [page, setPage] = useState(1);
    const [feed, setFeed] = useState([]);
    const [timeline, setTimeline] = useState([]);
    const [channelFeed, setChannelFeed] = useState([]);
    const [timelinePage, setTimelinePage] = useState(1);
    const [channelPage, setChannelPage] = useState(null);
    const [showDiscoverFade, setShowDiscoverFade] = useState(false);
    const [feedType, setFeedType] = useState('discover');
    const [discoverFeed, setDiscoverFeed] = useState([]);
    const [discoverPage, setDiscoverPage] = useState(1);
    const [focused, setFocused] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [refreshingFeed, setRefreshingFeed] = useState(false);
    const [refreshingDiscover, setRefreshingDiscover] = useState(false);
    const [refreshingChannel, setRefreshingChannel] = useState(false);
    const [lastStyle, setLastStyle] = useState(null);
    const [ready, setReady] = useState(false);
    const feedRef = useRef();
    const discoverFeedRef = useRef();
    const channelFeedRef = useRef();
    const [feedSettingsType, setFeedSettingsType] = useState(props.feedSettings.type);
    useScrollToTop(feedRef);
    useScrollToTop(discoverFeedRef);
    const discoverRefs = useRef({});
    const feedRefs = useRef({});
    const currentlyPlaying = useRef(null);
    const traditionDiscoverLastHighlight = useRef(null);
    const fullDiscoverLastHighlight = useRef(null);
    const traditionalFeedLastHighlight = useRef(null);
    const fullFeedLastHighlight = useRef(null);
    const lastItem = useRef('people');
    const viewedPosts = useRef({});
    const lastInViewPosts = useRef({});

    const isFocused = useIsFocused();

    const navigation = useNavigation();

    const onViewRef = React.useRef(({ viewableItems }) => {
        if (viewableItems[0]) {
            setActiveItem(viewableItems[0]);
            props.setActiveFeedItem(viewableItems[0].item);
        } else {
            setActiveItem(null);
            props.setActiveFeedItem(null);
        }
        // Use viewable items in state or as intended
    })
    const viewConfigRef = React.useRef({ viewAreaCoveragePercentThreshold: 50 })

    const onViewDiscoverRef = React.useRef(({ viewableItems }) => {
        //console.log(viewableItems);
        for (let listItem of viewableItems) {
            if (!lastInViewPosts.current[listItem.item.id]) {
                lastInViewPosts.current[listItem.item.id] = listItem.item;
                handlePostViewed(true, listItem.item);
            } else {
                delete lastInViewPosts.current[listItem.item.id];
                handlePostViewed(false, listItem.item);
            }
        }
        // Use viewable items in state or as intended
    })
    const viewConfigDiscoverRef = React.useRef({ viewAreaCoveragePercentThreshold: 0 });

    const refreshFeed = () => {
        setPage(null);
        setFeed([]);
        setDiscoverFeed([]);
        setDiscoverPage(null);
        setTimelinePage(null);
        setChannelPage(null);
        setChannelFeed([]);
        setTimeline([]);
    }

    useEffect(() => {
        const tabPressUnsub = navigation.addListener('tabPress', e => {
            // Prevent default action
        });

        const newPostListener = events.subscribe('post.new', (topic, data) => {
            refreshFeed();
        });

        const updatedPostListener = events.subscribe('post.update', (topic, data) => {
            refreshFeed();
        });

        const getViewed = async () => {
            let viewed = await StorageService.get("walkthrough");

            if (!viewed) {
                //navigation.navigate("Welcome");
            }

            setReady(true);
        }

        getViewed();

        return () => {
            tabPressUnsub();
            events.unsubscribe(newPostListener);
            events.unsubscribe(updatedPostListener);
        }
    }, []);

    useEffect(() => {
        if (isFocused) {
            setFocused(true);
            props.setTabStyle(lastStyle);
            props.setActiveChannel(null);
        } else {
            setFocused(false);
            props.setTabStyle(null);
        }
    }, [isFocused]);

    useEffect(() => {
        if (props.route.params && props.route.params.refresh) {
            refreshFeed();
        }
    }, [props.route.params]);

    useEffect(() => {
        //props.getFeed(1);
        if (discoverPage == null) {
            setDiscoverPage(1);
            traditionDiscoverLastHighlight.current = null;
        } else {
            API.get(`/api/v1/discover/feed?page=${discoverPage}`).then((response) => {
                if (response.error) {
                    //dispatch(setFeedErrorState(response.error));
                    return;
                }

                let newFeed = response.posts;

                let highlightPost = {
                    post_type: 'sync-contacts'
                };

                if (newFeed.length > 0) {
                    if (discoverFeed.length && discoverFeed[discoverFeed.length - 1]?.post_type === 'sync-contacts') {
                        highlightPost = {
                            post_type: 'people-on-slyde'
                        };
                    }

                    newFeed.push(highlightPost);
                }

                if (discoverPage == 1) {
                    setDiscoverFeed(newFeed);
                } else {
                    setDiscoverFeed([...discoverFeed, ...newFeed]);
                }

                setRefreshingDiscover(false);
            }, (err) => {
                //dispatch(setDiscoverErrorState('error.network'));
                //dispatch(setDiscoverLoadingState(false));
                setRefreshingDiscover(false);
            });
        }
    }, [discoverPage]);

    useEffect(() => {
        //props.getFeed(1);
        if (channelPage == null) {
            setChannelPage(1);
            lastItem.current = null;
        } else {
            API.get(`/api/v1/discover/mychannels/feed?page=${channelPage}`).then((response) => {
                if (response.error) {
                    //dispatch(setFeedErrorState(response.error));
                    return;
                }

                let newFeed = response.posts;

                let highlightPost = {
                    post_type: 'sync-contacts'
                };

                if (newFeed.length > 0) {
                    if (channelFeed.length && channelFeed[channelFeed.length - 1]?.post_type === 'sync-contacts') {
                        highlightPost = {
                            post_type: 'people-on-slyde'
                        };
                    }

                    newFeed.push(highlightPost);
                }

                if (channelPage == 1) {
                    setChannelFeed(newFeed);
                } else {
                    setChannelFeed([...channelFeed, ...newFeed]);
                }

                setRefreshingChannel(false);
            }, (err) => {
                setRefreshingChannel(false);
            });
        }
    }, [channelPage]);

    useEffect(() => {
        if (page == null) {
            setPage(1);
            fullFeedLastHighlight.current = null;
        } else {
            API.get(`/api/v1/feed?page=${page}`).then((response) => {
                if (props.feedSettings.type != "full") {
                    return;
                }
                if (response.error) {
                    //dispatch(setFeedErrorState(response.error));
                    return;
                }

                let newFeed = response.feed.map((c) => {
                    c.style = JSON.parse(c.style);
                    if (c.style.color && !c.style.fill) {
                        c.style.fill = c.style.color;
                    }
                    if (c.profile_pic_crop) {
                        c.profile_pic_crop = JSON.parse(c.profile_pic_crop);
                    }
                    return c;
                });

                let highlightPost = {
                    post_type: 'sync-contacts'
                };

                if (newFeed.length > 0) {
                    if (feed.length && feed[feed.length - 1]?.post_type === 'sync-contacts') {
                        highlightPost = {
                            post_type: 'people-on-slyde'
                        };
                    }

                    newFeed.push(highlightPost);
                }

                if (page == 1) {
                    setFeed(newFeed);
                } else {
                    setFeed([...feed, ...newFeed]);
                }

                setRefreshingFeed(false);
            }, (err) => {
                // error getting feed
                console.log('error', err);
                setRefreshingFeed(false);
            });
        }
    }, [page]);

    useEffect(() => {
        if (timelinePage == null) {
            setTimelinePage(1);
            traditionalFeedLastHighlight.current = null;
        } else {
            API.get(`/api/v1/feed/timeline?page=${timelinePage}`).then((response) => {
                if (response.error) {
                    //dispatch(setFeedErrorState(response.error));
                    return;
                }
                
                let newFeed = response.posts;

                let highlightPost = {
                    post_type: 'sync-contacts'
                };

                if (newFeed.length > 0) {
                    if (timeline.length && timeline[timeline.length - 1]?.post_type === 'sync-contacts') {
                        highlightPost = {
                            post_type: 'people-on-slyde'
                        };
                    }

                    newFeed.push(highlightPost);
                }

                if (timelinePage == 1) {
                    setTimeline(newFeed);
                } else {
                    setTimeline([...timeline, ...newFeed]);
                }

                setRefreshingFeed(false);
            }, (err) => {
                setRefreshingFeed(false);
                console.log("Error", err);
            });
        }
    }, [timelinePage]);

    useEffect(() => {
        setPage(1);
        setDiscoverPage(1);
        setChannelPage(1);

        if (discoverFeedRef.current && discoverFeed.length > 0) {
            discoverFeedRef.current.scrollToOffset({ animated: true, offset: 0 });
        }

        if (feedRef.current && feed.length > 0) {
            feedRef.current.scrollToOffset({ animated: true, offset: 0 });
        }

        if (channelFeedRef.current && channelFeed.length > 0) {
            channelFeedRef.current.scrollToOffset({ animated: true, offset: 0 });
        }
    }, [feedType]);

    useEffect(() => {
        if (props.feedSettings.type == 'traditional') {
            props.setTabStyle(null);
            setLastStyle(null);
        }

        refreshFeed();
    }, [props.feedSettings.type]);

    const setSizing = (size) => {
        setFillHeight(size.nativeEvent.layout.height);
        setFillWidth(size.nativeEvent.layout.width);
    }

    const checkScroll = (e) => {
        // e.nativeEvent.contentOffset.y
        let activeSlide = Math.round(e.nativeEvent.contentOffset.y / fillHeight);
        props.setActiveFeedIndex(activeSlide);
    }

    const updateTabs = (visible, user) => {
        if (visible && isFocused) {
            props.setTabStyle(user?.style);
            setLastStyle(user?.style);
        }
    }

    const handlePostViewed = (inView, post, context) => {
        let viewTime = new Date();
        if (inView && isFocused) {
            viewedPosts.current[post.id] = {
                startTime: viewTime.getTime(),
                inView: true
            }
        }
        if (!inView && viewedPosts.current[post.id]?.inView) {
            let secondsViewed = viewedPosts.current[post.id] ? parseFloat((viewTime.getTime() - viewedPosts.current[post.id].startTime) / 1000) : 0;
            API.post(`/api/v1/post/${post.id}/analytics`, {
                seconds_viewed: secondsViewed,
                request_uuid: props.user.session_id,
                context_type: context ? context : "feed",
            }).then((r) => {
                //
            }, (err) => {
                console.log(err);
            });
            viewedPosts.current[post.id].inView = false;
        }
    }

    const follow = (user) => {
        if (!props.user?.id) {
            // popover at some point
            navigation.navigate('UserLogin');
            return;
        }

        API.post(`/api/v1/follow/${user.id}`).then((response) => {
            if (response.error) {
                alert("Something went wrong while trying to follow.");
                return;
            }

            alert(`You're now following @${user.username}`);
        }, (err) => {
            alert("Something went wrong while trying to follow.");
        });
    }

    const renderFullFeed = ({ item, index }) => {
        if (item.post_type) {
            switch(item.post_type) {
                case 'people-on-slyde':
                    return (
                        <InViewport onChange={(v) => { updateTabs(v, null); }} key={`discover-feed-item-full-${index}-container`} style={{ ...{ justifyContent: "center" }, width: fillWidth, height: fillHeight, zIndex: 1 }}>
                            <View style={{ backgroundColor: "white", justifyContent: "center", width: fillWidth, height: fillHeight, zIndex: 1, flexDirection: "column" }}>
                                <PeopleOnSlyde showTabs />
                            </View>
                        </InViewport>
                    );
                case 'sync-contacts':
                    return (
                        <InViewport onChange={(v) => { updateTabs(v, null); }} key={`discover-feed-item-full-${index}-container`} style={{ ...{ justifyContent: "center" }, width: fillWidth, height: fillHeight, zIndex: 1 }}>
                            <View style={{ backgroundColor: "white", justifyContent: "center", width: fillWidth, height: fillHeight, zIndex: 1, flexDirection: "column" }}>
                                {/* <ContactSync showTabs /> */}
                                <PeopleOnSlyde showTabs />
                            </View>
                        </InViewport>
                    )
            }
        }

        return (<FeedScroller updateTabs={updateTabs} key={item.id} width={fillWidth} height={fillHeight} feedItem={item} />);
    };

    const renderTraditionalDiscover = ({ item, index }) => {
        let feedItem = null;
        let post = item;
        let user = post.user;

        switch (post.post_type) {
            case 'new_user':
                feedItem = <Text>New User</Text>;
                break;
            case 'status':
                if (post.media.length > 0) {
                    feedItem = <PostMedia post={post} user={user} isFeed={true} isPost={false} activeItem={activeDiscoverItem} fit={true} disableTap={true} />
                } else {
                    feedItem = (
                        <View style={{ ...styles.text_post_container }}>
                            <TouchableOpacity onPress={(e) => { navigation.push("PostView", { postId: post.id }) }}>
                                <TextView
                                    style={{ fontSize: 24, padding: 15, paddingTop: 0, fontWeight: "600" }}
                                    hashtagStyle={{ fontWeight: 'bold', color: '#F72585' }}
                                    mentionStyle={{ fontWeight: 'bold', color: '#4361EE' }}
                                    numberOfLines={10}
                                    onPress={(e) => {navigation.push("PostView", { postId: post.id })}}
                                    onPressMention={(e, mention) => { e.stopPropagation(); navigation.push('Profile', { username: mention.trim().substring(1) }) }}
                                    onPressHashtag={(e, hashtag) => { e.stopPropagation(); navigation.push('Search', { terms: hashtag.trim() }) }}
                                >{post.post_media}</TextView>
                            </TouchableOpacity>
                            { post.channel_id != null &&
                                <TouchableOpacity onPress={(e) => { navigation.push("Channel", { channelId: post.channel_tag }) }} style={{ marginLeft: 17, marginBottom: 20 }}>
                                    <TwitterTextView
                                        style={{ ...styles.caption_text, fontSize: 16 }}
                                        hashtagStyle={{ fontWeight: 'bold', color: '#48cae4' }}
                                        mentionStyle={{ fontWeight: 'bold', color: '#4361EE' }}
                                        onPressHashtag={(e, hashtag) => { e.stopPropagation(); navigation.push('Channel', { channelId: post.channel_id }) }}
                                    >{`Posted in #${post.channel_tag}`}</TwitterTextView>
                                </TouchableOpacity>
                            }
                            <PostFooter post={post} parent={user} isPost={false} />
                        </View>
                    )
                }
                break;
            case 'link':
                feedItem = <PostLink post={post} user={user} isFeed={true} isPost={false} activeItem={activeDiscoverItem} fit={true} disableTap={true} />;
                break;
            case 'people-on-slyde':
                return (
                    <View style={{ backgroundColor: "white", marginBottom: 15 }}>
                        <PeopleOnSlyde />
                    </View>
                );
            case 'sync-contacts':
                return (
                    <View style={{ backgroundColor: "white", marginBottom: 15 }}>
                        {/* <ContactSync showTabs /> */}
                        <PeopleOnSlyde />
                    </View>
                );
            default:
                feedItem = <></>;
                break;
        }

        const feedItemContainer = (
                <View key={`discover-feed-item-${post.id}`} style={{ marginBottom: 15, backgroundColor: "white", borderRadius: 0, borderWidth: 1, borderColor: "rgba(0,0,0,0.05)", borderRadius: 8, overflow: "hidden" }}>
                    <View style={styles.post_header}>
                        <View style={{ flexDirection: "row", width: "100%", alignItems: "center" }}>
                            <TouchableOpacity onPress={() => { navigation.push("Profile", { username: user.username }) }} style={{...styles.username_container, flex: 1, borderLeftWidth: 6, borderLeftColor: user.style.backgroundColor }}>
                                <View style={{ ...styles.name }}>
                                    <Image source={{ uri: user.profile_pic_url }} style={[ styles.profile_pic, {  }]} resizeMode={`cover`} />
                                    <View style={{ justifyContent: "center", flexDirection: "column" }}>
                                        <View style={{ flexDirection: "row" }}>
                                            <Text style={styles.user_name}>{user.real_name ? user.real_name : user.username}</Text>
                                            {user.verified == true &&
                                                <View style={{ justifyContent: "center", flexDirection: "column", alignItems: "flex-start", marginLeft: 7, marginTop: 2 }}>
                                                    <Octicons name="verified" size={16} color="#4895EF" />
                                                </View>
                                            }
                                        </View>
                                        <Text style={{ fontSize: 13, color: "rgba(0,0,0,0.4)" }} numberOfLines={1}>{user.tagline}</Text>
                                    </View>
                                </View>
                            </TouchableOpacity>
                            { !user.connected &&
                                <TouchableOpacity style={{ paddingRight: 20 }} onPress={() => { follow(user) }}>
                                    <Text style={{ color: "white", backgroundColor: "rgb(247, 37, 133)", padding: 5, paddingLeft: 15, paddingRight: 15, borderRadius: 4 }}>Follow</Text>
                                </TouchableOpacity>
                            }
                        </View>
                        {post.parent_post_id != null &&
                            <TouchableOpacity onPress={() => { navigation.push("Profile", { username: post.parent_username }) }} style={styles.shared}>
                                <View style={styles.name}>
                                    <Image source={{ uri: post.parent_profile_pic_url }} style={styles.shared_pic} resizeMode={`cover`} />
                                    <View style={{ flex: 1, justifyContent: "center", flexDirection: "column" }}>
                                        <Text style={styles.shared_original}><MaterialCommunityIcons name="share" size={18} color="rgba(0,0,0,0.7)" /> Originally Posted By:</Text>
                                        <Text style={styles.user_name}>{post.parent_real_name ? post.parent_real_name : post.parent_username}</Text>
                                    </View>
                                </View>
                            </TouchableOpacity>
                        }
                    </View>
                    {feedItem}
                    { post.user.theme_song && post.user.theme_song.preview &&
                        <PostThemeSong parent={post.user} />
                    }
                </View>
        );

        return feedItemContainer;
    };

    const renderFullDiscover = ({ item, index }) => {
        let feedItem = <></>;
        switch (item.post_type) {
            case 'new_user':
                feedItem = <FeedNewUser post={item} parent={item.user} parentType={`user`} />;
                break;
            case 'status':
                if (item.media.length > 0) {
                    feedItem = <FeedMedia post={item} parent={item.user} parentType={`user`} />
                } else {
                    feedItem = <FeedText post={item} parent={item.user} parentType={`user`} />
                }
                break;
            case 'link':
                feedItem = <FeedLink post={item} parent={item.user} parentType={`user`} />;
                break;
            case 'people-on-slyde':
                return (
                    <InViewport onChange={(v) => { updateTabs(v, null); }} key={`discover-feed-item-full-${index}-container`}>
                        <View style={{ backgroundColor: "white", justifyContent: "center", width: fillWidth, height: fillHeight, zIndex: 1, flexDirection: "column" }}>
                            <PeopleOnSlyde showTabs />
                        </View>
                    </InViewport>
                );
            case 'sync-contacts':
                return (
                    <InViewport onChange={(v) => { updateTabs(v, null); }} key={`discover-feed-item-full-${index}-container`}>
                        {/* <View style={{ backgroundColor: "white", justifyContent: "center", width: fillWidth, height: fillHeight, zIndex: 1, flexDirection: "column" }}>
                            <ContactSync showTabs />
                        </View> */}
                        <View style={{ backgroundColor: "white", justifyContent: "center", width: fillWidth, height: fillHeight, zIndex: 1, flexDirection: "column" }}>
                            <PeopleOnSlyde showTabs />
                        </View>
                    </InViewport>
                )
            default:
                console.log("SOMETHING ELSE", item.post_type);
                feedItem = <></>;
                return feedItem;
                break;
        }

        const feedItemContainer = (
            <View key={`feed-item-post-${item.id}-${index}-discover`} style={{ ...{ justifyContent: "center" }, width: fillWidth, height: fillHeight, zIndex: 1 }}>
                {feedItem}
            </View>
        );

        return feedItemContainer;
    };

    const renderTraditionalFeed = ({ item, index }) => {
        let feedItem = null;
        let post = item;
        let user = post.user;

        switch (post.post_type) {
            case 'new_user':
                feedItem = <PostNewUser post={post} user={user} isFeed={true} isPost={false} activeItem={activeItem} fit={true} disableTap={true} />;
                break;
            case 'status':
                if (post.media.length > 0) {
                    feedItem = <PostMedia post={post} user={user} isFeed={true} isPost={false} activeItem={activeItem} fit={true} disableTap={true} />
                } else {
                    feedItem = (
                        <View style={styles.text_post_container}>
                            <TouchableOpacity onPress={(e) => { navigation.push("PostView", { postId: post.id }) }}>
                                <TextView
                                    style={{ fontSize: 24, padding: 15, paddingTop: 0, fontWeight: "600", color: "black" }}
                                    hashtagStyle={{ fontWeight: 'bold', color: '#F72585' }}
                                    mentionStyle={{ fontWeight: 'bold', color: '#4361EE' }}
                                    numberOfLines={10}
                                    onPress={(e) => {navigation.push("PostView", { postId: post.id })}}
                                    onPressMention={(e, mention) => { e.stopPropagation(); navigation.push('Profile', { username: mention.trim().substring(1) }) }}
                                    onPressHashtag={(e, hashtag) => { e.stopPropagation(); navigation.push('Search', { terms: hashtag.trim() }) }}
                                >{post.post_media}</TextView>
                            </TouchableOpacity>
                            <PostFooter post={post} parent={user} isPost={false} />
                        </View>
                    )
                }
                break;
            case 'link':
                feedItem = <PostLink post={post} user={user} isFeed={true} isPost={false} activeItem={activeItem} fit={true} disableTap={true} />;
                break;
            case 'people-on-slyde':
                return (
                    <View style={{ backgroundColor: "white", marginBottom: 15 }}>
                        <PeopleOnSlyde />
                    </View>
                );
            case 'sync-contacts':
                return (
                    // <View style={{ backgroundColor: "white", marginBottom: 15 }}>
                    //     <ContactSync showTabs />
                    // </View>
                    <View style={{ backgroundColor: "white", marginBottom: 15 }}>
                        <PeopleOnSlyde />
                    </View>
                );
            default:
                feedItem = <></>;
                break;
        }

        const feedItemContainer = (
            <View key={`timeline-feed-item-${post.id}`} style={{ marginBottom: 15, backgroundColor: "white", borderRadius: 0, borderWidth: 1, borderColor: "rgba(0,0,0,0.05)", borderRadius: 8, overflow: "hidden" }}>
                <View style={styles.post_header}>
                    <View style={{ flexDirection: "row", width: "100%", alignItems: "center" }}>
                        <TouchableOpacity onPress={() => { navigation.push("Profile", { username: user.username }) }} style={{...styles.username_container, flex: 1, borderLeftWidth: 6, borderLeftColor: user.style.backgroundColor }}>
                            <View style={{ ...styles.name }}>
                                <Image source={{ uri: user.profile_pic_url }} style={[ styles.profile_pic, {  }]} resizeMode={`cover`} />
                                <View style={{ justifyContent: "center", flexDirection: "column" }}>
                                    <View style={{ flexDirection: "row" }}>
                                        <Text style={styles.user_name}>{user.real_name ? user.real_name : user.username}</Text>
                                        {user.verified == true &&
                                            <View style={{ justifyContent: "center", flexDirection: "column", alignItems: "flex-start", marginLeft: 7, marginTop: 2 }}>
                                                <Octicons name="verified" size={16} color="#4895EF" />
                                            </View>
                                        }
                                    </View>
                                    <Text style={{ fontSize: 13, color: "rgba(0,0,0,0.4)" }} numberOfLines={1}>{user.tagline}</Text>
                                </View>
                            </View>
                        </TouchableOpacity>
                        { !user.connected &&
                            <TouchableOpacity style={{ paddingRight: 20 }} onPress={() => { follow(user) }}>
                                <Text style={{ color: "white", backgroundColor: "rgb(247, 37, 133)", padding: 5, paddingLeft: 15, paddingRight: 15, borderRadius: 4 }}>Follow</Text>
                            </TouchableOpacity>
                        }
                    </View>
                    {post.parent_post_id != null &&
                        <TouchableOpacity onPress={() => { navigation.push("Profile", { username: post.parent_username }) }} style={styles.shared}>
                            <View style={styles.name}>
                                <Image source={{ uri: post.parent_profile_pic_url }} style={styles.shared_pic} resizeMode={`cover`} />
                                <View style={{ flex: 1, justifyContent: "center", flexDirection: "column" }}>
                                    <Text style={styles.shared_original}><MaterialCommunityIcons name="share" size={18} color="rgba(0,0,0,0.7)" /> Originally Posted By:</Text>
                                    <Text style={styles.user_name}>{post.parent_real_name ? post.parent_real_name : post.parent_username}</Text>
                                </View>
                            </View>
                        </TouchableOpacity>
                    }
                </View>
                {feedItem}
                { post.user.theme_song && post.user.theme_song.preview &&
                    <PostThemeSong parent={post.user} />
                }
                <View style={{ borderBottomWidth: 1, borderBottomColor: "rgba(0,0,0,0.1)", width: "50%", height: 4 }} />
            </View>
        );

        return feedItemContainer;
    };

    return (
        <View style={styles.container} onLayout={setSizing}>
            <DiscoverTools onSetFeedType={(type) => { setFeedType(type); }} />
            {props.feedSettings.type == 'traditional' &&
                <View style={{ flex: 1, width: "100%", backgroundColor: "#fafafa" }}>
                    {feedType == 'discover' &&
                        <FlatList
                            horizontal={false}
                            showsVerticalScrollIndicator={true}
                            disableVirtualization={true}
                            style={{ flex: 1, flexDirection: "column", display: feedType == 'discover' ? 'flex' : 'none', paddingLeft: 30, paddingRight: 30 }}
                            ListHeaderComponent={props.feedSettings.type == 'traditional' ? (
                                <SafeAreaView style={{}}>
                                    <View style={{ height: 60 }}>

                                    </View>
                                </SafeAreaView>
                            ) : null}
                            data={discoverFeed}
                            ref={discoverFeedRef}
                            renderItem={renderTraditionalDiscover}
                            onEndReached={() => { setDiscoverPage(discoverPage + 1); }}
                            onEndReachedThreshold={1.5}
                            initialNumToRender={5}
                            keyExtractor={(item, index) => `discover-feed-post-${item.id}-${index}`}
                            onViewableItemsChanged={onViewDiscoverRef.current}
                            viewabilityConfig={viewConfigDiscoverRef.current}
                            onRefresh={() => {
                                setRefreshingDiscover(true);
                                setDiscoverPage(null);
                            }}
                            refreshing={refreshingDiscover}
                            windowSize={12}
                        />
                    }
                </View>
            }
            {props.feedSettings.type == 'full' &&
                <View style={{ width: "100%" }}>
                    {feedType == "discover" &&
                        <FlatList
                            horizontal={false}
                            showsVerticalScrollIndicator={true}
                            disableVirtualization={true}
                            pagingEnabled={true}
                            decelerationRate="fast"
                            contentContainerStyle={{ flexGrow: 1 }}
                            showsVerticalScrollIndicator={false}
                            style={{ flex: 1, flexDirection: "column", display: feedType == 'discover' ? 'flex' : 'none' }}
                            data={discoverFeed}
                            ref={discoverFeedRef}
                            renderItem={renderFullDiscover}
                            onEndReached={() => { setDiscoverPage(discoverPage + 1); }}
                            onEndReachedThreshold={1.5}
                            initialNumToRender={3}
                            keyExtractor={(item, index) => `discover-feed-post-${item.id}-${index}-full`}
                            onViewableItemsChanged={onViewDiscoverRef.current}
                            viewabilityConfig={viewConfigDiscoverRef.current}
                            onRefresh={() => {
                                setRefreshingDiscover(true);
                                setDiscoverPage(null);
                            }}
                            refreshing={refreshingDiscover}
                            windowSize={5}
                            maxToRenderPerBatch={3}
                        />
                    }
                </View>
            }
            {feedType == "feed" &&
                <View style={{ width: "100%" }}>
                    {props.feedSettings.type == 'full' &&
                        <FlatList
                            horizontal={false}
                            pagingEnabled={true}
                            decelerationRate="fast"
                            contentContainerStyle={{ flexGrow: 1 }}
                            showsVerticalScrollIndicator={false}
                            onScroll={checkScroll}
                            scrollEventThrottle={50}
                            style={{ flex: 1, flexDirection: "column", width: "100%", height: "100%", display: feedType == 'feed' ? 'flex' : 'none' }}
                            data={feed}
                            renderItem={renderFullFeed}
                            onEndReached={() => { setPage(page + 1); }}
                            onEndReachedThreshold={1.5}
                            initialNumToRender={3}
                            keyExtractor={(item, index) => `home-feed-post-${item?.id}-${index}`}
                            onViewableItemsChanged={onViewDiscoverRef.current}
                            viewabilityConfig={viewConfigDiscoverRef.current}
                            ref={feedRef}
                            onRefresh={() => {
                                setRefreshingFeed(true);
                                setPage(null);
                            }}
                            refreshing={refreshingFeed}
                            windowSize={5}
                            maxToRenderPerBatch={3}
                        />
                    }
                    {props.feedSettings.type == 'traditional' &&
                        <View style={{ flex: 1, width: "100%", backgroundColor: "#fafafa" }}>
                            <FlatList
                                horizontal={false}
                                showsVerticalScrollIndicator={true}
                                disableVirtualization={true}
                                style={{ flex: 1, flexDirection: "column", display: 'flex', paddingLeft: 30, paddingRight: 30 }}
                                ListHeaderComponent={props.feedSettings.type == 'traditional' ? (
                                    <SafeAreaView style={{}}>
                                        <View style={{ height: 80 }}>

                                        </View>
                                    </SafeAreaView>
                                ) : null}
                                data={timeline}
                                ref={feedRef}
                                renderItem={renderTraditionalFeed}
                                onEndReached={() => { setTimelinePage(timelinePage + 1); }}
                                onEndReachedThreshold={1.5}
                                initialNumToRender={5}
                                keyExtractor={(item, index) => `timeline-feed-post-${item.id}-${index}`}
                                onViewableItemsChanged={onViewDiscoverRef.current}
                                viewabilityConfig={viewConfigDiscoverRef.current}
                                onRefresh={() => {
                                    setRefreshingFeed(true);
                                    setTimelinePage(null);
                                }}
                                refreshing={refreshingFeed}
                                windowSize={12}
                            />
                        </View>
                    }
                </View>
            }
            {feedType == "channel" &&
                <View style={{ width: "100%" }}>
                    {props.feedSettings.type == 'full' &&
                        <FlatList
                            horizontal={false}
                            pagingEnabled={true}
                            decelerationRate="fast"
                            contentContainerStyle={{ flexGrow: 1 }}
                            showsVerticalScrollIndicator={false}
                            onScroll={checkScroll}
                            scrollEventThrottle={50}
                            style={{ flex: 1, flexDirection: "column", width: "100%", height: "100%" }}
                            data={channelFeed}
                            renderItem={renderFullDiscover}
                            onEndReached={() => { setChannelPage(channelPage + 1); }}
                            onEndReachedThreshold={1.5}
                            initialNumToRender={3}
                            keyExtractor={(item, index) => `channel-feed-post-${item.id}-${index}`}
                            ref={channelFeedRef}
                            onViewableItemsChanged={onViewDiscoverRef.current}
                            viewabilityConfig={viewConfigDiscoverRef.current}
                            onRefresh={() => {
                                setRefreshingChannel(true);
                                setChannelPage(null);
                            }}
                            refreshing={refreshingChannel}
                            windowSize={5}
                            maxToRenderPerBatch={3}
                        />
                    }
                    {props.feedSettings.type == 'traditional' &&
                        <FlatList
                            horizontal={false}
                            showsVerticalScrollIndicator={true}
                            style={{ flex: 1, flexDirection: "column", display: 'flex' }}
                            ListHeaderComponent={props.feedSettings.type == 'traditional' ? (
                                <SafeAreaView style={{}}>
                                    <View style={{ height: 80 }}>

                                    </View>
                                </SafeAreaView>
                            ) : null}
                            data={channelFeed}
                            ref={channelFeedRef}
                            renderItem={renderTraditionalDiscover}
                            onEndReached={() => { setChannelPage(channelPage + 1); }}
                            onEndReachedThreshold={1.5}
                            initialNumToRender={5}
                            keyExtractor={(item, index) => `channel-feed-post-${item.id}-${index}`}
                            onViewableItemsChanged={onViewDiscoverRef.current}
                            viewabilityConfig={viewConfigDiscoverRef.current}
                            onRefresh={() => {
                                setRefreshingChannel(true);
                                setChannelPage(null);
                            }}
                            refreshing={refreshingChannel}
                            windowSize={12}
                        />
                    }
                </View>
            }
        </View>
    )
}

const styles = {
    container: {
        flex: 1,
        flexDirection: "row",
        width: "100%",
        height: "100%",
        backgroundColor: "white",
    },
    feed_scroll_container: {
        flexGrow: 1,
        flexDirection: "column",
        backgroundColor: "#B5179E",
    },
    post_1: {
        backgroundColor: "#B5179E",
        justifyContent: "center",
        color: "#ffffff"
    },
    post_header: {
        padding: 0
    },
    big_text_container: {
        flex: 1,
        justifyContent: "center",
        padding: 15
    },
    big_text: {
        fontSize: 30,
        textAlign: "center",
        color: "#000000"
    },
    profile_content_area: {
        flexDirection: "row"
    },
    profile_pic_container: {
        paddingLeft: 10,
    },
    username_container: {
        flexDirection: "row"
    },
    name: {
        flex: 1,
        flexDirection: "row",
        color: "black",
    },
    user_name: {
        color: '#000000',
        fontSize: 16,
        fontWeight: "bold",
    },
    profile_name: {
        flex: 1,
        flexDirection: "column",
        paddingLeft: 10,
        justifyContent: "center"
    },
    profile_name_text: {
        fontSize: 20,
        fontWeight: "bold"
    },
    profile_pic: {
        width: 45,
        height: 45,
        borderRadius: 40,
        margin: 7
    },
    shared: {
        borderRadius: 10,
        marginTop: 10,
        paddingBottom: 10
    },
    shared_original: {
        fontSize: 12,
        color: "rgba(0,0,0,0.7)"
    },
    shared_pic: {
        width: 26,
        height: 26,
        borderRadius: 5,
        margin: 5
    },
    caption_container: {
        margin: 15,
        fontSize: 18
    },
    loader: {
        width: 200,
        height: 200,
    },
    text_post_container: {
        backgroundColor: "white",
        paddingTop: 15,
        width: "100%",
    },
    post_time: {
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingRight: 10
    },
    post_time_text: {
        color: "rgba(0,0,0,0.6)",
        fontSize: 13
    }
};

const mapStateToProps = state => ({
    user: state.user,
    feed: state.feed.items,
    feedType: state.feed.feedType,
    feedSettings: state.feed.settings,
    discover_items: state.feed.discover_items
});

const mapDispatchToProps = dispatch => ({
    getFeed: page => dispatch(getFeed(page)),
    getFeedPage: page => dispatch(getFeedPage(page)),
    setActiveFeedIndex: index => dispatch(setActiveFeedIndex(index)),
    setActiveFeedItem: item => dispatch(setActiveFeedItem(item)),
    getDiscoverPage: page => dispatch(getDiscoverPage(page)),
    setTabStyle: style => dispatch(setTabStyle(style)),
    setActiveChannel: channel => dispatch(setActiveChannel(channel)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Feed);