import { useNavigation } from '@react-navigation/native';
import react from 'react';
import { useState, useEffect, useMemo } from 'react';
import { Pressable, View, Text, ScrollView, TouchableOpacity, Image } from 'react-native';
import { connect } from 'react-redux';
import { Ionicons, FontAwesome, Octicons } from '@expo/vector-icons';
import API from './libs/API';

const RightBar = (props) => {
    const [suggestedChannels, setSuggestedChannels] = useState([]);
    const [users, setUsers] = useState([]);
    
    const navigation = useNavigation();

    useEffect(() => {
        if (props.user) {
            API.get('/api/v1/suggestedChannels').then((result) => {
                // we got some channels here
                if (result.error) {
                    console.log(result);
                    return;
                }

                setSuggestedChannels(result.channels.slice(0,4));
            }).catch((error) => {
                console.log("Error", error);
            });

            API.get(`/api/v1/user/suggested?page=1`).then((response) => {
                if (response.error) {
                    return;
                }

                const suggestedUsers = response.connections.slice(0, 4).map((c) => {
                    c.style = JSON.parse(c.style);
                    if (c.profile_pic_crop) {
                        c.profile_pic_crop = JSON.parse(c.profile_pic_crop);
                    }
                    return c;
                });
                
                console.log(suggestedUsers);

                setUsers(suggestedUsers);
            }, (err) => {
                console.log(err);
            });
        }
    }, [props.user]);
    
    const follow = (user) => {
        if (!props.user?.id) {
            // popover at some point
            navigation.navigate('UserLogin');
            return;
        }

        API.post(`/api/v1/follow/${user.id}`).then((response) => {
            if (response.error) {
                alert("Something went wrong while trying to follow.");
                return;
            }

            alert(`You're now following @${user.username}`);
        }, (err) => {
            alert("Something went wrong while trying to follow.");
        });
    }

    const channels = useMemo(() => {
        return suggestedChannels.map((channel) => {
            return (
                <TouchableOpacity key={`suggested-channel-${channel.id}`} onPress={(e) => { navigation.navigate('Channel', { channelId: channel.tag }) }} style={{ flexDirection: "row", marginBottom: 15 }}>
                    {!!channel.pic_url &&
                        <img src={channel.pic_url} width={50} height={50} style={{ borderRadius: 6, objectFit: "cover", marginRight: 10 }} />
                    }
                    {!channel.pic_url &&
                        <View style={{ backgroundColor: "#e2e0e0", width: 50, height: 50, borderRadius: 6, overflow: "hidden", justifyContent: "center", alignItems: "center", marginRight: 10 }}>
                            <FontAwesome name="hashtag" size={24} color="white" />
                        </View>
                    }
                    <View style={{ flex: 1, flexDirection: "column" }}>
                        <Text style={{ color: "black", fontWeight: "bold" }} numberOfLines={1}>#{channel.tag}</Text>
                        <Text style={{ color: "rgba(0,0,0,0.7)", fontSize: 13 }} numberOfLines={2}>{channel.description}</Text>
                    </View>
                </TouchableOpacity>
            )
        });
    }, [suggestedChannels]);

    const suggestedUsers = useMemo(() => {
        return users.map((user) => {
            return (
            <View key={`suggested-user-${user.id}`} style={{ flexDirection: "row", width: "100%", alignItems: "center", marginTop: 15 }}>
                <TouchableOpacity onPress={() => { navigation.navigate("Profile", { username: user.username }) }} style={{...styles.username_container, borderRadius: 6, flex: 1, borderLeftWidth: 6, borderLeftColor: user.style.backgroundColor }}>
                    <View style={{ ...styles.name }}>
                        <Image source={{ uri: user.profile_pic_url }} style={[ styles.profile_pic, {  }]} resizeMode={`cover`} />
                        <View style={{ justifyContent: "center", flexDirection: "column" }}>
                            <View style={{ flexDirection: "row" }}>
                                <Text style={styles.user_name}>{user.real_name ? user.real_name : user.username}</Text>
                                {user.verified == true &&
                                    <View style={{ justifyContent: "center", flexDirection: "column", alignItems: "flex-start", marginLeft: 7, marginTop: 2 }}>
                                        <Octicons name="verified" size={16} color="#4895EF" />
                                    </View>
                                }
                            </View>
                            <Text style={{ fontSize: 13, color: "rgba(0,0,0,0.4)" }} numberOfLines={1}>{user.tagline.substr(0,30)}{user.tagline.length > 30 && `...`}</Text>
                        </View>
                    </View>
                </TouchableOpacity>
            </View>
            );
        });
    }, [users]);

    return (
        <ScrollView style={{ flexDirection: "columnn", width: "100%", backgroundColor: "white", flex: 1 }}>
            <View style={{ width: "100%", flexDirection: "column", padding: 20 }}>
                <Text style={{ fontSize: 20, fontWeight: "bold" }}>Suggested Channels</Text>
                <View style={{ flexDirection: "column", marginTop: 20 }}>
                    {channels}
                </View>
                <Text style={{ fontSize: 13, color: "rgba(0,0,0,0.5)", marginTop: 10 }}>Channels on Slyde are similar to groups or forums. Check out some fun ones to see what's happening and connect with other people on Slyde.</Text>
            </View>
            <View style={{ width: "100%", flexDirection: "column", padding: 20 }}>
                <Text style={{ fontSize: 20, fontWeight: "bold" }}>Suggested People</Text>
                {suggestedUsers}
                <Text style={{ fontSize: 13, color: "rgba(0,0,0,0.5)", marginTop: 10 }}>Discover other interesting and fun people on Slyde and start seeing their posts in your feed.</Text>
            </View>
            <View style={{ width: "100%", flexDirection: "column", padding: 20 }}>
                <Text style={{ fontSize: 20, fontWeight: "bold" }}>Slyde Blog</Text>
                <Text style={{ fontSize: 13, color: "rgba(0,0,0,0.5)", marginTop: 10 }}>Want to stay up with the latest news about Slyde? <a href="https://blog.slyde.network" style={{ color: "black" }} target="_blank">Check out our blog</a>.</Text>
            </View>
        </ScrollView>
    )
}

const styles = {
    navButton: {
        padding: 15,
        paddingLeft: 0,
        width: "100%",
        flexDirection: "row",
        alignItems: "center",
    },
    navButtonText: {
        fontWeight: "bold",
        fontSize: 17,
        color: "rgba(0,0,0,0.4)",
    },
    navButtonIconContainer: {
        paddingLeft: 20,
        paddingRight: 20,
    },
    activeItem: {
        width: 1,
        height: 30,
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
        borderRightWidth: 4,
        borderRightColor: "rgb(247, 37, 133)"
    },
    container: {
        flex: 1,
        flexDirection: "row",
        width: "100%",
        height: "100%",
        backgroundColor: "white",
    },
    feed_scroll_container: {
        flexGrow: 1,
        flexDirection: "column",
        backgroundColor: "#B5179E",
    },
    post_1: {
        backgroundColor: "#B5179E",
        justifyContent: "center",
        color: "#ffffff"
    },
    post_header: {
        padding: 0
    },
    big_text_container: {
        flex: 1,
        justifyContent: "center",
        padding: 15
    },
    big_text: {
        fontSize: 30,
        textAlign: "center",
        color: "#000000"
    },
    profile_content_area: {
        flexDirection: "row"
    },
    profile_pic_container: {
        paddingLeft: 10,
    },
    username_container: {
        flexDirection: "row"
    },
    name: {
        flex: 1,
        flexDirection: "row",
        color: "black",
    },
    user_name: {
        color: '#000000',
        fontSize: 16,
        fontWeight: "bold",
    },
    profile_name: {
        flex: 1,
        flexDirection: "column",
        paddingLeft: 10,
        justifyContent: "center"
    },
    profile_name_text: {
        fontSize: 20,
        fontWeight: "bold"
    },
    profile_pic: {
        width: 45,
        height: 45,
        borderRadius: 40,
        margin: 7
    },
    shared: {
        borderRadius: 10,
        marginTop: 10,
        paddingBottom: 10
    },
    shared_original: {
        fontSize: 12,
        color: "rgba(0,0,0,0.7)"
    },
    shared_pic: {
        width: 26,
        height: 26,
        borderRadius: 5,
        margin: 5
    },
    caption_container: {
        margin: 15,
        fontSize: 18
    },
    loader: {
        width: 200,
        height: 200,
    },
    text_post_container: {
        backgroundColor: "white",
        paddingTop: 15,
        width: "100%",
    },
    post_time: {
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingRight: 10
    },
    post_time_text: {
        color: "rgba(0,0,0,0.6)",
        fontSize: 13
    }
}

const mapStateToProps = state => ({
    user: state.user,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(RightBar);