import axios from 'axios';

// We will use qwest for now. Wrapper is made in case there is a need to
// change the underlying provider in the future without changing the rest
// of the code.

const API_URL = `https://api.slyde.network`;

class api {
    jwt = null;
    
    post(url, data) {
        return new Promise(async (resolve, reject) => {
            // make the request
            axios.post(`${API_URL}${url}`, data).then((response) => {
                resolve(response.data);
            });
        });
    }
    
    get(url) {
        return new Promise(async (resolve, reject) => {
            // make the request
            axios.get(`${API_URL}${url}`).then((response) => {
                resolve(response.data);
            }, (err) => {
                reject(err);
            });
        });
    }
    
    setJWT(token) {
        this.jwt = token;
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.jwt;
    }
}

var h = new api();

// for helpers and services, return a singleton.
export default h;
