import React, { useEffect, useState } from 'react';
import { Entypo } from '@expo/vector-icons';
import { Button, TextInput, Text, View, StyleSheet, ScrollView, useWindowDimensions, TouchableOpacity, Image } from 'react-native';
import { connect } from 'react-redux';

import AudioService from '../../services/AudioService';

const PostThemeSong = (props) => {
    const play = () => {
        if (props.audio.current_song != props.parent.theme_song.preview) {
            AudioService.play(props.parent.theme_song.preview);
        } else {
            AudioService.stop();
        }
    }
    return (
        <View style={styles.container}>
            <TouchableOpacity onPress={() => { play() }} style={{ flexDirection: "row", flex: 1, alignContent: "stretch" }}>
                <View style={styles.music_art_container}>
                    <Image source={{ uri: props.parent.theme_song.art }} style={{ width: 40, height: 40 }} />
                </View>
                <View style={styles.play_button_container}>
                    { props.audio.current_song != props.parent.theme_song.preview &&
                        <Entypo name="controller-play" size={24} color="black" />
                    }
                    { props.audio.current_song == props.parent.theme_song.preview &&
                        <Entypo name="controller-stop" size={24} color="black" />
                    }
                </View>
                <View style={styles.music_title_container}>
                    <View style={{ textAlign: "left", overflow: "hidden" }}>
                        <Text allowFontScaling={false} style={styles.music_title_person}>{props.parent.real_name ? props.parent.real_name : `@${props.parent.username}`}'s Theme Song</Text>
                        <Text numberOfLines={1} allowFontScaling={false} style={styles.music_title_song}>{props.parent.theme_song.name} ({props.parent.theme_song.artist})</Text>
                    </View>
                </View>
                <View style={styles.spotify_container}>
                    <Image source={require('../../assets/spotify.png')} style={styles.spotify} />
                </View>
            </TouchableOpacity>
        </View>
    )
}

const styles = {
    container: {
        flexDirection: "row",
        backgroundColor: "white",
        borderTopColor: "rgba(0,0,0,0.05)",
        borderTopWidth: 1,
        padding: 10
    },
    music_art_container: {
        width: 40,
        marginRight: 10,
        justifyContent: "center",
        alignItems: "center"
    },
    play_button_container: {
        width: 30,
        marginRight: 7,
        justifyContent: "center",
        alignItems: "center"
    },
    music_title_container: {
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        flex: 1,
    },
    music_title_person: {
        fontSize: 12,
        color: "rgba(0,0,0,0.8)",
        textAlign: "left",
        justifyContent: "flex-start"
    },
    music_title_song: {
        fontSize: 14,
        fontWeight: "bold",
        color: "#000000",
        justifyContent: "center",
        alignItems: "center"
    },
    spotify_container: {
        justifyContent: "center",
        alignItems: "center",
        width: 30,
        marginLeft: 10
    },
    spotify: {
        width: 30,
        height: 30,
        borderRadius: 30,
        marginTop: 5
    }
};

const mapStateToProps = state => ({
    user: state.user,
    audio: state.audio
});

const mapDispatchToProps = dispatch => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(PostThemeSong);