import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, TextInput, Text, View, StyleSheet, ScrollView, useWindowDimensions, SafeAreaView, FlatList } from 'react-native';
import { connect } from 'react-redux';
import API from '../../libs/API';
import InViewport from '../common/InViewport';
import FeedNewUser from './FeedNewUser';

import { getFeed, setActiveScrollPost } from '../../actions/FeedActions';
import { setTabStyle } from '../../actions/SettingsActions';
import FeedMedia from './FeedMedia';
import FeedText from './FeedText';
import FeedLink from './FeedLink';

const FeedScroller = (props) => {
    const [fillWidth, setFillWidth] = useState(0);
    const [fillHeight, setFillHeight] = useState(0);
    const [feed, setFeed] = useState([]);
    const [page, setPage] = useState(1);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [activeItem, setActiveItem] = useState(null);

    const onViewRef = React.useRef(({viewableItems})=> {
        if (viewableItems[0]) {
            props.setActiveScrollPost(viewableItems[0].item);
        } else {
            props.setActiveScrollPost(null);
        }
        // Use viewable items in state or as intended
    })
    const viewConfigRef = React.useRef({ viewAreaCoveragePercentThreshold: 50 })

    useEffect(() => {
        loadPage(page);
    }, [page]);

    const loadPage = (page) => {
        let query = `?page=${page}`;
        API.get(`/api/v1/user/${props.feedItem.id}/posts${query}`).then((response) => {
            if (response.error) {
                console.log("error", response.error);
                setError('error.network');
                return;
            }

            setFeed([ ...feed, ...response.posts ]);
        }, (err) => {
            setError('error.network');
        });
    }

    const updateTabs = (visible, user) => {
        if (visible) {
            props.setTabStyle(user.style)
        }
    }

    return (
        <InViewport onChange={(v) => { props.updateTabs(v, props.feedItem) }} style={{...styles.feed_scroll_container, width: props.width, height: props.height, backgroundColor: props.feedItem.style.backgroundColor }}>
            <FlatList
                horizontal={true}
                pagingEnabled={true}
                contentContainerStyle={{flexGrow: 1}}
                showsHorizontalScrollIndicator={false}
                data={feed}
                renderItem={({ item }) => {
                let post = item;
                let feedItem = [];
                switch (post.post_type) {
                    case 'new_user':
                        feedItem = <FeedNewUser post={post} parent={props.feedItem} parentType={`user`} />;
                        break;
                    case 'status':
                        if (post.media.length > 0) {
                            feedItem = <FeedMedia post={post} parent={props.feedItem} parentType={`user`} />
                        } else {
                            feedItem = <FeedText post={post} parent={props.feedItem} parentType={`user`} />
                        }
                        break;
                    case 'link':
                        feedItem = <FeedLink post={post} parent={props.feedItem} parentType={`user`} />;
                        break;
                    default:
                        feedItem = <></>;
                        break;
                }

                return (
                    <View key={`feed-item-post-${post.id}`} style={{...styles.post_1, width: props.width, height: props.height}}>
                        {feedItem}
                    </View>
                );
            }}
            onEndReached={() => { setPage(page + 1); }}
            onEndReachedThreshold={0.5}
            initialNumToRender={5}
            keyExtractor={(item) => `user-scroller-feed-post-${item.id}`}
            onViewableItemsChanged={onViewRef.current}
            viewabilityConfig={viewConfigRef.current}
            />
        </InViewport>      
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: "row"
    },
    feed_scroll_container: {
        flexGrow: 1,
        flexDirection: "column",
        backgroundColor: "#B5179E",
    },
    post_1: {
        justifyContent: "center"
    },
    post_2: {
        justifyContent: "center"
    },
    post_3: {
        backgroundColor: "#4895EF",
        justifyContent: "center",
        color: "#ffffff"
    },
    post_4: {
        backgroundColor: "#4895EF",
        justifyContent: "center",
        color: "#ffffff"
    }
});

const mapStateToProps = state => ({
    user: state.user,
    feed: state.feed
});

const mapDispatchToProps = dispatch => ({
    getFeed: page => dispatch(getFeed(page)),
    setActiveScrollPost: post => dispatch(setActiveScrollPost(post)),
    setTabStyle: style => dispatch(setTabStyle(style))
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedScroller);