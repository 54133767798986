import moment from 'moment';

const prettyTime = (date) => {
    // do pretty moment stuff
    var REFERENCE = moment(); // fixed just for testing, use moment();
    var TODAY = REFERENCE.clone().startOf('day');
    var YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day');
    var A_WEEK_OLD = REFERENCE.clone().subtract(7, 'days').startOf('day');
    
    let momentDate = moment(date).utc();
    
    if (REFERENCE.diff(momentDate, 'minutes') < 30) {
        return momentDate.fromNow();
    }
    
    if (momentDate.isSame(TODAY, 'd')) {
        return momentDate.local().format("h:mma");
    }
    
    if (momentDate.isSame(YESTERDAY, 'd')) {
        // was yesterday
        return momentDate.local().format("[Yesterday -] h:mma");
    }
    
    if (momentDate.isAfter(A_WEEK_OLD)) {
        // this last week
        return momentDate.local().format("ddd [-] h:mma");
    }
    
    return momentDate.local().format("MMM Do h:mma");
};

export { prettyTime }