import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, TextInput, Text, View, StyleSheet, ScrollView, useWindowDimensions, SafeAreaView, Image, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';
import TwitterTextView from '../common/TextView';
import {
    useFonts,
    Inter_900Black,
  } from '@expo-google-fonts/inter';

import { getFeed } from '../../actions/FeedActions';

import FeedItemFooter from './FeedItemFooter';
import FeedItemTools from './FeedItemTools';
import { useNavigation } from '@react-navigation/native';

const FeedText = (props) => {
    const [fillWidth, setFillWidth] = useState(0);
    const [fillHeight, setFillHeight] = useState(0);
    const navigation = useNavigation();
    let [fontsLoaded] = useFonts({
        Inter_900Black,
      });
    
      let fontSize = 24;
      let paddingVertical = 6;

    useEffect(() => {
        //console.log("New User post", props.post, props.parent);
    }, []);

    if (!fontsLoaded) {
        return <></>;
    }

    const onPress = (e) => {
        if (props.tapToOpen) {
            navigation.push('PostView', { post: props.post, user: props.post.user });
            return;
        }
    }

    return (
        <View style={styles.container}>
            <TouchableOpacity onPress={onPress} style={styles.big_text_container}>
                <TwitterTextView
                    style={[styles.big_text, props.textPostOverride]}
                    hashtagStyle={{ fontWeight: 'bold', color: '#F72585' }}
                    mentionStyle={{ fontWeight: 'bold', color: '#4361EE' }}
                    numberOfLines={10}
                    onPressMention={(e, mention) => { navigation.push('Profile', { username: mention.trim().substring(1) }) }}
                    onPressHashtag={(e, hashtag) => { navigation.push('Search', { terms: hashtag.trim() }) }}
                    >{props.post.post_media}</TwitterTextView>
            </TouchableOpacity>
            { !props.noName &&
                <FeedItemFooter {...props} />
            }
            { !props.noTools &&
                <FeedItemTools {...props} />
            }
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: "column",
        backgroundColor: "#ffffff",
    },
    big_text_container: {
        justifyContent: "center",
        padding: 30,
        flex: 1,
    },
    big_text: {
        fontSize: 30,
        textAlign: "center",
        fontWeight: "bold",
        color: "#000000"
    }
});

const mapStateToProps = state => ({
    user: state.user
});

const mapDispatchToProps = dispatch => ({
    getFeed: page => dispatch(getFeed(page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedText);