/**
 * Holds logged in user data
 */
import { Types } from '../constants/actionTypes';
import Events from '../services/EventService';

const initialState = {
    chats: [],
    unread: 0
};

export const chatsReducer = (state = initialState, action) => {
    switch (action.type) {
        case Types.CHAT_LIST:
            // set unread count when we get the list
            return { ...state, chats: action.payload };
        case Types.CHAT_MESSAGES:
            var chatIndex = state.chats.findIndex((chat) => { return chat.chat.id == action.payload.chat.id });
            if (chatIndex === -1 || !action.payload.chatMessages[0]) {
                return state;
            }

            var newState = {...state, lastUpdated: new Date()};

            Events.publish('chat.messages', action.payload);

            if (action.payload.page == 1) {
                newState.chats[chatIndex].chatMessages = action.payload.chatMessages;
                return newState;
            }

            if (action.payload.chatMessages[0].id == newState.chats[chatIndex].chatMessages[0].id) {
                return state;
            } else {
                newState.chats[chatIndex].chatMessages = [...newState.chats[chatIndex].chatMessages, ...action.payload.chatMessages];
            }

            return newState;
        case Types.CHAT_MESSAGE:
            var chatIndex = state.chats.findIndex((chat) => { return chat.chat.id == action.payload.chatId });
            if (chatIndex === -1) {
                return state;
            }

            var newState = {...state, lastUpdated: new Date()};

            if (!action.payload.message.user) {
                action.payload.message.user = state.chats[chatIndex].userData[action.payload.message.user_id];
            }

            if (action.payload.message.uuid) {
                // if we already have this message, remove the current one and add the new one.
                newState.chats[chatIndex].chatMessages = newState.chats[chatIndex].chatMessages.filter((m) => {
                    return !m.uuid || m.uuid != action.payload.message.uuid;
                });
            }

            newState.chats[chatIndex].chatMessages = [...[action.payload.message], ...newState.chats[chatIndex].chatMessages];
            newState.chats[chatIndex].chat.updated_at = action.payload.message.created_at;

            return newState;
        case Types.CHAT_START:
            var chatIndex = state.chats.findIndex((chat) => { return chat.chat.id == action.payload.chat.id });
            if (chatIndex === -1) {
                // add the chat because it doesn't already exist
                var newState = {...state, lastUpdated: new Date()};
                newState.chats.shift(action.payload);
                return newState;
            } else {
                // replace the current chat with this one
                var newState = {...state, lastUpdated: new Date()};
                newState.chats[chatIndex] = action.payload;
                return newState;
            }
        case Types.CHAT_UNREAD:
            return {...state, lastUpdated: new Date(), unread: action.payload};
        default:
            return state;
    }
}