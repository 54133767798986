import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, TextInput, Text, View, StyleSheet, ScrollView, useWindowDimensions, SafeAreaView, Image } from 'react-native';
import { connect } from 'react-redux';

import { getFeed } from '../../actions/FeedActions';

import FeedItemFooter from './FeedItemFooter';
import FeedItemTools from './FeedItemTools';

const FeedNewUser = (props) => {
    const [fillWidth, setFillWidth] = useState(0);
    const [fillHeight, setFillHeight] = useState(0);

    useEffect(() => {
        //console.log("New User post", props.post, props.parent);
    }, []);

    const username = props.post.parent_post_id ? (props.post.parent_real_name ? props.post.parent_real_name : `@${props.post.parent_username}`) : (props.parent.real_name ? props.parent.real_name : `@${props.parent.username}`);

    return (
        <View style={styles.container}>
            <Image source={{ uri: props.parent.profile_pic_url }} style={styles.profile_pic_bg} resizeMode={`cover`} blurRadius={50} />
            <Image source={{ uri: props.parent.profile_pic_url }} style={styles.profile_pic} resizeMode={`contain`} />
            { !props.noTools &&
                <FeedItemTools {...props} />
            }
            <FeedItemFooter {...props} caption={`${username} joined Slyde!`} />
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: "column",
        backgroundColor: "#000000"
    },
    profile_pic_bg: {
        flex: 1,
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 1,
        opacity: 0.5
    },
    profile_pic: {
        flex: 1,
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 2
    }
});

const mapStateToProps = state => ({
    user: state.user
});

const mapDispatchToProps = dispatch => ({
    getFeed: page => dispatch(getFeed(page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedNewUser);