import API from '../libs/API';
import Storage from '../libs/Storage';

import { Types } from '../constants/actionTypes';

export const setSong = (url) => {
    return {
        type: Types.AUDIO_SET_SONG,
        payload: url,
    };
};