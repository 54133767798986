import { store } from '../store/index';
import { setSong } from '../actions/AudioActions';

class AudioService {
    constructor() {
        this.sound = new Audio();
    }

    async play(url) {
        this.sound = new Audio(url);
        this.sound.addEventListener("pause", (e) => {
            if (store.getState().audio.current_song == url) {
                store.dispatch(setSong(null));
            }
        })
        this.sound.play();
        store.dispatch(setSong(url));
    }

    async stop() {
        this.sound.pause();
        store.dispatch(setSong(null));
    }
}

let as = new AudioService();
export default as;