import React, { Component, PropTypes, useEffect, useRef } from "react";
import { View } from 'react-native';
import Video from './Video';
import { useState } from "react";

import InViewport from '../common/InViewport';
import useInView from "react-cool-inview";
import { useIsFocused } from "@react-navigation/native";

const ScaledVideo = (props) => {
    const [style, setStyle] = useState({});
    const videoRef = useRef();
    const isFocused = useIsFocused();
    const [calculatedStyle, setCalculatedStyle] = useState({ ...props.style });
    const pauseRef = useRef();

    useEffect(() => {
        setStyle({
            width: props.width ? props.width : "100%",
            height: props.height ? props.height: "100%",
            maxWidth: props.width ? props.width : "100%",
            maxHeight: props.height ? props.height : "100%"
        })
    }, []);

    useEffect(() => {
        if (props.onLoad && style.width) {
            props.onLoad(style.width, style.height);
        }
    }, [style]);

    useEffect(() => {
        const fitStyle = props.maxHeight ? { maxHeight: props.maxHeight } : {};
        let newStyle = { ...props.style, ...style, ...{ borderRadius: props.rounded ? 20 : 0 }, ...fitStyle};

        setCalculatedStyle(newStyle);
    }, [style, props.style]);

    const { observe, unobserve, inView, scrollDirection, entry } = useInView({
        threshold: 0.5, // Default is 0
        onChange: ({ inView, scrollDirection, entry, observe, unobserve }) => {
          // Triggered whenever the target meets a threshold, e.g. [0.25, 0.5, ...]
          handlePlaying(inView);
        },
        onEnter: ({ scrollDirection, entry, observe, unobserve }) => {
          // Triggered when the target enters the viewport
          //handlePlaying(true);
        },
        onLeave: ({ scrollDirection, entry, observe, unobserve }) => {
          // Triggered when the target leaves the viewport
          //handlePlaying(false);
        },
        // More useful options...
    });

    // useEffect(() => {
    //     console.log(props.dontAutoplay)
    //     if (props.dontAutoplay && videoRef.current) {
    //         videoRef.current.stopAsync();
    //     }
    // }, [props.dontAutoplay, videoRef.current]);

    const handlePlaying = (isVisible) => {
        if (videoRef.current) {
            if (isVisible && isFocused && !props.dontAutoplay) {
                pauseRef.current = false;
                try {
                    videoRef.current.play();
                } catch (e) {
                    // irrelevant
                    pauseRef.current = true;
                }
            } else {
                if (!pauseRef.current) {
                    pauseRef.current = true;
                    videoRef.current.pause();
                }
            }
        }
    }

    const calculateSize = ({ naturalSize }) => {
        let width = naturalSize.width;
        let height = naturalSize.height;
        let newWidth = width;
        let newHeight = height;

        if (props.width && !props.height) {
            newHeight = height * (props.width / width);

            if (props.maxHeight && newHeight > props.maxHeight) {
                // do width!
                newHeight = props.maxHeight;
            }
        } else if (!props.width && props.height) {
            newWidth = width * (props.height / height);
        }

        setStyle({
            width: newWidth,
            height: newHeight,
            maxWidth: props.width ? props.width : newWidth,
            maxHeight: newHeight
        });
    }

    const { width, height, ...newProps } = props;

    return (
        <View ref={observe}>
            <Video source={{ uri: props.uri }} {...newProps} muted={true} onReadyForDisplay={calculateSize} ref={videoRef} style={calculatedStyle} />
        </View>
    )
}

export default ScaledVideo;