import * as React from 'react';
import { useEffect, useState, useMemo, useRef } from 'react';
import { Button, TextInput, Text, View, StyleSheet, ScrollView, useWindowDimensions, SafeAreaView, Image, Pressable } from 'react-native';
import { connect } from 'react-redux';
import { useNavigation } from '@react-navigation/native';

import InViewport from '../common/InViewport';

import PostFooter from './PostFooter';

const PostNewUser = React.forwardRef((props, ref) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [user, setUser] = useState(null);
    const [post, setPost] = useState(null);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [playVideo, setPlayVideo] = useState(false);
    const navigation = useNavigation();
    const window = useWindowDimensions();

    useEffect(() => {
    }, []);

    useEffect(() => {
        //console.log("active item changed!", props.activeItem.item.id, props.post.id);
        if (props.activeItem && props.activeItem.item && props.activeItem.item.id == props.post.id) {
            //console.log("Play video!");
            setPlayVideo(true);
        } else {
            setPlayVideo(false);
        }
    }, [props.activeItem]);

    const onPress = (e) => {
        if (!props.disableTap) {
            if (e.nativeEvent.locationX < (window.width / 3)) {
                setActiveIndex(activeIndex == 0 ? 0 : activeIndex - 1);
            } else {
                setActiveIndex(activeIndex == (props.post.media.length - 1) ? activeIndex : activeIndex + 1 );
            }
        } else {
            navigation.push("PostView", { post: props.post, user: props.post.user, noHeader: true })
        }
    }

    const updateSize = (e) => {
        setWidth(e.nativeEvent.layout.width);
        setHeight(e.nativeEvent.layout.width);
    }

    if (!props.post.user) {
        return (<></>);
    }

    return (
        <View style={styles.container} onLayout={updateSize}>
            <View style={{ width: "100%", justifyContent: "center", alignItems: "center" }}>
                <Image source={{ uri: props.post.user.profile_pic_url}} style={{ width, height }} />
            </View>
            <Pressable onPress={onPress}>
                <Text
                    style={{ fontSize: 20, padding: 25, paddingTop: 0, color: "black", paddingTop: 15 }}
                >{props.post.user.real_name ? props.post.user.real_name : props.post.user.username} joined Slyde!</Text>
            </Pressable>
            <PostFooter post={props.post} parent={props.user} isPost={props.isPost} />
        </View>
    );
})

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: "column"
    },
    big_text_container: {
        flex: 1,
        justifyContent: "center",
        padding: 15
    },
    big_text: {
        fontSize: 30,
        fontFamily: 'Inter_900Black',
        textAlign: "center",
        color: "#000000"
    },
    profile_pic: {
        flex: 1,
        position: "relative",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 2,
        justifyContent: "center"
    },
    video: {
        flex: 1,
        position: "relative",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 2,
    },
    profile_pic_bg: {
        flex: 1,
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 1,
        opacity: 0.5
    },
});

const mapStateToProps = state => ({
    user: state.user,
    feed: state.feed
});

const mapDispatchToProps = dispatch => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(PostNewUser);