import API from '../libs/API';
import Storage from '../libs/Storage';

import { Types } from '../constants/actionTypes';

const setFeedLoadingState = (loading) => {
    return {
        type: Types.FEED_LOADING,
        payload: loading,
    };
};

const setDiscoverLoadingState = (loading) => {
    return {
        type: Types.FEED_DISCOVER_LOADING,
        payload: loading,
    };
};

const setFeedErrorState = (error) => {
    return {
        type: Types.USER_LOGIN_ERROR,
        payload: error
    }
}

const setFeed = (feed) => {
    return {
        type: Types.FEED_LOADED,
        payload: feed
    }
}

const setFeedPageItems = (feed) => {
    return {
        type: Types.FEED_ADD_PAGE,
        payload: feed
    }
}

const setDiscoverPageItems = (feed) => {
    return {
        type: Types.FEED_ADD_DISCOVER_PAGE,
        payload: feed
    }
}

export const setActiveFeedIndex = (feedIndex) => {
    return {
        type: Types.FEED_INDEX,
        payload: feedIndex
    }
}

export const setActiveFeedItem = (feedItem) => {
    return {
        type: Types.FEED_ACTIVE_ITEM,
        payload: feedItem
    }
}

export const setActiveScrollPost = (post) => {
    return {
        type: Types.FEED_ACTIVE_SCROLL_POST,
        payload: post
    }
}

export const setFeedType = (feedType) => {
    return {
        type: Types.FEED_TYPE,
        payload: feedType
    }
}

export const setFeedSettingsType = (feedType) => {
    return {
        type: Types.FEED_SETTINGS_TYPE,
        payload: feedType
    }
}

const clearDiscoverFeeed = () => {
    return {
        type: Types.FEED_CLEAR_DISCOVER,
        payload: []
    }
}

export const getFeedPage = (page) => {
    return (dispatch) => {
        API.get(`/api/v1/feed?page=${page}`).then((response) => {
            if (response.error) {
                dispatch(setFeedErrorState(response.error));
                return;
            }

            let feed = response.feed.map((c) => {
                c.style = JSON.parse(c.style);
                if (c.style.color && ! c.style.fill) {
                    c.style.fill = c.style.color;
                }
                if (c.profile_pic_crop) {
                    c.profile_pic_crop = JSON.parse(c.profile_pic_crop);
                }
                return c;
            });

            dispatch(setFeedPageItems(feed));
            dispatch(setFeedLoadingState(false));
        }, (err) => {
            dispatch(setFeedErrorState('error.network'));
            dispatch(setFeedLoadingState(false));
        });
    }
}

export const getFeed = (page) => {
    return (dispatch) => {
        API.get(`/api/v1/feed?page=${page}`).then((response) => {
            if (response.error) {
                dispatch(setFeedErrorState(response.error));
                return;
            }

            let feed = response.feed.map((c) => {
                c.style = JSON.parse(c.style);
                if (c.style.color && ! c.style.fill) {
                    c.style.fill = c.style.color;
                }
                if (c.profile_pic_crop) {
                    c.profile_pic_crop = JSON.parse(c.profile_pic_crop);
                }
                return c;
            });

            dispatch(setFeed(feed));
            dispatch(setFeedLoadingState(false));
        }, (err) => {
            dispatch(setFeedErrorState('error.network'));
            dispatch(setFeedLoadingState(false));
        });
    }
}

export const getDiscoverPage = (page) => {
    return (dispatch) => {
        if (page === 1) {
            dispatch(clearDiscoverFeeed());
        }

        API.get(`/api/v1/discover/feed?page=${page}`).then((response) => {
            if (response.error) {
                dispatch(setFeedErrorState(response.error));
                return;
            }

            let feed = response.posts;

            dispatch(setDiscoverPageItems(feed));
            dispatch(setDiscoverLoadingState(false));
        }, (err) => {
            //dispatch(setDiscoverErrorState('error.network'));
            dispatch(setDiscoverLoadingState(false));
        });
    }
}

export const setActiveChannel = (channel) => {
    return (dispatch) => {
        dispatch({
            type: Types.ACTIVE_CHANNEL,
            payload: channel
        });
    }
}

export const setShareData = (data) => {
    return (dispatch) => {
        dispatch({
            type: Types.EXTERNAL_SHARE,
            payload: data,
        });
    }
}