/**
 * Holds logged in user data
 */
import { Types } from '../constants/actionTypes';

const initialState = {
    id: null
};

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case Types.USER_LOGIN:
            let d = new Date();
            return { ...state, ...action.payload, ...{ session_id: `${action.payload.id}-${d.getTime()}` } };
        case Types.USER_DATA: {
            return { ...state, ...action.payload };
        }
        case Types.USER_LOGOUT:
            return {};
        case Types.USER_LOGIN_ERROR:
            return { ...state, error: action.payload };
        case Types.USER_REGISTER:
            return { ...state, didJustRegister: action.payload };
        default:
            return state;
    }
}