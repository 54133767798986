import React, { useEffect, useState } from 'react';
import { FontAwesome5, AntDesign, MaterialIcons, Entypo } from '@expo/vector-icons';
import { Button, TextInput, Text, View, StyleSheet, ScrollView, useWindowDimensions, TouchableOpacity, Image, Modal } from 'react-native';

import { connect } from 'react-redux';
import { useNavigation } from '@react-navigation/native';

import API from '../../libs/API';

const FeedItemTools = (props) => {
    const navigation = useNavigation();

    const [showVote, setShowVote] = useState(false);
    const [hasVoted, setHasVoted] = useState(props.post.voted);

    const vote = (points) => {
        setTimeout(() => {
            if (showVote) {
                setShowVote(false);
            }

            API.post(`/post/${props.post.id}/vote`, { points }).then((postResponse) => {
                setHasVoted(true);
                if (postResponse.error) {
                    //reject(postResponse.error);
                    return;
                }

                //resolve(postResponse.vote);
                // at some point will display a lottie component
            }, (err) => {
                //reject('error.api_error');
            });
        }, 200);
    }

    const handleVote = () => {
        if (!props.user.id) {
            return;
        }
        
        if (props.post.user_id != props.user.id) {
            vote(1);
        } else {
            navigation.push("PostStats", { postId: props.post.id });
        }
    }

    const votedStyle = hasVoted ? { color: "#F72585" } : {};

    return (
        <View style={[styles.container, props.toolsContainerOverride ]}>
            <View style={[styles.vote_container, props.toolsVoteContainerOverride ]}>
                <TouchableOpacity onPress={(e) => { handleVote(); e.stopPropagation(); }} onLongPress={(e) => { if (props.user.id) { setShowVote(!showVote); e.stopPropagation(); } }} style={{ alignItems: "center", marginBottom: 10 }}>
                    <FontAwesome5 name="fire" size={props.toolsContainerIconSize ? props.toolsContainerIconSize : 35} color="white" style={{...styles.icon, ...votedStyle}} />
                    <Text style={[styles.vote_count, votedStyle ]}>{props.post.points}</Text>
                </TouchableOpacity>
            </View>
            <TouchableOpacity style={styles.comments_container} onPress={(e) => { e.stopPropagation(); navigation.push("PostView", { postId: props.post.id }) }}>
                <MaterialIcons name="mode-comment" size={props.toolsContainerIconSize ? props.toolsContainerIconSize : 30} color="white" style={styles.icon} />
                <Text style={styles.vote_count}>{props.post.comment_count}</Text>
            </TouchableOpacity>
            { props.post.is_public == true && !!props.user.id &&
                <TouchableOpacity style={styles.share_container} onPress={() => { navigation.navigate("SharePost", { postId: props.post.id }) }}>
                    <FontAwesome5 name="share" size={props.toolsContainerIconSize ? props.toolsContainerIconSize : 24} color="white" style={styles.icon} />
                </TouchableOpacity>
            }
            <Modal
                animationType="fade"
                transparent={true}
                presentationStyle="overFullScreen"
                visible={showVote == true}
                onDismiss={() => { setShowVote(false) }}
                onRequestClose={() => {
                    //Alert.alert('Modal has been closed.');
                }}>
                <TouchableOpacity onPress={(e) => { e.stopPropagation(); setShowVote(false); }} style={styles.centeredView}>
                <View style={styles.modalView}>
                    <View style={{ flexDirection: "column" }}>
                        <Text style={styles.vote_title}>Vote up (+1) or down (-).</Text>
                        <Text style={{ fontSize: 16, color: "white", textAlign: "center" }}>Let your opinion be heard.</Text>
                    </View>
                    <TouchableOpacity style={styles.vote_up_container} onPress={(e) => { vote(1); e.stopPropagation(); }}>
                        <Entypo name="chevron-thin-up" size={35} color="#ffffff" />
                        <Text style={styles.vote_up_text}>+1</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.vote_down_container} onPress={(e) => { vote(-1); e.stopPropagation(); }}>
                        <View style={{ flex: 1, alignItems: "flex-end", justifyContent: "flex-end"}}>
                            <Text style={styles.vote_down_text}>-1</Text>
                        </View>
                        <Entypo name="chevron-thin-down" size={35} color="#ffffff" />
                    </TouchableOpacity>
                </View>
                </TouchableOpacity>
            </Modal>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        position: "absolute",
        right: 0,
        top: 0,
        height: "100%",
        paddingBottom: 220,
        flexDirection: "column",
        zIndex: 30,
        justifyContent: "flex-end",
        alignItems: "center",
        marginRight: 20,
        elevation: 1,
        opacity: 0.95,
        overflow: "visible"
    },
    vote_container: {
        position: "relative",
        top: 0,
        left: 0,
        zIndex: 20,
        overflow: "visible",
        backgroundColor: "rgba(0,0,0,0.1)",
        borderRadius: 30,
        padding: 5,
    },
    vote_title: {
        color: "white",
        fontSize: 24,
        fontWeight: "bold",
        textAlign: "center"
    },
    vote_up_down_container: {
        flex: 1,
        borderRadius: 40,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 20,
    },
    vote_up_container: {
        flexDirection: "column",
        width: 80,
        height: 80,
        backgroundColor: "#4895EF",
        borderRadius: 40,
        alignItems: "center",
        marginTop: 20
    },
    vote_up_text: {
        textAlign: "center",
        textAlignVertical: "center",
        fontSize: 20,
        color: "#ffffff"
    },
    vote_down_container: {
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
        borderRadius: 40,
        backgroundColor: "#F72585",
        width: 80,
        height: 80,
        marginTop: 30
    },
    vote_down_text: {
        textAlign: "center",
        textAlignVertical: "bottom",
        alignItems: "flex-end",
        justifyContent: "flex-end",
        fontSize: 20,
        color: "#ffffff"
    },
    vote_count: {
        fontSize: 16,
        textAlign: "center",
        color: "white",
        textShadowColor: "rgba(0,0,0,0.4)",
        marginTop: 3,
        fontWeight: "bold",
        textShadowRadius: 2,
        backgroundColor : "rgba(0,0,0,0)",
        textShadowOffset: {
            width: 0, height: 0
        },
    },
    comments_container: {
        marginTop: 15,
        overflow: "visible",
        backgroundColor: "rgba(0,0,0,0.1)",
        borderRadius: 30,
        padding: 5,
    },
    share_container: {
        marginTop: 20,
        overflow: "visible",
        backgroundColor: "rgba(0,0,0,0.1)",
        borderRadius: 30,
        padding: 5,
    },
    icon: {
        shadowColor: 'black',
        backgroundColor : "rgba(0,0,0,0)",
        elevation: 2,
        overflow: "visible",
        textShadowColor: "rgba(0,0,0,0.4)",
        textShadowRadius: 2,
        textShadowOffset: {
            width: 0, height: 0
        },
        overflow: "visible",
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: "rgba(0,0,0,0.4)"
    },
    modalView: {
        margin: 20,
        borderRadius: 20,
        padding: 35,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        flexDirection: "column",
        elevation: 5
    },
    openButton: {
        backgroundColor: '#F194FF',
        borderRadius: 20,
        padding: 10,
        elevation: 2,
    },
    textStyle: {
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    modalText: {
        marginBottom: 15,
        textAlign: 'center',
    }
});

const mapStateToProps = state => ({
    user: state.user
});

const mapDispatchToProps = dispatch => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedItemTools);