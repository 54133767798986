/**
 * Holds logged in user data
 */
 import { Types } from '../constants/actionTypes';

 const initialState = {
     tabStyle: null
 };
 
 export const settingsReducer = (state = initialState, action) => {
     switch (action.type) {
         case Types.TAB_STYLE:
             return { ...state, tabStyle: action.payload };
         default:
             return state;
     }
 }