import openSocket from 'socket.io-client';
import Storage from '../libs/Storage';
import API from '../libs/API.js';
import * as NavigationService from './NavigationService';

class Events {
    listeners;
    listenerCache;
    listenerIds;
    socketMessages;
    userLogoutListener;
    socket;
    authed;

    constructor() {
        // home-grown pub-sub
        this.listeners = {};
        this.listenerCache = {};
        this.listenerIds = 0;
        this.authed = false;
        this.refreshChannel = true;

        this.socketMessages = [];

        setInterval(() => {
            this.processOutboundMessages();
        }, 100);

        this.userLogoutListener = this.subscribe("user.logged_out", (user) => {
            this.socket.disconnect();
        });

        this.subscribe("user.logged_in", (user) => {
            this.connectToServer();
        });

    }

    connectToServer() {
        API.get('/api/v1/socket').then(async (r) => {
            let server = 'https://slyde-api-1.slyde.network';
            let jwt = await Storage.get('jwt');
            if (r.server) {
                server = r.server;
            }
            console.debug("Connecting to socket server", server);
            this.socket = openSocket(server, {
                transports: ['websocket'],
                transportOptions: {
                    polling: {
                        extraHeaders: {
                            'Authorization': 'Bearer ' + jwt
                        }
                    }
                }
            });

            this.socketSubscribe();
        });
    }

    disconnectFromServer() {
        this.socket.disconnect(true);
    }

    sendMessageToServer(event, msg, callback) {
        this.socketMessages.push({ msg: { event, data: msg }, callback });
    }

    processOutboundMessages() {
        if (this.socket && this.socket.connected && this.authed) {
            let msg = this.socketMessages.pop();
            if (msg) {
                this.socket.emit(msg.msg.event, msg.msg.data);
                if (msg.callback) {
                    msg.callback(msg.msg);
                }
            }
        }
    }

    socketSubscribe() {
        this.socket.on('auth', (data) => {
            if (data.success) {
                this.authed = true;
                this.publish("connected", true);
            }
        });

        this.socket.on('connect', async () => {
            let jwt = await Storage.get('jwt');
            this.socket.emit('auth', { jwt });
        });

        this.socket.on('disconnect', () => {
            console.log("DISCONNECTED");
            this.authed = false;
        });

        this.socket.on('user.status', (status) => {
            this.publish("user.status", status);
        });

        this.socket.on('chat', (msg) => {
            console.debug('chat message recv');
            this.publish('chat', msg);
        });

        this.socket.on('comment.new', (msg) => {
            this.publish('comment.new', msg);
        });

        this.socket.on('notification', (data) => {
            this.publish('notification', data);
        });
    }

    subscribe(topic, callback) {
        if (typeof this.listeners[topic] === "undefined") {
            // there are no listeners for this even yet
            this.listeners[topic] = [];
        }

        this.listeners[topic][this.listenerIds] = callback;

        const returnId = this.listenerIds;

        this.listenerCache[returnId] = topic;

        this.listenerIds++;

        return returnId;
    }

    publish(topic, payload) {
        if (typeof this.listeners[topic] !== "undefined") {
            // there are no listeners for this even yet
            this.listeners[topic].forEach((elm, i) => {
                elm(topic, payload);
            });
        }
    }

    unsubscribe(listenerId) {
        const topic = this.listenerCache[listenerId];

        delete this.listeners[topic][listenerId];

        return true;
    }
}

const events = new Events();

export default events;