import React, { useEffect, useState } from 'react';
import { Entypo } from '@expo/vector-icons';
import { Button, TextInput, Text, View, StyleSheet, ScrollView, useWindowDimensions, TouchableOpacity, Image } from 'react-native';
import { connect } from 'react-redux';

import AudioService from '../../services/AudioService';

const ThemeSong = (props) => {
    const theme_song = props.song ? props.song : props.parent.theme_song;

    useEffect(() => {
        if (props.song && props.song.preview != props.parent.theme_song.preview) {
            AudioService.play(theme_song.preview);
        }
    }, [props.song])

    const play = () => {
        if (props.audio.current_song != theme_song.preview) {
            AudioService.play(theme_song.preview);
        } else {
            AudioService.stop();
        }
    }

    return (
        <View style={styles.container}>
            <TouchableOpacity onPress={() => { play() }} style={{ flexDirection: "row", flex: 1, alignContent: "stretch" }}>
                <View style={styles.music_art_container}>
                    <Image source={{ uri: theme_song.art }} style={{ width: 40, height: 40 }} />
                </View>
                <View style={styles.play_button_container}>
                    { props.audio.current_song != theme_song.preview &&
                        <Entypo name="controller-play" size={24} color="white" />
                    }
                    { props.audio.current_song == theme_song.preview &&
                        <Entypo name="controller-stop" size={24} color="white" />
                    }
                </View>
                <View style={styles.music_title_container}>
                    <View style={{ textAlign: "left", overflow: "hidden" }}>
                        <Text allowFontScaling={false} style={styles.music_title_person}>{props.parent.real_name ? props.parent.real_name : `@${props.parent.username}`}'s Theme Song</Text>
                        <Text allowFontScaling={false} style={styles.music_title_song} numberOfLines={1}>{theme_song.name} ({theme_song.artist})</Text>
                    </View>
                </View>
                <View style={styles.spotify_container}>
                    <Image source={require('../../assets/spotify.png')} style={styles.spotify} />
                </View>
            </TouchableOpacity>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: "row",
        height: 40,
        marginTop: 10
    },
    music_art_container: {
        width: 40,
        marginRight: 10,
        justifyContent: "center",
        alignItems: "center"
    },
    play_button_container: {
        width: 30,
        marginRight: 7,
        justifyContent: "center",
        alignItems: "center"
    },
    music_title_container: {
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        flex: 1,
    },
    music_title_person: {
        fontSize: 12,
        color: "rgba(255,255,255,0.8)",
        textAlign: "left",
        justifyContent: "flex-start"
    },
    music_title_song: {
        fontSize: 14,
        fontWeight: "bold",
        color: "#ffffff",
        justifyContent: "center",
        alignItems: "center"
    },
    spotify_container: {
        justifyContent: "center",
        alignItems: "center",
        width: 30
    },
    spotify: {
        width: 30,
        height: 30,
        borderRadius: 30,
        marginTop: 5
    }
});

const mapStateToProps = state => ({
    user: state.user,
    audio: state.audio
});

const mapDispatchToProps = dispatch => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(ThemeSong);