/**
 * Holds logged in user data
 */
import { Types } from '../constants/actionTypes';
import Storage from '../libs/Storage';

const initialState = {
    currentPage: 1,
    loading: true,
    discover_loading: true,
    error: null,
    items: [],
    discover_items: [],
    activeIndex: 0,
    activeItem: null,
    activeScrollPost: null,
    feedType: 'feed',
    settings: {
        type: 'traditional'
    },
    activeChannel: null,
    shareData: null,
};

export const feedReducer = (state = initialState, action) => {
    switch (action.type) {
        case Types.FEED_LOADING:
            return { ...state, loading: action.payload };
        case Types.FEED_DISCOVER_LOADING:
            return { ...state, discover_loading: action.payload };
        case Types.FEED_LOADED:
            return { ...state, items: action.payload };
        case Types.FEED_ERROR:
            return { ...state, error: action.payload };
        case Types.FEED_INDEX:
            return { ...state, activeIndex: action.payload };
        case Types.FEED_ADD_PAGE:
            return { ...state, items: [...state.items, ...action.payload]};
        case Types.FEED_CLEAR_DISCOVER:
            return { ...state, items: [] };
        case Types.FEED_ADD_DISCOVER_PAGE:
            return { ...state, discover_items: [...state.discover_items, ...action.payload]};
        case Types.FEED_ACTIVE_ITEM:
            return {...state, activeItem: action.payload};
        case Types.FEED_ACTIVE_SCROLL_POST:
            return {...state, activeScrollPost: action.payload};
        case Types.FEED_TYPE:
            return {...state, feedType: action.payload};
        case Types.FEED_SETTINGS_TYPE:
            Storage.set('feedSettingsType', action.payload);
            return {...state, settings: { ...state.settings, type: action.payload }}
        case Types.ACTIVE_CHANNEL:
            return {...state, activeChannel: action.payload}
        case Types.EXTERNAL_SHARE:
            return {...state, shareData: action.payload}
        default:
            return state;
    }
}