import * as React from 'react';
import { useEffect, useState, useMemo, useRef } from 'react';
import { Button, TextInput, Text, View, StyleSheet, ScrollView, useWindowDimensions, TouchableOpacity, Image, Pressable, Modal } from 'react-native';
import { SafeAreaView, useSafeAreaInsets } from 'react-native-safe-area-context';
import { connect } from 'react-redux';
import { Ionicons } from '@expo/vector-icons';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { FontAwesome } from '@expo/vector-icons';
import TaggedInput from '../Input/TaggedInput';
import API from '../../libs/API';
import ScaledImage from '../common/ScaledImage';
import ScaledVideo from '../common/ScaledVideo';
import Video from '../common/Video';
import TwitterTextView from '../common/TextView';

import InViewport from '../common/InViewport';

import PostFooter from './PostFooter';
import useOnScreen from '../common/useOnScreen';

const FeedMediaImage = (props) => {
    const [resizeMode, setResizeMode] = useState("cover");

    useEffect(() => {
        let src = props.media.source_url;
        if (src && src.includes("cloudinary.com/slydenetwork")) {
            // we have one
            let parts = src.split('/');
            let publicId = parts[parts.length - 1];

            src = `https://res.cloudinary.com/slydenetwork/image/upload/w_800/${publicId}`;
        }
        
        if (['image/jpeg', 'image/gif', 'image/png', 'image/jpg'].includes(props.media.type)) {
            Image.getSize(src, (width, height) => {
                if ((width / height) > 0.75) {
                    setResizeMode("contain");
                }
            }, (err) => {
                console.log("There was an error getting size for image: ", props.media.source_url);
            });
        }
    }, []);

    let src = props.media.source_url;

    if (src && src.includes("cloudinary.com/slydenetwork")) {
        // we have one
        let parts = src.split('/');
        let publicId = parts[parts.length - 1];

        src = `https://res.cloudinary.com/slydenetwork/image/upload/w_800/${publicId}`;
    }

    return (
            <ScaledImage uri={src} width={props.width} />
    )
}

const FeedMediaVideo = (props) => {
    const [shouldPlay, setShouldPlay] = useState(false);
    const video = useRef(null);
    const window = useWindowDimensions();
    const ref = useRef();
    const isOnScreen = useOnScreen(ref);

    useEffect(() => {
        if (isOnScreen) {
            setShouldPlay(true);
        } else {
            setShouldPlay(false);
        }
    }, [isOnScreen]);

    return (
        <View style={{ flex: 1 }} ref={ref}>
            <ScaledVideo uri={props.media.source_url} width={props.width ? props.width : window.width} maxHeight={props.maxHeight} useNativeControls={true} shouldPlay={shouldPlay} autoplay={true} muted={true} isLooping={true} resizeMode={`contain`} dontAutoplay={props.dontAutoplay} isMuted={true} />
        </View>
    )
}

const PostMedia = React.forwardRef((props, ref) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [user, setUser] = useState(null);
    const [post, setPost] = useState(null);
    const [playVideo, setPlayVideo] = useState(false);
    const [showPhotoRoll, setShowPhotoRoll] = useState(false);
    const [activePhoto, setActivePhoto] = useState(null);
    const navigation = useNavigation();
    const window = useWindowDimensions();

    useEffect(() => {
        //console.log("New User post", props.post, props.parent);
        for (let i of props.post.media) {
            if (['image/jpeg', 'image/gif', 'image/png', 'image/jpg'].includes(i.type)) {
                Image.getSize(i.source_url, () => {});
            }
        }
    }, []);

    useEffect(() => {
        //console.log("active item changed!", props.activeItem.item.id, props.post.id);
        if (props.activeItem && props.activeItem.item && props.activeItem.item.id == props.post.id) {
            //console.log("Play video!");
            setPlayVideo(true);
        } else {
            setPlayVideo(false);
        }
    }, [props.activeItem]);

    useEffect(() => {
        if (showPhotoRoll) {
            setPlayVideo(false);
        } else {
            setPlayVideo(true);
            setActivePhoto(null);
        }
    }, [showPhotoRoll]);

    const onPress = (e) => {
        if (!props.disableTap) {
            if (e.nativeEvent.locationX < (window.width / 3)) {
                setActiveIndex(activeIndex == 0 ? 0 : activeIndex - 1);
            } else {
                setActiveIndex(activeIndex == (props.post.media.length - 1) ? activeIndex : activeIndex + 1 );
            }
        } else {
            navigation.push("PostView", { post: props.post, user: props.post.user, noHeader: true })
        }
    }

    const media = useMemo(() => {
        if (['image/jpeg', 'image/gif', 'image/png', 'image/jpg'].includes(props.post.media[activeIndex].type)) {
            return (
                <Pressable style={{ flex: 1 }} onPress={() => setShowPhotoRoll(true)}>
                    <img key={`post-media-${props.post.id}-view-${activeIndex}`} style={{ maxWidth: "100%", maxHeight: props.fit ? "calc(100vh - 100px)" : "calc(100vh * .8)", backgroundColor: "black", objectFit: "contain" }} src={props.post.media[activeIndex].source_url} />
                </Pressable>
            );
        } else {
            return <FeedMediaVideo media={props.post.media[activeIndex]} width={"100%"} maxHeight={props.fit ? "calc(100vh - 100px)" : "calc(100vh * .8)"} rounded={props.isFeed} dontAutoplay={showPhotoRoll} />;
        }
    }, [activeIndex, showPhotoRoll]);

    const zoomMedia = props.post.media.map((m, i) => {
        let mContent = null;
        if (['image/jpeg', 'image/gif', 'image/png', 'image/jpg'].includes(m.type)) {
            mContent = (
                <View style={{ position: "relative" }}>
                    <img src={props.post.media[i]} style={{ maxWidth: "100%", maxHeight: "calc(100vh * .8)", backgroundColor: "black", objectFit: "contain" }} />
                </View>
            );
        } else {
            mContent = <FeedMediaVideo key={`post-media-${props.post.id}-view-max-${i}`} media={props.post.media[i]} width={window.width - 20} maxHeight={window.height * .5} rounded={false} muted={true} />;
        }

        return (
            <View style={{ marginBottom: 15 }} minZoom={1}>
                {mContent}
            </View>
        );
    })

    const textOptions = props.isPost == false ? {
        numberOfLines: 4
    } : {};

    return (
        <View style={styles.container}>
            <View style={{ maxWidth: "100%", maxHeight: "100%", justifyContent: "center", alignItems: "center", position: "relative", backgroundColor: "rgba(0,0,0,0.9)" }}>
                {media}
                { props.post.media.length > 1 &&
                    <Pressable style={{ position: "absolute", top: 20, right: 20, borderRadius: 14, backgroundColor: "rgba(0,0,0,0.6)", padding: 12, flexDirection: "row", alignItems: "center" }} onPress={() => setShowPhotoRoll(true)}>
                        <Text style={{ color: "white", fontSize: 12, marginRight: 10 }}>1 / {zoomMedia.length}</Text>
                        <FontAwesome name="photo" size={16} color="white" />
                    </Pressable>
                }
            </View>
            <Modal visible={showPhotoRoll} style={{ backgroundColor: "rgba(0,0,0,0.6)", flexDirection: "column" }} transparent={true} onRequestClose={() => setShowPhotoRoll(false)}>
                <SafeAreaView edges={['top', 'bottom']} style={{ flex: 1, backgroundColor: "rgba(0,0,0,0.9)"}}>
                    <View style={{ flexDirection: "row", padding: 15 }}>
                        <View style={{ flex: 1 }}>
                            <Text style={{ color: "white", fontSize: 16 }}>Showing {props.post.media.length}</Text>
                        </View>
                        <Pressable onPress={() => setShowPhotoRoll(false)}><Text style={{ color: "white" }}>Close</Text></Pressable>
                    </View>
                    <ScrollView style={{ flex: 1, width: "100%", alignContent: "center", padding: 10 }}>
                        {zoomMedia}
                    </ScrollView>
                </SafeAreaView>
                { showPhotoRoll == true && activePhoto !== null &&
                    <Modal visible={activePhoto !== null} transparent={false} onRequestClose={() => setActivePhoto(null)}>
                        <SafeAreaView edges={['top', 'bottom']} style={{ flex: 1, backgroundColor: "rgba(0,0,0,1)", alignContent: "center", justifyContent: "center", flexDirection: "column"}}>
                            <View style={{ flexDirection: "row", padding: 15 }}>
                                <View style={{ flex: 1 }}>
                                    
                                </View>
                                <Pressable onPress={() => setActivePhoto(null)}><Text style={{ color: "white" }}>Close</Text></Pressable>
                            </View>
                            <View style={{ marginBottom: 15 }} minZoom={1} maxZoom={30}>
                                { activePhoto !== null &&
                                    <FeedMediaImage key={`post-photo-view`} width={window.width - 20} media={activePhoto} rounded={false} />
                                }
                            </View>
                        </SafeAreaView>
                    </Modal>
                }
            </Modal>
            { !props.noFooter &&
                <PostFooter post={props.post} parent={props.user} isPost={props.isPost} />
            }
            { props.post.post_media != '' && props.post.post_media != null && !props.noFooter &&
                <Pressable onPress={onPress}>
                    <TwitterTextView
                        style={{ fontSize: 20, padding: 15, paddingTop: 5, fontWeight: 600 }}
                        hashtagStyle={{ fontWeight: 'bold', color: '#F72585' }}
                        mentionStyle={{ fontWeight: 'bold', color: '#4361EE' }}
                        onPressMention={(e, mention) => { e.stopPropagation(); navigation.push('Profile', { username: mention.trim().substring(1) }) }}
                        onPressHashtag={(e, hashtag) => { e.stopPropagation(); navigation.push('Search', { terms: hashtag.trim() }) }}
                        {...textOptions}
                    >{props.post.post_media}</TwitterTextView>
                </Pressable>
            }
            { props.post.channel_id != null && !props.hideChannel && !props.noFooter &&
                <TouchableOpacity onPress={(e) => { navigation.push("Channel", { channelId: props.post.channel_tag }) }} style={{ paddingLeft: 15, marginBottom: 20 }}>
                    <TwitterTextView
                        style={{ ...styles.caption_text, fontSize: 16 }}
                        hashtagStyle={{ fontWeight: 'bold', color: '#48cae4' }}
                        mentionStyle={{ fontWeight: 'bold', color: '#4361EE' }}
                        onPressHashtag={(e, hashtag) => { e.stopPropagation(); navigation.push('Channel', { channelId: props.post.channel_id }) }}
                    >{`Posted in #${props.post.channel_tag}`}</TwitterTextView>
                </TouchableOpacity>
            }
        </View>
    );
})

const styles = StyleSheet.create({
    container: {
        flexDirection: "column"
    },
    big_text_container: {
        flex: 1,
        justifyContent: "center",
        padding: 15
    },
    big_text: {
        fontSize: 30,
        fontFamily: 'Inter_900Black',
        textAlign: "center",
        color: "#000000"
    },
    profile_pic: {
        flex: 1,
        position: "relative",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 2,
        justifyContent: "center"
    },
    video: {
        flex: 1,
        position: "relative",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 2,
    },
    profile_pic_bg: {
        flex: 1,
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 1,
        opacity: 0.5
    },
});

const mapStateToProps = state => ({
    user: state.user,
    feed: state.feed
});

const mapDispatchToProps = dispatch => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(PostMedia);