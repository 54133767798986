import { useNavigation } from '@react-navigation/native';
import React, { useState, useEffect } from 'react';
import { Ionicons } from '@expo/vector-icons';
import { Button, TextInput, Text, View, StyleSheet, ScrollView, useWindowDimensions, SafeAreaView, FlatList, TouchableOpacity, Image } from 'react-native';
import { useIsMounted } from './isMounted';

import API from '../../libs/API';

const PeopleOnSlyde = (props) => {
    const [page, setPage] = useState(null);
    const [users, setUsers] = useState([]);
    const isMounted = useIsMounted();

    const navigation = useNavigation();

    useEffect(() => {
        if (page === null) {
            setPage(1);
        } else {
            // get the first page of users
            API.get(`/api/v1/user/suggested?page=${page}`).then((response) => {
                if (isMounted && setUsers) {
                    if (response.error) {
                        return;
                    }

                    const newUsers = response.connections.map((c) => {
                        c.style = JSON.parse(c.style);
                        if (c.profile_pic_crop) {
                            c.profile_pic_crop = JSON.parse(c.profile_pic_crop);
                        }
                        return c;
                    });
                    

                    if (page == 1) {
                        setUsers([...newUsers]);
                    } else {
                        setUsers([...users, ...newUsers]);
                    }
                }
            }, (err) => {
                console.log(err);
            });
        }
    }, [page]);

    const renderUser = ({ item, index }) => {
        let user = item;

        return (
            <TouchableOpacity onPress={(e) => { navigation.push("Profile", { username: user.username }) }} style={{ flexDirection: "column", alignItems: "center", alignContent: "center", padding: 20, paddingLeft: 30, paddingRight: 30, borderRightWidth: 1, borderRightColor: "rgba(0,0,0,0.05)", maxWidth: 200 }} key={`search-user-result-${user.id}-${index}`}>
                <Image source={{ uri: user.profile_pic_url }} style={styles.profile_pic} resizeMode={`cover`} />
                <View style={{ backgroundColor: user.style.backgroundColor ? user.style.backgroundColor : "rgba(0,0,0,0.5)", height: 4, width: "80%", marginTop: 15, marginBottom: 15 }}></View>
                <View style={{ flexGrow: 1, flexDirection: "column", alignItems: "center" }}>
                    <Text style={{ ...styles.user_name }}>{user.real_name ? user.real_name : user.username}</Text>
                    {user.tagline !== '' &&
                        <Text style={{ color: "rgba(0,0,0,0.5)", textAlign: "center" }}>{user.tagline}</Text>
                    }
                    <Text style={{}}>{user.connections} followers</Text>
                    <Text style={{ color: "rgba(0,0,0,0.7)", fontWeight: "bold" }}>View Profile</Text>
                </View>
            </TouchableOpacity>
        )
    }

    return (
        <View style={{ flexDirection: "column", paddingBottom: 15, backgroundColor: "white" }}>
            <View style={{ padding: 15, flexDirection: "row", alignItems: "center" }}>
                <Ionicons name="people-circle-sharp" size={26} color="black" />
                <Text style={{ fontWeight: "bold", fontSize: 22, marginLeft: 5 }}>More People On Slyde</Text>
            </View>
            <View style={{ borderTopWidth: 5, width: "50%", borderTopColor: "#560bad", marginBottom: 20 }}></View>
            <FlatList
                horizontal={true}
                contentContainerStyle={{}}
                showsHorizontalScrollIndicator={false}
                data={users}
                renderItem={renderUser}
                onEndReached={() => { setPage(page + 1); }}
                onEndReachedThreshold={0.5}
                initialNumToRender={5}
                keyExtractor={(item, index) => `user-scroller-suggested-user-${item.id}-${index}`}
            />
            <View style={{ borderTopWidth: 5, width: "50%", borderTopColor: "#560bad", marginBottom: 20 }}></View>
            <View style={{ padding: 15, flexDirection: "row", alignItems: "center" }}>
                <Ionicons name="people-circle-sharp" size={26} color="black" />
                <Text style={{ fontWeight: "bold", fontSize: 22, marginLeft: 5 }}>Slyde is better with people you know!</Text>
            </View>
            <View style={{ padding: 15, paddingTop: 0, display: "none" }}>
                <Text>We made sharing slyde easy, just tap the button below to share Slyde with people you know on other platforms.</Text>
                <TouchableOpacity onPress={(e) => { navigation.navigate("ShareCode", { showTabs: props.showTabs }) }} style={{ padding: 15, backgroundColor: "black", marginTop: 20, alignItems: "center" }}>
                    <Text style={{ fontSize: 24, color: "white" }}>Share Slyde</Text>
                </TouchableOpacity>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: "column",
        backgroundColor: "#ffffff"
    },
    searchToggles: {
        flexDirection: "row",
        height: 60,
        borderTopWidth: 1
    },
    searchToggleButton: {

    },
    submit: {
        backgroundColor: "#000000",
        color: "white",
        padding: 15,
        margin: 15
    },
    submit_text: {
        textAlign: "center",
        color: "white",
        fontSize: 20,
        fontWeight: "bold"
    },
    section_header: {
        margin: 10,
        paddingTop: 10,
        padding: 10,
        flexDirection: "column",
        justifyContent: "center",
        borderBottomColor: "black",
        borderBottomWidth: 1,
    },
    section_header_title: {
        fontSize: 24,
        fontWeight: "bold",
        textAlign: "center"
    },
    section_header_description: {
        fontSize: 18,
        paddingTop: 10,
        textAlign: "center"
    },
    loader: {
        width: 200,
        height: 200,
    },
    profile_content_area: {
        flexDirection: "row",
        borderBottomWidth: 1,
        backgroundColor: "white"
    },
    profile_pic_container: {
        paddingLeft: 10,
    },
    profile_pic: {
        width: 70,
        height: 70,
        borderRadius: 10
    },
    profile_name: {
        flex: 1,
        flexDirection: "row",
        paddingLeft: 10,
    },
    profile_name_text: {
        fontSize: 18,
        fontWeight: "bold",
        color: "black",
        width: "100%"
    },
    caption_container: {
        fontSize: 18,
        width: "100%"
    },
    input_option_container: {
        marginTop: 10,
        marginLeft: 15
    },
    media_container: {
        maxHeight: 100,
        maxWidth: 100
    },
    name: {
        flexDirection: "row",
        color: "black",
        backgroundColor: "white",
        borderTopWidth: 1,
        borderTopColor: "rgba(0,0,0,0.05)",
        alignItems: "center"
    },
    user_name: {
        color: '#000000',
        fontSize: 20,
        fontWeight: "bold"
    },
    text_post_override: {
        fontSize: 18,
    },
});

export default PeopleOnSlyde;