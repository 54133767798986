import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { feedReducer } from '../reducers/Feed';
import { userReducer } from '../reducers/User';
import { audioReducer } from '../reducers/Audio';
import { settingsReducer } from '../reducers/Settings';
import { notificationsReducer } from '../reducers/Notifications';
import { chatsReducer } from '../reducers/Chat';

const rootReducer = combineReducers({
  user: userReducer,
  feed: feedReducer,
  audio: audioReducer,
  settings: settingsReducer,
  notifications: notificationsReducer,
  chat: chatsReducer
});

export const store = createStore(
  rootReducer,
  applyMiddleware(thunkMiddleware)
);