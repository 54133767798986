import * as React from 'react';
import { useEffect, useState } from 'react';
import { TextInput, Text, StyleSheet, ScrollView, useWindowDimensions, SafeAreaView, Image, TouchableOpacity, Pressable } from 'react-native';
import TextView from "../common/TextView";

const TaggedInput = React.forwardRef((props, ref) => {
    const [content, setContent] = useState([]);
    const [mentionCount, setMentionCount] = useState(0);

    useEffect(() => {
        if (props.content) {
            let newCount = 0;
            let newContent = props.content.split(' ').map((t, i) => {
                if (t[0] == '@' && t.length > 1) {
                    newCount++;
                    return (<Text key={`mention-${i}-${newCount}`} style={{  }}>{t}</Text>);
                }
                if (t[0] == '#' && t.length > 1) {
                    newCount++;
                    return (<Text key={`hashtag-${i}-${newCount}`} style={{  }}>{t}</Text>);
                }

                return (<Text key={`plain-${i}`}>{t}</Text>);
            }).flatMap((value, index, array) => {
                return array.length - 1 !== index ? [value, <Text key={`space-${index}`}> </Text>] : value
            });

            setContent(newContent);
            setMentionCount(false);
        } else {
            setContent('');
            setMentionCount(false);
        }
    }, [props.content]);

    if (typeof props.content == "undefined") {
        return <TextInput
            {...props}
            ref={ref}
            keyboardType="twitter"
            selectionColor={`#F72585`}
        >
        </TextInput>
    }

    return (
        <TextInput
            {...props}
            value={props.content}
            ref={ref}
            keyboardType="twitter"
            selectionColor={`#F72585`}
        >
            <Text>{content}</Text>
        </TextInput>
    )
})

export default TaggedInput;