class StorageService {
    async set(key, value) {
        // value will almost always be a string so...
        localStorage.setItem(key, value);
    }
    
    async get(key) {
        return new Promise((resolve, reject) => {
            resolve(localStorage.getItem(key));
        });
    }
    
    async remove(key) {
        return new Promise((resolve, reject) => {
            resolve(localStorage.removeItem(key))
        });
    }
}

let storageService = new StorageService();

export default storageService;