import * as React from 'react';
import { useEffect, useState, useRef, useMemo } from 'react';
import { Button, Modal, Text, View, StyleSheet, Pressable, useWindowDimensions, Image, TouchableHighlight, ScrollView } from 'react-native';
import { SafeAreaView, useSafeAreaInsets } from 'react-native-safe-area-context';
import { FontAwesome, Ionicons } from '@expo/vector-icons';
import { useIsFocused, useNavigation } from '@react-navigation/native';

import Video from '../common/Video';
import { connect } from 'react-redux';

import { getFeed } from '../../actions/FeedActions';

import FeedItemFooter from './FeedItemFooter';
import FeedItemTools from './FeedItemTools';
import InViewport from '../common/InViewport';
import { TouchableOpacity } from 'react-native-gesture-handler';
import ScaledImage from '../common/ScaledImage';
import ScaledVideo from '../common/ScaledVideo';

const FeedMediaImage = (props) => {
    const [resizeMode, setResizeMode] = useState("cover");

    useEffect(() => {
        let src = props.media.source_url;
        if (src && src.includes("cloudinary.com/slydenetwork")) {
            // we have one
            let parts = src.split('/');
            let publicId = parts[parts.length - 1];

            src = `https://res.cloudinary.com/slydenetwork/image/upload/w_800/${publicId}`;
        }
        
        if (['image/jpeg', 'image/gif', 'image/png', 'image/jpg'].includes(props.media.type)) {
            Image.getSize(src, (width, height) => {
                if ((width / height) > 0.75 && resizeMode) {
                    setResizeMode("contain");
                }
            }, (err) => {
                console.log("There was an error getting size for image: ", props.meida.source_url);
            });
        }
    }, []);

    let src = props.media.source_url;

    if (src && src.includes("cloudinary.com/slydenetwork")) {
        // we have one
        let parts = src.split('/');
        let publicId = parts[parts.length - 1];

        src = `https://res.cloudinary.com/slydenetwork/image/upload/w_800/${publicId}`;
    }

    return (
        <View style={{ flex: 1 }}>
            { resizeMode == "contain" &&
                <Image source={{ uri: src }} style={styles.profile_pic_bg} resizeMode={`cover`} blurRadius={50} />
            }
            <Image source={{ uri: src }} style={styles.profile_pic} resizeMode={resizeMode} />
        </View>
    )
}

const FeedMediaVideoComponent = (props) => {
    const [muted, setMuted] = useState(props.smallMode ? true : false);
    const [playing, setPlaying] = useState(true);
    const [paused, setPaused] = useState(false);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [resizeMode, setResizeMode] = useState('cover');
    const navigation = useNavigation();
    const ref = useRef();
    const pauseRef = useRef();
    const window = useWindowDimensions();
    const isFocused = useIsFocused();

    useEffect(() => {
        if (props.feed.feedType != 'feed') {
            if (playing) {
                ref.current.pause();
            }
        }
    }, [props.feed.feedType]);

    useEffect(() => {
        if (!isFocused && ref?.current?.stopAsync) {
            pauseRef.current = true;
            ref.current?.pause().then(() => {
                setPlaying(false);
            });
        }
    }, [isFocused]);

    useEffect(() => {
        if (props.dontAutoplay && ref?.current?.stopAsync) {
            console.log("Pausing video");
            pauseRef.current = true;
            ref.current.pause().then(() => {
                setPlaying(false);
            });
        }
    }, [props.dontAutoplay]);

    const updateSize = (e) => {
        setWidth(e.nativeEvent.layout.width);
        setHeight(e.nativeEvent.layout.height);
    }

    const handlePlaying = (isVisible) => {
        if (ref.current && isFocused && !props.dontAutoplay) {
            if (isVisible && !paused) {
                pauseRef.current = false;
                ref.current.play();
                setPlaying(true);
            } else {
                if (playing && !pauseRef.current) {
                    pauseRef.current = true;
                    ref.current.pause();
                    setPlaying(false);
                }
            }
        }
    }

    const togglePlay = (e) => {
        if (ref.current) {
            if (paused) {
                pauseRef.current = false;
                ref.current.play();
                setPaused(false);
            } else {
                if (!pauseRef.current) {
                    pauseRef.current = true;
                    ref.current.pause();
                    setPaused(true);
                }
            }
        }
    }

    const handleLoad = (e) => {
        //console.log(e);
        const videoRatio = e.naturalSize.width / e.naturalSize.height;

        if (videoRatio >= 0.75) {
            setResizeMode('contain');
        }
    }

    return (
        <View style={{ flex: 1 }} onLayout={updateSize}>
            <TouchableHighlight onPress={(e) => { e.stopPropagation(); setMuted(!muted); }} style={{ alignItems: "center", marginBottom: 10, position: "absolute", left: 20, bottom: 200, zIndex: 20, backgroundColor: "rgba(0,0,0,0.4)", borderRadius: 40, padding: 5 }}>
                <View>
                    { muted == true &&
                        <Ionicons name="ios-volume-mute-sharp" size={24} color="white" />
                    }
                    { muted == false &&
                        <Ionicons name="ios-volume-medium-sharp" size={24} color="white" />
                    }
                </View>
            </TouchableHighlight>
            { paused == true &&
                <View pointerEvents="none" style={{ position: "absolute", top: 0, left: 0, width: width, height: height, alignContent: "center", justifyContent: "center", alignItems: "center", zIndex: 20 }}>
                    <Ionicons name="ios-play" size={80} color="rgba(255,255,255,0.8)" />
                </View>
            }
            <InViewport onChange={handlePlaying} style={{flex: 1}}>
                <TouchableOpacity onPress={(e) => {  togglePlay(e) }} style={{ width: width, height: height }}>
                    <Video ref={ref} source={{ uri: props.media.source_url }} style={{...styles.profile_pic, zIndex: 10 }} onReadyForDisplay={handleLoad} useNativeControls={false} shouldPlay={false} muted={muted} isLooping={true} resizeMode={resizeMode} />
                </TouchableOpacity>
            </InViewport>
        </View>
    )
}

const mapVideoStateToProps = state => ({
    feed: state.feed
});

const mapVideoDispatchToProps = dispatch => ({
    getFeed: page => dispatch(getFeed(page)),
});

const FeedMediaVideo = connect(mapVideoStateToProps, mapVideoDispatchToProps)(FeedMediaVideoComponent);

const MediaImage = (props) => {
    const [resizeMode, setResizeMode] = useState("cover");

    useEffect(() => {
        let src = props.media.source_url;
        if (src && src.includes("cloudinary.com/slydenetwork")) {
            // we have one
            let parts = src.split('/');
            let publicId = parts[parts.length - 1];

            src = `https://res.cloudinary.com/slydenetwork/image/upload/w_800/${publicId}`;
        }
        
        if (['image/jpeg', 'image/gif', 'image/png', 'image/jpg'].includes(props.media.type)) {
            Image.getSize(src, (width, height) => {
                if ((width / height) > 0.75) {
                    setResizeMode("contain");
                }
            }, (err) => {
                console.log("There was an error getting size for image: ", props.media.source_url);
            });
        }
    }, []);

    let src = props.media.source_url;

    if (src && src.includes("cloudinary.com/slydenetwork")) {
        // we have one
        let parts = src.split('/');
        let publicId = parts[parts.length - 1];

        src = `https://res.cloudinary.com/slydenetwork/image/upload/w_800/${publicId}`;
    }

    return (
        <View style={{ flex: 1 }}>
            <img src={src} width={props.width} />
        </View>
    )
}

const MediaVideo = (props) => {
    const video = useRef(null);
    const window = useWindowDimensions();

    return (
        <ScaledVideo uri={props.media.source_url} width={props.width ? props.width : "100%"} maxHeight={props.maxHeight} useNativeControls={true} shouldPlay={false} isLooping={true} resizeMode={`contain`} dontAutoplay={props.dontAutoplay} />
    )
}

const FeedMedia = (props) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const videoRef = React.createRef();
    const navigation = useNavigation();
    const window = useWindowDimensions();
    const [showPhotoRoll, setShowPhotoRoll] = useState(false);
    const [activePhoto, setActivePhoto] = useState(null);

    useEffect(() => {
        for (let i of props.post.media) {
            let src = i.source_url;

            if (src && src.includes("cloudinary.com/slydenetwork")) {
                // we have one
                let parts = src.split('/');
                let publicId = parts[parts.length - 1];

                src = `https://res.cloudinary.com/slydenetwork/image/upload/w_800/${publicId}`;
            }

            if (['image/jpeg', 'image/gif', 'image/png', 'image/jpg'].includes(i.type)) {
                Image.getSize(src, () => {});
            }
        }
    }, []);

    useEffect(() => {
        if (!showPhotoRoll) {
            setActivePhoto(null);
        }
    }, [showPhotoRoll]);

    const mediaThumbs = props.post.media.map((m, i) => {
        let mContent = null;
        const size = props.smallMode ? 20 : 45;
        const borderRadius = props.smallMode ? 3 : 10;

        if (['image/jpeg', 'image/gif', 'image/png', 'image/jpg'].includes(m.type)) {
            mContent =  <Image source={{ uri: m.source_url }} resizeMode="contain" style={{ width: size, height: size, borderWidth: 2, borderColor: activeIndex == i ? "white" : "rgba(255,255,255,0.3)", borderRadius, backgroundColor: "black" }} />;
        } else {
            mContent = <Video feedItem={props.parent} source={{ uri: m.source_url }} shouldPlay={false} resizeMode="contain" style={{ width: size, height: size, borderWidth: 2, borderColor: activeIndex == i ? "white" : "rgba(255,255,255,0.3)", borderRadius, backgroundColor: "black" }} />;
        }

        return <TouchableOpacity onPress={(e) => { setActiveIndex(i); }} key={`feed-post-${props.post.id}-media-${m.id}-thumb`} style={{ padding: 5 }}>{mContent}</TouchableOpacity>
    })

    const media = useMemo(() => {
        if (['image/jpeg', 'image/gif', 'image/png', 'image/jpg'].includes(props.post.media[activeIndex].type)) {
            return (
                <Pressable style={{ flex: 1 }} onPress={() => setShowPhotoRoll(true)}>
                    <FeedMediaImage key={`feed-post-${props.post.id}-media-${activeIndex}`} media={props.post.media[activeIndex]} />
                </Pressable>
            );
        } else {
            return <FeedMediaVideo media={props.post.media[activeIndex]} post={props.post} feedItem={props.parent} smallMode={props.smallMode} dontAutoplay={showPhotoRoll} />;
        }
    }, [activeIndex, showPhotoRoll]);

    const zoomMedia = props.post.media.map((m, i) => {
        let mContent = null;
        if (['image/jpeg', 'image/gif', 'image/png', 'image/jpg'].includes(m.type)) {
            mContent = (
                <View style={{ position: "relative" }}>
                    <MediaImage key={`post-media-${props.post.id}-view-max-${i}`} width={"100%"} media={props.post.media[i]} rounded={false} />
                    <Pressable style={{ position: "absolute", bottom: 10, right: 10, borderRadius: 10, backgroundColor: "rgba(0,0,0,0.4)", padding: 7 }} onPress={() => { setActivePhoto(m); console.log("Setting active photo", m) }}>
                        <FontAwesome name="expand" size={24} color="rgba(255,255,255,0.6)" />
                    </Pressable>
                </View>
            );
        } else {
            mContent = <MediaVideo key={`post-media-${props.post.id}-view-max-${i}`} media={props.post.media[i]} width={"100%"} maxHeight={window.height * .7} rounded={false} muted={true} />;
        }

        return (
            <View style={{ marginBottom: 15 }} minZoom={1}>
                {mContent}
            </View>
        );
    })

    return (
        <View style={styles.container}>
            <View style={{ flex: 1, position: "relative", width: "100%", backgroundColor: "rgba(0,0,0,0.1)" }}>
                {media}
                { zoomMedia.length > 1 &&
                    <View style={{position: "absolute", top: 0, left: 0, width: "100%", zIndex: 70 }}>
                        <SafeAreaView edges={['top']} style={{ alignContent: "center", justifyContent: "center", flexDirection: "row", paddingTop: 70 }}>
                            <Pressable style={{ borderRadius: 14, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, backgroundColor: "rgba(0,0,0,0.3)", padding: 12, flexDirection: "row", alignItems: "center", marginLeft: 0 }} onPress={(e) => { setShowPhotoRoll(true); e.stopPropagation() }}>
                                <Text style={{ color: "white", fontSize: 12, marginRight: 10 }}>1 / {zoomMedia.length}</Text>
                                <FontAwesome name="photo" size={16} color="rgba(255,255,255,0.6)" />
                            </Pressable>
                            <View style={{ flex: 1 }}></View>
                        </SafeAreaView>
                    </View>
                }
            </View>
            <Modal visible={showPhotoRoll} style={{ backgroundColor: "rgba(0,0,0,0.6)", flexDirection: "column" }} transparent={true} onRequestClose={() => setShowPhotoRoll(false)}>
                <SafeAreaView edges={['top', 'bottom']} style={{ flex: 1, backgroundColor: "rgba(0,0,0,0.9)"}}>
                    <View style={{ flexDirection: "row", padding: 15 }}>
                        <View style={{ flex: 1 }}>
                            <Text style={{ color: "white", fontSize: 16 }}>Showing {props.post.media.length}</Text>
                        </View>
                        <Pressable onPress={() => setShowPhotoRoll(false)}><Text style={{ color: "white" }}>Close</Text></Pressable>
                    </View>
                    <ScrollView style={{ flex: 1, width: "100%", alignContent: "center", padding: 10 }}>
                        {zoomMedia}
                    </ScrollView>
                </SafeAreaView>
                <Modal visible={activePhoto !== null} transparent={false} onRequestClose={() => setActivePhoto(null)}>
                    <SafeAreaView edges={['top', 'bottom']} style={{ flex: 1, backgroundColor: "rgba(0,0,0,1)"}}>
                        <View style={{ flexDirection: "row", padding: 15 }}>
                            <View style={{ flex: 1 }}>
                                
                            </View>
                            <Pressable onPress={() => setActivePhoto(null)}><Text style={{ color: "white" }}>Close</Text></Pressable>
                        </View>
                        <View minZoom={1} maxZoom={30} initialZoom={1}>
                            { activePhoto !== null &&
                                <MediaImage key={`post-photo-view`} width={"100%"} media={activePhoto} rounded={false} />
                            }
                        </View>
                    </SafeAreaView>
                </Modal>
            </Modal>
            { !props.noFooter &&
                <FeedItemFooter caption={props.post.post_media} {...props} />
            }
            { !props.noTools &&
                <FeedItemTools {...props} />
            }
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: "column",
        backgroundColor: "#000000",
    },
    profile_pic: {
        flex: 1,
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 2,
        justifyContent: "center"
    },
    profile_pic_bg: {
        flex: 1,
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 1,
        opacity: 0.5
    },
});

const mapStateToProps = state => ({
    user: state.user,
    activeItem: state.feed.activeItem
});

const mapDispatchToProps = dispatch => ({
    getFeed: page => dispatch(getFeed(page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedMedia);