import React from 'react';

const Video = React.forwardRef((props, ref) => {
    const newStyle = { ...props.style, ...{ backgroundColor: "black" }};

    return (
        <video
            src={props.source.uri}
            style={newStyle}
            ref={ref}
            controls={true}
            muted={props.muted}
            autoPlay={props.shouldPlay}
            loop={props.isLooping}
            playsInline={true}
        />
    )
})

export default Video;