import React, { Component, PropTypes } from "react";
import { Image, View } from "react-native";

export default class ScaledImage extends Component {
    constructor(props) {
        super(props);
        this.state = { source: { uri: this.props.uri } };
    }

    UNSAFE_componentWillMount() {
        Image.getSize(this.props.uri, (width, height) => {
            if (this.props.width && !this.props.height) {
                this.setState({
                    width: this.props.width,
                    height: height * (this.props.width / width)
                });
            } else if (!this.props.width && this.props.height) {
                this.setState({
                    width: width * (this.props.height / height),
                    height: this.props.height
                });
            } else {
                this.setState({ width: width, height: height });
            }

            if (this.props.onLoad) {
                this.props.onLoad(width, height);
            }
        });
    }

    render() {
        return (
                <Image
                    source={this.state.source}
                    style={{ height: this.state.height, width: this.state.width, borderRadius: this.props.rounded ? 20 : 0 }}
                />
        );
    }
}