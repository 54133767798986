import API from '../libs/API';
import Storage from '../libs/Storage';

import { Types } from '../constants/actionTypes';

import Events from '../services/EventService';
import { setFeedSettingsType } from './FeedActions';

export const setTabStyle = (style) => {
    if (style && (!style.color || !style.backgroundColor)) {
        style = null;
    }
    return {
        type: Types.TAB_STYLE,
        payload: style,
    };
};