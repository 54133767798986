import API from '../libs/API';
import Storage from '../libs/Storage';

import { Types } from '../constants/actionTypes';

import Events from '../services/EventService';
import { setFeedSettingsType } from './FeedActions';

const setLoginState = (loginData) => {
    return {
        type: Types.USER_LOGIN,
        payload: loginData,
    };
};

const setLoginError = (error) => {
    return {
        type: Types.USER_LOGIN_ERROR,
        payload: error
    }
}

const setUserDetails = (userData) => {
    return {
        type: Types.USER_DATA,
        payload: userData
    }
}

const setUserLogout = () => {
    return {
        type: Types.USER_LOGOUT,
        payload: null
    }
}

const setUserRegistered = (didJustRegister) => {
    return {
        type: Types.USER_REGISTER,
        payload: didJustRegister
    }
}

export const registered = (reg) => {
    return (dispatch) => {
        dispatch(setUserRegistered(reg));
    }
}

export const login = (loginData) => {
    return (dispatch) => {
        API.post('/api/v1/auth', { email: loginData.email, password: loginData.password }).then(async (userResponse) => {
            if (userResponse.error) {
                dispatch(setLoginError(userResponse.error));
                return;
            }

            API.setJWT(userResponse.jwt);
            Storage.set('jwt', userResponse.jwt);

            dispatch(setLoginState(userResponse.user));
            
            API.get('/api/v1/user/me').then(async (response) => {
                // do nothing here for now
                if (response.error) {
                    console.debug('error getting user');
                    return;
                }
                let personas = { work: null, friends: null, family: null, public: null, channels: {}};
    
                for (let persona of response.personas) {
                    if (typeof persona.style == "string") {
                        persona.style = JSON.parse(persona.style);
                    }
                    if (["work", "friends", "family", "public"].includes(persona.type)) {
                        personas[persona.type] = persona;
                    } else {
                        if (persona.type == 'channel') {
                            personas.channels[persona.channel_id] = persona;
                        }
                    }
                }
    
                let user = response.user;
    
                let channelMods = {};
    
                for (let m of response.channelMods) {
                    channelMods[m.id] = m; 
                }
    
                Events.connectToServer();
    
                dispatch(setLoginState(user));
                dispatch(setUserDetails({ channelMods, personas }));
            });
        });
    }
}

export const logout = () => {
    return async (dispatch) => { 
        await Storage.remove('jwt');

        window.location.href = "/";

        dispatch(setUserLogout());
    }
}

export const syncUser = () => {
    return (dispatch) => {
        API.get('/api/v1/user/me').then(async (response) => {
            // do nothing here for now
            if (response.error) {
                console.debug('error getting user');
                return;
            }
            let personas = { work: null, friends: null, family: null, public: null, channels: {}};

            for (let persona of response.personas) {
                if (typeof persona.style == "string") {
                    persona.style = JSON.parse(persona.style);
                }
                if (["work", "friends", "family", "public"].includes(persona.type)) {
                    personas[persona.type] = persona;
                } else {
                    if (persona.type == 'channel') {
                        personas.channels[persona.channel_id] = persona;
                    }
                }
            }

            let user = response.user;

            let channelMods = {};

            for (let m of response.channelMods) {
                channelMods[m.id] = m; 
            }

            Events.connectToServer();

            dispatch(setLoginState(user));
            dispatch(setUserDetails({ channelMods, personas }));

            let feedSettingsType = await Storage.get('feedSettingsType');

            if (feedSettingsType) {
                dispatch(setFeedSettingsType(feedSettingsType));
            }
        }, (err) => {
            console.log(err);
        });
    }
}