import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, TextInput, Text, View, StyleSheet, SafeAreaView, useWindowDimensions, Platform, FlatList, TouchableHighlight, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';
import Popover from 'react-native-popover-view';

import { Feather, Octicons, Ionicons, MaterialIcons, FontAwesome, MaterialCommunityIcons } from '@expo/vector-icons';

import FeedScroller from './FeedScroller';

import { getFeed, getFeedPage, setActiveFeedIndex, setFeedType } from '../../actions/FeedActions';
import { useNavigation } from '@react-navigation/native';

const DiscoverTools = (props) => {
    const [fillWidth, setFillWidth] = useState(0);
    const [fillHeight, setFillHeight] = useState(0);
    const [activeItem, setActiveItem] = useState(null);
    const [showOptions, setShowOptions] = useState(false);
    const [activeFeed, setActiveFeed] = useState('discover');
    const [page, setPage] = useState(1);
    const [loadingMore, setLoadingMore] = useState(false);
    const navigation = useNavigation();

    useEffect(() => {
        //props.getFeed(1);
    }, []);

    useEffect(() => {
        props.setFeedType(activeFeed);
        props.onSetFeedType(activeFeed);
    }, [activeFeed]);

    // ios can use paging which makes it better
    return (
        <View style={[styles.container, { borderBottomWidth: 1, borderBottomColor: props.feedSettings.type == 'full' ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,0.05)', paddingBottom: props.feedSettings.type == 'full' ? 20 : 0 }]}>
            <View style={[styles.top_area, { paddingBottom: props.feedSettings.type == 'full' ? 20 : 10 }]}>
                <View style={styles.top_content}>
                    <Popover
                        isVisible={showOptions}
                        onRequestClose={() => { setShowOptions(false) }}
                        popoverStyle={{ backgroundColor: "white" }}
                        from={(
                            <TouchableOpacity onPress={(e) => { setShowOptions(!showOptions) }} style={{ alignContent: "center", justifyContent: "center", flexDirection: "row", paddingTop: 10 }}>
                                {activeFeed == "feed" &&
                                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                                        <FontAwesome name="feed" size={20} color={props.feedSettings.type == 'full' ? 'white' : 'black'} />
                                        <Text style={[styles.feed_toggle_item_active, { color: props.feedSettings.type == 'full' ? 'white' : 'black' }]}>Feed</Text>
                                        <MaterialIcons name="keyboard-arrow-down" size={24} color={props.feedSettings.type == 'full' ? 'white' : 'black'} />
                                    </View>
                                }
                                {activeFeed == "discover" &&
                                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                                        <MaterialCommunityIcons name="newspaper-variant-multiple-outline" size={20} color={props.feedSettings.type == 'full' ? 'white' : 'black'} />
                                        <Text style={[styles.feed_toggle_item_active, { color: props.feedSettings.type == 'full' ? 'white' : 'black' }]}>Discover</Text>
                                        <MaterialIcons name="keyboard-arrow-down" size={24} color={props.feedSettings.type == 'full' ? 'white' : 'black'} />
                                    </View>
                                }
                                {activeFeed == "channel" &&
                                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                                        <FontAwesome name="hashtag" size={20} color={props.feedSettings.type == 'full' ? 'white' : 'black'} />
                                        <Text style={[styles.feed_toggle_item_active, { color: props.feedSettings.type == 'full' ? 'white' : 'black' }]}>My Channels</Text>
                                        <MaterialIcons name="keyboard-arrow-down" size={24} color={props.feedSettings.type == 'full' ? 'white' : 'black'} />
                                    </View>
                                }
                            </TouchableOpacity>
                        )}>
                        <View style={styles.feed_toggles}>
                            <TouchableOpacity style={styles.feed_toggle_item_container} onPress={(e) => { setActiveFeed('feed'); setShowOptions(false); }}>
                                <View style={{ flexDirection: "row", alignItems: "center" }}>
                                    <FontAwesome name="feed" size={20} color="rgba(0,0,0,0.6)" />
                                    <Text style={styles.feed_toggle_item}>Feed</Text>
                                </View>
                            </TouchableOpacity>
                            <View style={{ width: 2, height: 30, backgroundColor: "white", marginLeft: 15, marginRight: 15, marginTop: -5 }}></View>
                            <TouchableOpacity style={styles.feed_toggle_item_container} onPress={(e) => { setActiveFeed('discover'); setShowOptions(false); }}>
                                <View style={{ flexDirection: "row", alignItems: "center" }}>
                                    <MaterialCommunityIcons name="newspaper-variant-multiple-outline" size={20} color="rgba(0,0,0,0.6)" />
                                    <Text style={styles.feed_toggle_item}>Discover</Text>
                                </View>
                            </TouchableOpacity>
                            <View style={{ width: 2, height: 30, backgroundColor: "white", marginLeft: 15, marginRight: 15, marginTop: -5 }}></View>
                            <TouchableOpacity style={styles.feed_toggle_item_container} onPress={(e) => { setActiveFeed('channel'); setShowOptions(false); }}>
                                <View style={{ flexDirection: "row", alignItems: "center" }}>
                                    <FontAwesome name="hashtag" size={20} color="rgba(0,0,0,0.6)" />
                                    <Text style={styles.feed_toggle_item}>My Channels</Text>
                                </View>
                            </TouchableOpacity>
                        </View>
                    </Popover>
                    <View style={{ flex: 1 }}></View>
                    {/* <TouchableOpacity style={styles.top_settings} onPress={(e) => { e.stopPropagation(); navigation.push('FeedSettings') }}>
                        <Octicons name="settings" size={24} color={props.feedSettings.type == 'full' ? 'white' : 'black'} style={{ marginTop: 5 }} />
                    </TouchableOpacity> */}
                    <TouchableOpacity style={styles.top_search_container} onPress={(e) => { e.stopPropagation(); navigation.push('Search'); }}>
                        <Feather name="search" size={24} color={props.feedSettings.type == 'full' ? 'white' : 'black'} style={{ marginTop: 5 }} />
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: "column",
        width: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 20,
        paddingBottom: 20,
        backgroundColor: "white",
    },
    feed_toggles: {
        flexDirection: "column",
        marginTop: 15,
        padding: 20,
        paddingBottom: 20
    },
    feed_toggle_item_container: {
        marginRight: 0
    },
    feed_toggle_item: {
        fontSize: 20,
        color: "rgba(0,0,0,0.6)",
        fontWeight: "bold",
        marginLeft: 10
    },
    feed_toggle_item_active: {
        fontSize: 20,
        color: "white",
        fontWeight: "bold",
        textAlign: "center",
        marginLeft: 10, marginRight: 3
    },
    top_area: {
        padding: 20,
        paddingTop: 0,
    },
    top_content: {
        flexDirection: "row"
    },
    top_search_container: {
        paddingTop: 10
    },
    top_settings: {
        paddingTop: 10,
        marginRight: 15
    }
});

const mapStateToProps = state => ({
    user: state.user,
    feed: state.feed.items,
    feedSettings: state.feed.settings
});

const mapDispatchToProps = dispatch => ({
    setFeedType: type => dispatch(setFeedType(type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DiscoverTools);