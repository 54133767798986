/**
 * Holds logged in user data
 */
import { Types } from '../constants/actionTypes';

const initialState = {
    unread: 0
};

export const notificationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case Types.NOTIFICATION_COUNT:
            return { ...state, unread: action.payload };
        default:
            return state;
    }
}