import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, TextInput, Text, View, StyleSheet, ScrollView, useWindowDimensions, Linking, Image } from 'react-native';
import { connect } from 'react-redux';

import { getFeed } from '../../actions/FeedActions';

import FeedItemFooter from './FeedItemFooter';
import FeedItemTools from './FeedItemTools';
import { TouchableOpacity } from 'react-native-gesture-handler';

const FeedLink = (props) => {
    const [hideTools, setHideTools] = React.useState(false);
    const [linkData, setLinkData] = React.useState(null);
    const [showCaption, setShowCaption] = useState(true);
    const [linkImage, setLinkImage] = useState(null);

    React.useEffect(() => {
        let title = null;
        let description = null;
        let image = null;
        let favicon = null;
        let link = null;
        let url = null;

        let data = JSON.parse(props.post.link_data);
        if (data) {
            if (data.title) {
                title = data.title.substring(0,70);
                if (data.title.length > 70) {
                    title += '...';
                }
            }
            description = data.description;
            favicon = data.favicon;
            if (data.oEmbed) {
                // preferred
                if (!title && data.oEmbed.title) {
                    title = data.oEmbed.title;
                }
                url = data.oEmbed.url;
                if (data.oEmbed.thumbnails && data.oEmbed.thumbnails.length > 0) {
                    image = data.oEmbed.thumbnails[0].url;
                } else {
                    if (data.open_graph && data.open_graph.images) {
                        image = data.open_graph.images[0].url;
                    } 
                }
            } else {
                if (data.open_graph) {
                    url = data.open_graph.url;
                    if (!title) {
                        title = data.open_graph.title;
                    }
                    if (data.open_graph.images && data.open_graph.images.length > 0) {
                        image = data.open_graph.images[0].url;
                    }
                }
            }

            if (!image && data.images) {
                image = data.images[0].url;
            }

            if (!url) {
                url = data.url;
            }
            
            setLinkImage(image);
        }

        setLinkData({
            title,
            image,
            favicon,
            link,
            url,
            description
        })
    }, [props.post]);
    
    return (
        <View style={[styles.container, props.parent.style ]}>
            { linkData &&
                <View style={styles.link_body}>
                    <View style={styles.link_title}>
                        { linkData.favicon &&
                            <Image source={{ uri: linkData.favicon }} style={styles.link_favicon} />
                        }
                        { !props.clickToView &&
                            <Text 
                                onPress={() => { try { window.open(linkData.url); console.log(linkData.url) } catch (e) { console.log(e, linkData) } }}
                                style={[styles.link_title_text, { fontSize: props.smallMode ? 14 : styles.link_title_text.fontSize }]}
                                >{`${linkData.title}`}</Text>
                        }
                        { props.clickToView &&
                            <Text style={[styles.link_title_text]}>{`${linkData.title}`}</Text>
                        }
                    </View>
                    { linkImage && !props.hideLinkImage &&
                        <View style={styles.link_image_container}>
                            { !props.clickToView &&
                                <TouchableOpacity onPress={() => { try { window.open(linkData.url); console.log(linkData.url) } catch (e) { console.log(e, linkData) } }}>
                                    <Image source={{ uri: linkImage }} style={styles.link_image} resizeMode={`contain`} />
                                </TouchableOpacity>
                            }
                            { props.clickToView &&
                                <Image source={{ uri: linkImage }} style={styles.link_image} resizeMode={`contain`} />
                            }
                        </View>
                    }
                    { !props.hideLinkDescription &&
                        <View style={styles.link_description}>
                            { !props.clickToView &&
                                <Text onPress={() => { try { window.open(linkData.url); console.log(linkData.url) } catch (e) { console.log(e) } }}>{`${linkData.description}`}</Text>
                            }
                            { props.clickToView &&
                                <Text>{`${linkData.description}`}</Text>
                            }
                        </View>
                    }
                </View>
            }
            { !props.noTools &&
                <FeedItemTools {...props} />
            }
            <FeedItemFooter caption={props.post.post_media} {...props} />
        </View>
    );
}

const styles = {
    container: {
        flex: 1,
        flexDirection: "column",
        backgroundColor: "#000000",
        justifyContent: "center",
        alignItems: "center",
        justifyContent: 'center',
    },
    link_body: {
        width: "80%",
        zIndex: 2,
        justifyContent: "center",
        flexDirection: "column",
        position: "relative",
        borderRadius: 20,
        backgroundColor: "#ffffff"
    },
    link_favicon: {
        position: "absolute",
        top: -10,
        left: -10,
        width: 24,
        height: 24
    },
    link_title: {
        padding: 15
    },
    link_title_text: {
        fontFamily: 'Inter_900Black',
        fontSize: 20,
    },
    link_image_container: {
        padding: 15
    },
    link_image: {
        width: 300,
        height: 300,
        maxWidth: "100%",
        maxHeight: "100%",
        overflow: "hidden",
        backgroundColor: "#000000",
        borderRadius: 10
    },
    link_description: {
        padding: 15
    },
};

const mapStateToProps = state => ({
    user: state.user
});

const mapDispatchToProps = dispatch => ({
    getFeed: page => dispatch(getFeed(page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedLink);