import { useNavigation } from '@react-navigation/native';
import React, { useState, useEffect } from 'react';
import { Ionicons } from '@expo/vector-icons';
import { Button, TextInput, Text, View, StyleSheet, ScrollView, useWindowDimensions, SafeAreaView, FlatList, TouchableOpacity, Image } from 'react-native';
import { useIsMounted } from './isMounted';

import API from '../../libs/API';

const ContactSync = (props) => {
    const [page, setPage] = useState(null);
    const [users, setUsers] = useState([]);
    const isMounted = useIsMounted();

    const navigation = useNavigation();

    return (
        <View style={{ flexDirection: "column", paddingBottom: 15, backgroundColor: "white" }}>
            <View style={{ padding: 15, flexDirection: "row", alignItems: "center" }}>
                <Ionicons name="people-circle-sharp" size={26} color="black" />
                <Text style={{ fontWeight: "bold", fontSize: 22, marginLeft: 5 }}>Spread the word!</Text>
            </View>
            <View style={{ borderTopWidth: 5, width: "50%", borderTopColor: "#560bad", marginBottom: 20 }}></View>
            <View style={{ padding: 15, flexDirection: "row", alignItems: "center" }}>
                <Text style={{ fontWeight: "bold", fontSize: 22, marginLeft: 5 }}>You can sync contacts on your phone with Slyde to connect with people you know and share Slyde.</Text>
            </View>
            <View style={{ padding: 15, paddingTop: 0 }}>
                <Text>Sync your contacts on Slyde so you can connect with them here and be alerted when they join, we don't share your contacts' info with anyone.</Text>
                <TouchableOpacity onPress={(e) => { navigation.navigate("Contacts", { showTabs: props.showTabs }) }} style={{ padding: 15, backgroundColor: "black", marginTop: 20, alignItems: "center" }}>
                    <Text style={{ fontSize: 24, color: "white" }}>View Contacts</Text>
                </TouchableOpacity>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: "column",
        backgroundColor: "#ffffff"
    },
    searchToggles: {
        flexDirection: "row",
        height: 60,
        borderTopWidth: 1
    },
    searchToggleButton: {

    },
    submit: {
        backgroundColor: "#000000",
        color: "white",
        padding: 15,
        margin: 15
    },
    submit_text: {
        textAlign: "center",
        color: "white",
        fontSize: 20,
        fontWeight: "bold"
    },
    section_header: {
        margin: 10,
        paddingTop: 10,
        padding: 10,
        flexDirection: "column",
        justifyContent: "center",
        borderBottomColor: "black",
        borderBottomWidth: 1,
    },
    section_header_title: {
        fontSize: 24,
        fontWeight: "bold",
        textAlign: "center"
    },
    section_header_description: {
        fontSize: 18,
        paddingTop: 10,
        textAlign: "center"
    },
    loader: {
        width: 200,
        height: 200,
    },
    profile_content_area: {
        flexDirection: "row",
        borderBottomWidth: 1,
        backgroundColor: "white"
    },
    profile_pic_container: {
        paddingLeft: 10,
    },
    profile_pic: {
        width: 70,
        height: 70,
        borderRadius: 10
    },
    profile_name: {
        flex: 1,
        flexDirection: "row",
        paddingLeft: 10,
    },
    profile_name_text: {
        fontSize: 18,
        fontWeight: "bold",
        color: "black",
        width: "100%"
    },
    caption_container: {
        fontSize: 18,
        width: "100%"
    },
    input_option_container: {
        marginTop: 10,
        marginLeft: 15
    },
    media_container: {
        maxHeight: 100,
        maxWidth: 100
    },
    name: {
        flexDirection: "row",
        color: "black",
        backgroundColor: "white",
        borderTopWidth: 1,
        borderTopColor: "rgba(0,0,0,0.05)",
        alignItems: "center"
    },
    user_name: {
        color: '#000000',
        fontSize: 20,
        fontWeight: "bold"
    },
    text_post_override: {
        fontSize: 18,
    },
});

export default ContactSync;