import { useNavigation } from '@react-navigation/native';
import react from 'react';
import { useState, useEffect } from 'react';
import { Pressable, View, Text, ScrollView, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';
import { Ionicons, FontAwesome } from '@expo/vector-icons';
import API from '../../libs/API';
import { abbreviateNumber } from '../../libs/Numbers';

const UserLeftBar = (props) => {
    const [activeSection, setActiveSection] = useState('home');
    const [views, setViews] = useState(0);
    const [posts, setPosts] = useState(0);
    const [followers, setFollowers] = useState(0);
    
    const navigation = useNavigation();

    useEffect(() => {
        if (props.user) {
            API.get(`/api/v1/username/${props.user.username}`).then((result) => {
                if (result.error) {
                    return;
                }
                setFollowers(abbreviateNumber(result.user.connections));
                setViews(abbreviateNumber(result.user.views));
                setPosts(abbreviateNumber(result.user.posts));
            });
        }
    }, [props.user]);

    return (
        <ScrollView style={{ flexDirection: "columnn", width: "100%", backgroundColor: "white", flex: 1 }}>
            <View style={{ flexDirection: "column", width: "100%", padding: 10, backgroundColor: "white", borderBottomWidth: 1, borderBottomColor: "rgba(0,0,0,0.05)", alignItems: "center", justifyContent: "center", paddingTop: 20, paddingBottom: 20 }}>
                { !!props.user?.personas &&
                    <View style={{ width: "100%", alignItems: "center" }}>
                    <Pressable onPress={() => { navigation.navigate("Profile", { username: props.user.username }) }}>
                        <img src={props.user.personas['public'].profile_pic_url} style={{ width: 100, height: 100, borderRadius: 100, borderWidth: 4, border: `6px solid ${props.user.personas['public'].style.backgroundColor}`, borderColor: props.user.personas['public'].style.backgroundColor, overflow: "hidden" }} />
                    </Pressable>

                    <View style={{ flexDirection: "column", justifyContent: "center", alignItems: "center", flex: 1, padding: 10 }}>
                        <Text style={{ fontWeight: "bold", fontSize: 18, textAlign: "center" }}>@{props.user.username}</Text>
                        <Text style={{ color: "rgba(0,0,0,0.6)", fontSize: 12, textAlign: "center" }}>{props.user.personas.public.real_name}</Text>
                    </View>
                    </View>
                }
                <View style={{ flexDirection: "row", alignItems: "center", width: "100%" }}>
                    <View style={{ flex: 1 }} />
                    <View style={{ flexDirection: "column", justifyContent: "center", alignItems: "center", flex: 1, padding: 10 }}>
                        <Text style={{ fontWeight: "bold", fontSize: 16, textAlign: "center" }}>{followers}</Text>
                        <Text style={{ color: "rgba(0,0,0,0.6)", fontSize: 12, textAlign: "center" }}>Followers</Text>
                    </View>
                    <View style={{ flexDirection: "column", justifyContent: "center", alignItems: "center", flex: 1, padding: 10 }}>
                        <Text style={{ fontWeight: "bold", fontSize: 16, textAlign: "center" }}>{posts}</Text>
                        <Text style={{ color: "rgba(0,0,0,0.6)", fontSize: 12, textAlign: "center" }}>Posts</Text>
                    </View>
                    <View style={{ flexDirection: "column", justifyContent: "center", alignItems: "center", flex: 1, padding: 10 }}>
                        <Text style={{ fontWeight: "bold", fontSize: 16, textAlign: "center" }}>{views}</Text>
                        <Text style={{ color: "rgba(0,0,0,0.6)", fontSize: 12, textAlign: "center" }}>Views</Text>
                    </View>
                    <View style={{ flex: 1 }} />
                </View>
            </View>
            <View style={{ width: "100%", flex: 1, flexDirection: "column", marginTop: 10 }}>
                <TouchableOpacity onPress={() => { navigation.navigate('Feed'); setActiveSection('home'); }} style={styles.navButton}>
                    { activeSection === 'home' &&
                        <View style={styles.activeItem} />
                    }
                    <View style={styles.navButtonIconContainer}>
                        <Ionicons name="home" size={24} color={activeSection === 'home' ? "rgb(247, 37, 133)" : "rgba(0,0,0,0.4)"} />
                    </View>
                    <Text style={{...styles.navButtonText, color: activeSection === 'home' ? "black" : "rgba(0,0,0,0.4)" }}>Home</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => { navigation.navigate('ChannelList'); setActiveSection('channels'); }} style={styles.navButton}>
                    { activeSection === 'channels' &&
                        <View style={styles.activeItem} />
                    }
                    <View style={styles.navButtonIconContainer}>
                        <FontAwesome name="hashtag" size={24} color={activeSection === 'channels' ? "rgb(247, 37, 133)" : "rgba(0,0,0,0.4)"} />
                    </View>
                    <Text style={{...styles.navButtonText, color: activeSection === 'channels' ? "black" : "rgba(0,0,0,0.4)" }}>Channels</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => { navigation.navigate('Chats'); setActiveSection('chat'); }} style={styles.navButton}>
                    { activeSection === 'chat' &&
                        <View style={styles.activeItem} />
                    }
                    <View style={styles.navButtonIconContainer}>
                        <Ionicons name="chatbox-outline" size={24} color={activeSection === 'chat' ? "rgb(247, 37, 133)" : "rgba(0,0,0,0.4)"} />
                    </View>
                    <Text style={{...styles.navButtonText, color: activeSection === 'chat' ? "black" : "rgba(0,0,0,0.4)" }}>Chat</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => { navigation.navigate('AccountSettings'); setActiveSection('account'); }} style={styles.navButton}>
                    { activeSection === 'account' &&
                        <View style={styles.activeItem} />
                    }
                    <View style={styles.navButtonIconContainer}>
                        <Ionicons name="settings-outline" size={24} color={activeSection === 'account' ? "rgb(247, 37, 133)" : "rgba(0,0,0,0.4)"} />
                    </View>
                    <Text style={{...styles.navButtonText, color: activeSection === 'account' ? "black" : "rgba(0,0,0,0.4)" }}>Account</Text>
                </TouchableOpacity>
            </View>
        </ScrollView>
    )
}

const styles = {
    navButton: {
        padding: 15,
        paddingLeft: 0,
        width: "100%",
        flexDirection: "row",
        alignItems: "center",
    },
    navButtonText: {
        fontWeight: "bold",
        fontSize: 17,
        color: "rgba(0,0,0,0.4)",
    },
    navButtonIconContainer: {
        paddingLeft: 20,
        paddingRight: 20,
    },
    activeItem: {
        width: 1,
        height: 30,
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
        borderRightWidth: 4,
        borderRightColor: "rgb(247, 37, 133)"
    }
}

const mapStateToProps = state => ({
    user: state.user,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(UserLeftBar);