export const Types = {
    USER_LOGIN: 'USER_LOGIN',
    USER_LOGIN_ERROR: 'USER_LOGIN_ERROR',
    USER_REGISTER: 'USER_REGISTER',
    USER_LOGOUT: 'USER_LOGOUT',
    USER_UPDATE: 'USER_UPDATE',
    USER_DATA: 'USER_DATA',
    USER_REGISTER: 'USER_REGISTER',

    // FEED
    FEED_LOADING: 'FEED_LOADING',
    FEED_DISCOVER_LOADING: 'FEED_DISCOVER_LOADING',
    FEED_LOADED: 'FEED_LOADED',
    FEED_ERROR: 'FEED_ERROR',
    FEED_INDEX: 'FEED_INDEX',
    FEED_ADD_PAGE: 'FEED_PAGE',
    FEED_ADD_DISCOVER_PAGE: 'FEED_DISCOVER_PAGE',
    FEED_CLEAR_DISCOVER: 'FEED_CLEAR_DISCOVER',
    FEED_ACTIVE_ITEM: 'FEED_ACTIVE_ITEM',
    FEED_ACTIVE_SCROLL_POST: 'FEED_ACTIVE_SCROLL_POST',
    FEED_TYPE: 'FEED_TYPE',
    FEED_SETTINGS_TYPE: 'FEED_SETTING_TYPE',

    // AUDIO
    AUDIO_SET_SONG: 'AUDIO_SET_SONG',

    // SETTINGS
    TAB_STYLE: 'TAB_STYLE',

    // NOTIFICATIONS
    NOTIFICATION_COUNT: 'NOTIFICATION_COUNT',

    // CHAT
    CHAT_LIST: 'CHAT_LIST',
    CHAT_MESSAGE: 'CHAT_MESSAGE',
    CHAT_MESSAGES: 'CHAT_MESSAGES',
    CHAT_START: 'CHAT_START',
    CHAT_UNREAD: 'CHAT_UNREAD',

    // CHANNEL
    ACTIVE_CHANNEL: 'ACTIVE_CHANNEL',

    // SHARE
    EXTERNAL_SHARE: 'EXTERNAL_SHARE',
};