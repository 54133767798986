/**
 * Holds logged in user data
 */
import { Types } from '../constants/actionTypes';

const initialState = {
    current_song: null
};

export const audioReducer = (state = initialState, action) => {
    switch (action.type) {
        case Types.AUDIO_SET_SONG:
            return { ...state, current_song: action.payload };
        default:
            return state;
    }
}