import { useNavigation } from '@react-navigation/native';
import react from 'react';
import { Pressable, View } from 'react-native';
import { connect } from 'react-redux';
import { Ionicons } from '@expo/vector-icons';

import logo from './assets/purple-s.png';

const TopBar = (props) => {
    const navigation = useNavigation();

    return (
        <View style={{ flexDirection: "row", maxWidth: 1300, width: "100%", padding: 10, backgroundColor: "white", borderBottomWidth: 1, borderBottomColor: "rgba(0,0,0,0.05)" }}>
            <img src={logo} style={{ width: 50 }} />
            <View style={{ flex: 1 }}>

            </View>
            <View style={{ flexDirection: "row", alignItems: "center", paddingRight: 20 }}>
                <Pressable onPress={() => { navigation.navigate("Chats") }} style={{ marginRight: 30 }}>
                    <Ionicons name="chatbox-outline" size={24} color="black" />
                </Pressable>
                <Pressable onPress={() => { navigation.navigate("Settings") }} style={{ marginRight: 30 }}>
                    <Ionicons name="notifications-outline" size={24} color="black" />
                </Pressable>
                { props.user?.personas &&
                    <Pressable onPress={() => { navigation.navigate("AccountSettings") }}>
                        <img src={props.user.personas['public'].profile_pic_url} style={{ width: 30, height: 30, borderRadius: 30 }} />
                    </Pressable>
                }
            </View>
        </View>
    )
}

const mapStateToProps = state => ({
    user: state.user,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);