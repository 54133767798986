import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, TextInput, Text, View, StyleSheet, ScrollView, useWindowDimensions, Linking, Image, Pressable } from 'react-native';
import { connect } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import TwitterTextView from '../common/TextView';

import PostFooter from './PostFooter';

import { TouchableOpacity } from 'react-native-gesture-handler';

const PostLink = React.forwardRef((props, ref) => {
    const [hideTools, setHideTools] = React.useState(false);
    const [linkData, setLinkData] = React.useState(null);
    const [showCaption, setShowCaption] = useState(true);
    const [linkImage, setLinkImage] = useState(null);
    const navigation = useNavigation();

    React.useEffect(() => {
        let title = null;
        let description = null;
        let image = null;
        let favicon = null;
        let link = null;
        let url = null;

        let data = JSON.parse(props.post.link_data);
        if (data) {
            if (data.title) {
                title = data.title.substring(0,70);
                if (data.title.length > 70) {
                    title += '...';
                }
            }
            description = data.description;
            favicon = data.favicon;
            if (data.oEmbed) {
                // preferred
                if (!title && data.oEmbed.title) {
                    title = data.oEmbed.title;
                }
                url = data.oEmbed.url;
                if (data.oEmbed.thumbnails && data.oEmbed.thumbnails.length > 0) {
                    image = data.oEmbed.thumbnails[0].url;
                } else {
                    if (data.open_graph && data.open_graph.images) {
                        image = data.open_graph.images[0].url;
                    } 
                }
            } else {
                if (data.open_graph) {
                    url = data.open_graph.url;
                    if (!title) {
                        title = data.open_graph.title;
                    }
                    if (data.open_graph.images && data.open_graph.images.length > 0) {
                        image = data.open_graph.images[0].url;
                    }
                }
            }

            if (!image && data.images) {
                image = data.images[0].url;
            }
            
            setLinkImage(image);
        }

        setLinkData({
            title,
            image,
            favicon,
            link,
            url,
            description
        })
    }, [props.post]);

    const onPress = (e) => {
        if (!props.disableTap) {
            
        } else {
            navigation.push("PostView", { post: props.post, user: props.post.user, noHeader: true })
        }
    }

    const textOptions = props.isPost == false ? {
        numberOfLines: 4
    } : {};

    return (
        <View style={styles.container}>
            <View style={{ width: "100%", flexDirection: "column", alignItems: "center" }}>
                { linkData &&
                    <View style={styles.link_body}>
                        <View style={styles.link_title}>
                            { !!linkData.favicon &&
                                <Image source={{ uri: linkData.favicon }} style={styles.link_favicon} />
                            }
                            { !props.clickToView &&
                                <Text onPress={() => { try { window.open(linkData.url, "_blank") } catch (e) { console.log(e) } }} style={styles.link_title_text}>{`${linkData.title.trim()}`}</Text>
                            }
                            { props.clickToView &&
                                <Text style={styles.link_title_text}>{`${linkData.title.trim()}`}</Text>
                            }
                        </View>
                        { !!linkImage && !props.hideLinkImage &&
                            <View style={styles.link_image_container}>
                                { !props.clickToView &&
                                    <TouchableOpacity onPress={() => { try { window.open(linkData.url, "_blank") } catch (e) { console.log(e) } }}>
                                        <img src={linkImage} style={styles.link_image} resizeMode={`contain`} />
                                    </TouchableOpacity>
                                }
                                { props.clickToView &&
                                    <img src={linkImage} style={styles.link_image} resizeMode={`contain`} />
                                }
                            </View>
                        }
                        { !props.hideLinkDescription && typeof linkData.description != "undefined" &&
                            <View style={styles.link_description}>
                                { !props.clickToView &&
                                    <Text onPress={() => { try { window.open(linkData.url, "_blank"); } catch (e) { console.log(e) } }}>{`${linkData.description}`}</Text>
                                }
                                { props.clickToView &&
                                    <Text>{`${linkData.description}`}</Text>
                                }
                            </View>
                        }
                    </View>
                }
            </View>
            <PostFooter post={props.post} parent={props.user} isPost={props.isPost} />
            { props.post.post_media != '' &&
                <Pressable onPress={onPress}>
                    <TwitterTextView
                        style={{ fontSize: 20, padding: 25, paddingTop: 0 }}
                        hashtagStyle={{ fontWeight: 'bold', color: '#F72585' }}
                        mentionStyle={{ fontWeight: 'bold', color: '#4361EE' }}
                        onPressMention={(e, mention) => { e.stopPropagation(); navigation.push('Profile', { username: mention.trim().substring(1) }) }}
                        onPressHashtag={(e, hashtag) => { e.stopPropagation(); navigation.push('Search', { terms: hashtag.trim() }) }}
                        {...textOptions}
                    >{props.post.post_media}</TwitterTextView>
                </Pressable>
            }
        </View>
    );
})

const styles = {
    container: {
        flex: 1,
        width: "100%",
        flexDirection: "column",
    },
    big_text_container: {
        flex: 1,
        justifyContent: "center",
        padding: 15
    },
    big_text: {
        fontSize: 30,
        fontFamily: 'Inter_900Black',
        textAlign: "center",
        color: "#000000"
    },
    profile_pic: {
        flex: 1,
        position: "relative",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 2,
        justifyContent: "center"
    },
    video: {
        flex: 1,
        position: "relative",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 2,
    },
    profile_pic_bg: {
        flex: 1,
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 1,
        opacity: 0.5
    },
    link_body: {
        width: "80%",
        zIndex: 2,
        justifyContent: "center",
        flexDirection: "column",
        position: "relative",
        borderRadius: 20,
        backgroundColor: "#ffffff"
    },
    link_favicon: {
        marginRight: 10,
        width: 30,
        height: 30,
    },
    link_title: {
        paddingLeft: 0,
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 10,
    },
    link_title_text: {
        fontWeight: "bold",
        fontSize: 20,
    },
    link_image_container: {
        width: "100%",
        justifyContent: "center",
        flex: 1,
    },
    link_image: {
        width: "100%",
        maxHeight: 600,
        backgroundColor: "#000000",
    },
    link_description: {
        padding: 15
    },
};

const mapStateToProps = state => ({
    user: state.user,
    feed: state.feed
});

const mapDispatchToProps = dispatch => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(PostLink);