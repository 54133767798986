import React, { useEffect, useState } from 'react';
import { FontAwesome5, MaterialIcons, SimpleLineIcons, MaterialCommunityIcons, Entypo } from '@expo/vector-icons';
import { Button, TextInput, Text, View, StyleSheet, ScrollView, useWindowDimensions, TouchableOpacity, Modal, Alert } from 'react-native';
import { connect } from 'react-redux';
import { useNavigation } from '@react-navigation/native';

import API from '../../libs/API';
import { useActionSheet } from '@expo/react-native-action-sheet';

const PostFooter = (props) => {
    const navigation = useNavigation();

    const { showActionSheetWithOptions } = useActionSheet();

    const [showVote, setShowVote] = useState(false);
    const [hasVoted, setHasVoted] = useState(props.post.voted);

    const vote = (points) => {
        setTimeout(() => {
            if (showVote) {
                setShowVote(false);
            }

            API.post(`/post/${props.post.id}/vote`, { points }).then((postResponse) => {
                setHasVoted(true);
                if (postResponse.error) {
                    //reject(postResponse.error);
                    return;
                }

                //resolve(postResponse.vote);
                // at some point will display a lottie component
            }, (err) => {
                //reject('error.api_error');
            });
        }, 200);
    }

    const handleVote = () => {
        if (!props.user.id) {
            return;
        }
        if (props.post.user_id != props.user.id) {
            vote(1);
        } else {
            navigation.push("PostStats", { postId: props.post.id });
        }
    }

    const onPressComment = (e) => {
        if (!props.isPost) {
            e.stopPropagation(); navigation.push("PostView", { postId: props.post.id });
        }
    }

    const openAdminMenu = () => {
        showActionSheetWithOptions(
            {
                options: ["Cancel", "Curate", "Shadow"],
                destructiveButtonIndex: 2,
                cancelButtonIndex: 0,
                userInterfaceStyle: 'dark'
            },
            buttonIndex => {
                if (buttonIndex === 0) {
                    // cancel action
                } else if (buttonIndex === 1) {
                    // report
                    Alert.alert("Curate", "Are you sure you want to curate this post?", [
                        {
                            text: "Cancel"
                        },
                        {
                            text: "Yes. Curate :)",
                            onPress: curatePost
                        },
                    ]);
                } else if (buttonIndex === 2) {
                    // edit
                    Alert.alert("Shadow Post", "Are you sure you want to shadow this post?", [
                        {
                            text: "Cancel"
                        },
                        {
                            text: "Yes. Shadow it.",
                            onPress: shadowPost,
                            style: "destructive"
                        },
                    ]);
                }
            },
        );
    }

    const curatePost = (e) => {
        // curate the thing
        API.post(`/api/v1/post/${props.post.id}/curate`).then((postResponse) => {
            if (postResponse.error) {
                //reject(postResponse.error);
                return;
            }

            Alert.alert("Curated", "This post has been curated.");
        }, (err) => {
            //reject('error.api_error');
            Alert.alert("Oops", "Something went wrong");
        }).catch((e) => {
            Alert.alert("Oops", "Something went wrong");
        });
    }

    const shadowPost = (e) => {
        // shadow this post forever
        API.post(`/api/v1/post/${props.post.id}/shadow`).then((postResponse) => {
            if (postResponse.error) {
                //reject(postResponse.error);
                return;
            }

            Alert.alert("Shadowed", "This post has been shadowed.");
        }, (err) => {
            //reject('error.api_error');
            Alert.alert("Oops", "Something went wrong");
        });
    }

    const votedStyle = hasVoted ? { color: "#F72585" } : {};

    return (
        <View style={[styles.container, props.toolsContainerOverride ]}>
            {/* <View style={{ flex: 1, flexDirection: "row", alignContent: "flex-start"}}>
                { props.post.is_public == true &&
                    <TouchableOpacity style={styles.share_container} onPress={() => { navigation.navigate("SharePost", { postId: props.post.id }) }}>
                        <MaterialCommunityIcons name="share-outline" size={props.toolsContainerIconSize ? props.toolsContainerIconSize : 24} color="black" style={styles.icon} />
                        <Text style={{ marginLeft: 5 }}>Share</Text>
                    </TouchableOpacity>
                }
            </View> */}
            { !!props.user.admin &&
                <TouchableOpacity style={[styles.comments_container, { marginRight: 14 }]} onPress={(e) => { openAdminMenu(); }}>
                    <MaterialIcons name="admin-panel-settings" size={24} color="#f72585" />
                </TouchableOpacity>
            }
            <TouchableOpacity style={styles.comments_container} onPress={(e) => { onPressComment(e); }}>
                <MaterialCommunityIcons name="comment-outline" size={props.toolsContainerIconSize ? props.toolsContainerIconSize : 24} color="black" style={styles.icon} />
                <Text style={styles.vote_count}>{props.post.comment_count}</Text>
            </TouchableOpacity>
            <View style={[styles.vote_container, props.toolsVoteContainerOverride]}>
                <TouchableOpacity onPress={(e) => { handleVote(); e.stopPropagation(); }} onLongPress={(e) => { setShowVote(!showVote); e.stopPropagation(); }} style={{ flexDirection: "row", marginLeft: 10, justifyContent: "center", alignItems: "center" }}>
                    <SimpleLineIcons name="fire" size={props.toolsContainerIconSize ? props.toolsContainerIconSize : 21} color="black" style={{ ...styles.icon, ...votedStyle, ...{ marginTop: -2 } }} />
                    <Text style={[styles.vote_count, { votedStyle }]}>{props.post.points}</Text>
                </TouchableOpacity>
            </View>
            <Modal
                animationType="fade"
                transparent={true}
                presentationStyle="overFullScreen"
                visible={showVote == true}
                onRequestClose={() => {
                    Alert.alert('Modal has been closed.');
                }}>
                <TouchableOpacity onPress={(e) => { e.stopPropagation(); setShowVote(false); }} style={styles.centeredView}>
                    <View style={styles.modalView}>
                        <View style={{ flexDirection: "column" }}>
                            <Text style={styles.vote_title}>Vote up (+1) or down (-).</Text>
                            <Text style={{ fontSize: 16, color: "white", textAlign: "center" }}>Let your opinion be heard.</Text>
                        </View>
                        <TouchableOpacity style={styles.vote_up_container} onPress={(e) => { vote(1); e.stopPropagation(); }}>
                            <Entypo name="chevron-thin-up" size={35} color="#ffffff" />
                            <Text style={styles.vote_up_text}>+1</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.vote_down_container} onPress={(e) => { vote(-1); e.stopPropagation(); }}>
                            <View style={{ flex: 1, alignItems: "flex-end", justifyContent: "flex-end" }}>
                                <Text style={styles.vote_down_text}>-1</Text>
                            </View>
                            <Entypo name="chevron-thin-down" size={35} color="#ffffff" />
                        </TouchableOpacity>
                    </View>
                </TouchableOpacity>
            </Modal>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        position: "relative",
        right: 0,
        top: 0,
        width: "100%",
        padding: 12,
        flexDirection: "row",
        zIndex: 10,
        justifyContent: "flex-end",
        alignItems: "center",
        paddingRight: 15,
        paddingLeft: 15,
        borderTopWidth: 1,
        borderTopColor: "rgba(0,0,0,0.05)"
    },
    vote_container: {
        position: "relative",
        top: 0,
        left: 0,
        zIndex: 20,
        marginLeft: 10
    },
    vote_title: {
        color: "white",
        fontSize: 24,
        fontWeight: "bold",
        textAlign: "center"
    },
    vote_up_down_container: {
        flex: 1,
        borderRadius: 40,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 20,
    },
    vote_up_container: {
        flexDirection: "column",
        width: 80,
        height: 80,
        backgroundColor: "#4895EF",
        borderRadius: 40,
        alignItems: "center",
        marginTop: 20
    },
    vote_up_text: {
        textAlign: "center",
        textAlignVertical: "center",
        fontSize: 20,
        color: "#ffffff"
    },
    vote_down_container: {
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
        borderRadius: 40,
        backgroundColor: "#F72585",
        width: 80,
        height: 80,
        marginTop: 30
    },
    vote_down_text: {
        textAlign: "center",
        textAlignVertical: "bottom",
        alignItems: "flex-end",
        justifyContent: "flex-end",
        fontSize: 20,
        color: "#ffffff"
    },
    vote_count: {
        fontSize: 18,
        color: "black",
        fontWeight: "bold",
        marginLeft: 7
    },
    comments_container: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: 10
    },
    share_container: {
        marginRight: 20,
        flexDirection: "row",
        alignItems: "center",
    },
    icon: {

    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: "rgba(0,0,0,0.4)"
    },
    modalView: {
        margin: 20,
        borderRadius: 20,
        padding: 35,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        flexDirection: "column",
        elevation: 5
    },
    openButton: {
        backgroundColor: '#F194FF',
        borderRadius: 20,
        padding: 10,
        elevation: 2,
    },
    textStyle: {
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    modalText: {
        marginBottom: 15,
        textAlign: 'center',
    }
});

const mapStateToProps = state => ({
    user: state.user
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(PostFooter);