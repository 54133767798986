import * as React from 'react';
import { useState, useEffect, useCallback, Suspense } from 'react';
import { connect } from 'react-redux';
import { useDispatch, Provider } from 'react-redux';
import { Button, Text, View, StyleSheet, TouchableOpacity, Image, Appearance, Pressable, Modal, Alert, ScrollView } from 'react-native';
import { NavigationContainer, useIsFocused } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { ActionSheetProvider } from '@expo/react-native-action-sheet'
import { navigationRef, isReadyRef } from './services/NavigationService';
import { createDrawerNavigator } from '@react-navigation/drawer';

import { MaterialIcons, Feather, FontAwesome5, Ionicons, FontAwesome } from '@expo/vector-icons';

import Feed from './components/Feed/Feed.js';

import { store } from './store/index';
import Storage from './libs/Storage';
import API from './libs/API';
import { syncUser } from './actions/UserActions.js';
import { setShareData } from './actions/FeedActions';
import TopBar from './TopBar';
import UserLeftBar from './components/User/UserLeftBar';
import RightBar from './RightBar';

const UserLogin = React.lazy(() => import('./components/User/UserLogin'));
const LoginLink = React.lazy(() => import('./components/User/UserLogin'));
const UserRegisterFromInvite = React.lazy(() => import('./components/User/UserRegisterFromInvite'));
const Profile = React.lazy(() => import('./components/User/Profile'));
const ConnectionSettings = React.lazy(() => import('./components/User/ConnectionSettings.js'));
// import SharePost from './components/Post/SharePost.js';
const PostView = React.lazy(() => import('./components/Post/PostView.js'));
// import FeedSettings from './components/Feed/FeedSettings.js';
// import NewPost from './components/Post/NewPost.js';
const Search = React.lazy(() => import('./components/Search/Search.js'));
// import Barcode from './components/Search/Barcode.js';
const Channels = React.lazy(() => import('./components/Channels/Channels.js'));
const Channel = React.lazy(() => import('./components/Channels/Channel.js'));
const ChannelMenu = React.lazy(() => import('./components/Channels/ChannelMenu.js'));
const ChannelSettings = React.lazy(() => import('./components/Channels/ChannelSettings.js'));
const ChannelStyle = React.lazy(() => import('./components/Channels/ChannelStyle.js'));
// import SupporterSettings from './components/Channels/SupporterSettings.js';
//import ChannelNewPost from './components/Channels/ChannelNewPost.js';
const NewChannel = React.lazy(() => import('./components/Channels/NewChannel.js'));
const ChannelListMenu = React.lazy(() => import('./components/Channels/ChannelListMenu.js'));
// import Portal from './components/Portal/Portal.js';
const ChannelGroups = React.lazy(() => import('./components/Channels/ChannelGroups.js'));
const ChannelGroup = React.lazy(() => import('./components/Channels/ChannelGroup.js'));
const NewChannelGroup = React.lazy(() => import('./components/Channels/NewChannelGroup.js'));
// import SupportChannel from './components/Channels/SupportChannel.js';
const Account = React.lazy(() => import('./components/Account/Account.js'));
const ChannelMembers = React.lazy(() => import('./components/Channels/ChannelMembers.js'));
const ChannelMember = React.lazy(() => import('./components/Channels/ChannelMember.js'));
const AccountSettings = React.lazy(() => import('./components/Account/AccountSettings.js'));
const EditProfile = React.lazy(() => import('./components/User/EditProfile.js'));
const EditProfileColor = React.lazy(() => import('./components/User/EditProfileColor.js'));
const EditProfilePhoto = React.lazy(() => import('./components/User/EditProfilePhoto.js'));
const EditProfileTagline = React.lazy(() => import('./components/User/EditProfileTagline.js'));
const EditProfileRealname = React.lazy(() => import('./components/User/EditProfileRealname.js'));
const EditProfileSong = React.lazy(() => import('./components/User/EditProfileSong.js'));
const AccountSettingsChangePassword = React.lazy(() => import('./components/User/AccountSettingsChangePassword.js'));
const AccountSettingsContentFilter = React.lazy(() => import('./components/User/AccountSettingsContentFilter.js'));
const AccountSettingsDeleteAccount = React.lazy(() => import('./components/User/AccountSettingsDeleteAccount.js'));
// import AccountSettingsPremium from './components/User/AccountSettingsPremium.js';
const AccountSettingsVerifyAccount = React.lazy(() => import('./components/User/AccountSettingsVerifyAccount.js'));
const Chats = React.lazy(() => import('./components/Chat/Chats.js'));
const ChatRoom = React.lazy(() => import('./components/Chat/ChatRoom.js'));
const NewChat = React.lazy(() => import('./components/Chat/NewChat.js'));
const PostStats = React.lazy(() => import('./components/Post/PostStats.js'));
const UserRegister = React.lazy(() => import('./components/User/UserRegister.js'));
const UserRegisterProfilePic = React.lazy(() => import('./components/User/UserRegisterProfilePic.js'));
const UserRegisterProfileColor = React.lazy(() => import('./components/User/UserRegisterProfileColor.js'));
const TermsOfService = React.lazy(() => import('./components/TermsOfService'));
const PrivacyPolicy = React.lazy(() => import('./components/PrivacyPolicy'));
const UserForgotPassword = React.lazy(() => import('./components/User/UserForgotPassword'));
const NotificationPreferences = React.lazy(() => import('./components/Account/NotificationPreferences'));
// import ShareCode from './components/Account/ShareCode';
const ConnectionList = React.lazy(() => import('./components/User/ConnectionList'));
// import ProfilePageEditor from './components/User/ProfilePageEditor';
// import Contacts from './components/Account/Contacts';

const routeConfig = {
  screens: {
      Feed: '',
      Profile: ':username',
      PostView: 'p/:postId',
      ChannelList: 'channels',
      Channel: {
        path: '/s/:channelId'
      },
      PostView: 'c/p/:postId',
      ChatRoom: 'messenger/:chatId',
      Chats: 'messenger',
    }
}

const unauthedconstRouteConfig = {
  screens: {
    UserRegister: 'register',
    UserRegisterFromInvite: 'i/:inviteCode',
    UserLogin: 'login',
    UserForgotPassword: 'forgotPassword',
    Profile: ':username',
    PostView: 'p/:postId',
    Channel: 's/:channelId',
    LoginLink: 'loginlink'
  }
}

function DetailsScreen() {
  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <Text>Details!</Text>
    </View>
  );
}

const NewPost = (props) => {
  return (
    <View style={{ flex: 1, backroundColor: "black", flexDirection: "column"}}>
        <View style={{ width: "100%", padding: 10, flexDirection: "row", alignItems: "center", backgroundColor: "black" }}>
            <TouchableOpacity onPress={() => { props.goBack(); }} style={{ justifyContent: "center" }}>
                <Ionicons name="arrow-back" size={30} color={"white"} />
            </TouchableOpacity>
        </View>
        <ScrollView style={{ flex: 1, backgroundColor: "black" }}>
            <View style={{ flex: 1, flexDirection: "column", padding: 15, paddingBottom: 30 }}>
                <View style={{ paddingTop: 0, paddingBottom: 0 }}>
                    <Text style={{ fontSize: 40, fontWeight: "bold", color: "white" }}>Wanna make a post? Nice!</Text>
                    <Text style={{ fontSize: 20, fontWeight: "400", color: "rgba(255,255,255,0.6)", marginTop: 10 }}>To post something on Slyde, you gotta get our mobile app.</Text>
                </View>
                <View style={{ width: "35%", height: 4, backgroundColor: "#7807ea", marginTop: 30, marginLeft: 15, marginBottom: 30 }}></View>
                <View style={{ flexDirection: "column", padding: 20, marginTop: 0, paddingTop: 0 }}>
                    <View style={{ borderColor: "white", borderRadius: 15, borderWidth: 2, padding: 20, alignItems: "center", justifyContent: "center", flexDirection: "row" }}>
                        <FontAwesome5 name="app-store-ios" size={24} color="white" />
                        <a href="https://apps.apple.com/us/app/slyde-social/id1536712767" style={{ color: 'white', textDecoration: "none", fontSize: 20, marginLeft: 10 }}>iOS App</a>
                    </View>
                    <View style={{ borderColor: "white", borderRadius: 15, borderWidth: 2, padding: 20, alignItems: "center", justifyContent: "center", flexDirection: "row", marginTop: 20 }}>
                        <FontAwesome name="android" size={24} color="white" />
                        <a href="https://play.google.com/store/apps/details?id=network.slyde.mobile" style={{ color: 'white', textDecoration: "none", fontSize: 20, marginLeft: 10 }}>Android App</a>
                    </View>
                </View>
            </View>
        </ScrollView>
    </View>
  );
}

const HomeStack = createStackNavigator();

function HomeStackScreen() {
  const isFocused = useIsFocused();

  return (
    <HomeStack.Navigator screenOptions={{ headerShown: false, animationEnabled: true, animationTypeForReplace: "push", animationType: "push" }}>
      <HomeStack.Screen name="Feed" component={Feed} options={{ headerShown: false }} />
      <HomeStack.Screen name="Search" component={Search} />
      <HomeStack.Screen name="Profile" component={Profile} options={{ headerShown: false }} />
      <HomeStack.Screen name="PostView" component={PostView} />
      <HomeStack.Screen name="ConnectionSettings" component={ConnectionSettings} />
      <HomeStack.Screen name="PostStats" component={PostStats} />
      <HomeStack.Screen name="ConnectionList" component={ConnectionList} />
      {/* 
      <HomeStack.Screen name="Details" component={DetailsScreen} options={{ headerShown: false }} />
      <HomeStack.Screen name="SharePost" component={SharePost} />
      
      <HomeStack.Screen name="FeedSettings" component={FeedSettings} />
      <HomeStack.Screen name="NewPost" component={NewPost} />
      
      
      
      <HomeStack.Screen name="SupporterSettings" component={SupporterSettings} />
      <HomeStack.Screen name="ChannelNewPost" component={ChannelNewPost} />
      <HomeStack.Screen name="Portal" component={Portal} />
      <HomeStack.Screen name="ShareCode" component={ShareCode} />
      <HomeStack.Screen name="Contacts" component={Contacts} /> 
      <HomeStack.Screen name="Barcode" component={Barcode} />*/}
    </HomeStack.Navigator>
  );
}

const ChannelsStack = createStackNavigator();

const ScreenStack = () => {
  return (

    <ChannelsStack.Navigator screenOptions={{ headerShown: false, animationEnabled: true }}>
      <ChannelsStack.Screen name="ChannelList" component={Channels} options={{ headerShown: false }} />
      <ChannelsStack.Screen name="Channel" component={Channel} />
      <ChannelsStack.Screen name="ChannelMenu" component={ChannelMenu} />
      <ChannelsStack.Screen name="ChannelSettings" component={ChannelSettings} />
      <ChannelsStack.Screen name="ChannelStyle" component={ChannelStyle} />
      <ChannelsStack.Screen name="ChannelGroups" component={ChannelGroups} />
      <ChannelsStack.Screen name="NewChannelGroup" component={NewChannelGroup} />
      <ChannelsStack.Screen name="ChannelGroup" component={ChannelGroup} />
      <ChannelsStack.Screen name="NewChannel" component={NewChannel} />
      <ChannelsStack.Screen name="ChannelListMenu" component={ChannelListMenu} />
      <ChannelsStack.Screen name="ChannelMembers" component={ChannelMembers} />
      <ChannelsStack.Screen name="ChannelMember" component={ChannelMember} />
      <ChannelsStack.Screen name="PostStats" component={PostStats} />
      <ChannelsStack.Screen name="ConnectionList" component={ConnectionList} />
      <ChannelsStack.Screen name="Profile" component={Profile} options={{ headerShown: false }} />
      <ChannelsStack.Screen name="ConnectionSettings" component={ConnectionSettings} />
      <ChannelsStack.Screen name="PostView" component={PostView} />
      <ChannelsStack.Screen name="Search" component={Search} />
      {/* 
      <ChannelsStack.Screen name="Details" component={DetailsScreen} options={{ headerShown: false }} />
      <ChannelsStack.Screen name="SharePost" component={SharePost} />
      <ChannelsStack.Screen name="FeedSettings" component={FeedSettings} />
      <ChannelsStack.Screen name="NewPost" component={NewPost} />
      <ChannelsStack.Screen name="SupporterSettings" component={SupporterSettings} />
      <ChannelsStack.Screen name="ChannelNewPost" component={ChannelNewPost} />
      <ChannelsStack.Screen name="Portal" component={Portal} />
      
      
      
      <ChannelsStack.Screen name="SupportChannel" component={SupportChannel} />
      <ChannelsStack.Screen name="ShareCode" component={ShareCode} />
      <ChannelsStack.Screen name="Barcode" component={Barcode} /> */}
    </ChannelsStack.Navigator>
  )
}

function ChannelsStackScreen() {
  return (
    <ScreenStack />
  );
}

const SettingsStack = createStackNavigator();

function SettingsStackScreen() {
  return (
    <SettingsStack.Navigator screenOptions={{ headerShown: false, animationEnabled: true }}>
      <SettingsStack.Screen name="Settings" component={Account} />
      <SettingsStack.Screen name="PostView" component={PostView} />
      <SettingsStack.Screen name="Profile" component={Profile} options={{ headerShown: false }} />
      <SettingsStack.Screen name="AccountSettings" component={AccountSettings} />
      <SettingsStack.Screen name="NotificationPreferences" component={NotificationPreferences} />
      <SettingsStack.Screen name="AccountSettingsChangePassword" component={AccountSettingsChangePassword} />
      <SettingsStack.Screen name="AccountSettingsContentFilter" component={AccountSettingsContentFilter} />
      <SettingsStack.Screen name="AccountSettingsVerifyAccount" component={AccountSettingsVerifyAccount} />
      <SettingsStack.Screen name="AccountSettingsDeleteAccount" component={AccountSettingsDeleteAccount} />
      <SettingsStack.Screen name="TermsOfService" component={TermsOfService} />
      <SettingsStack.Screen name="PrivacyPolicy" component={PrivacyPolicy} />
      <SettingsStack.Screen name="EditProfile" component={EditProfile} />
      <SettingsStack.Screen name="EditProfileColor" component={EditProfileColor} />
      <SettingsStack.Screen name="EditProfileTagline" component={EditProfileTagline} />
      <SettingsStack.Screen name="EditProfileRealname" component={EditProfileRealname} />
      <SettingsStack.Screen name="EditProfileSong" component={EditProfileSong} />
      <SettingsStack.Screen name="EditProfilePhoto" component={EditProfilePhoto} />
      <SettingsStack.Screen name="PostStats" component={PostStats} />
      <SettingsStack.Screen name="ConnectionList" component={ConnectionList} />
      {/*
      <SettingsStack.Screen name="Details" component={DetailsScreen} />
      <SettingsStack.Screen name="AccountSettingsPremium" component={AccountSettingsPremium} />
      <SettingsStack.Screen name="Barcode" component={Barcode} />
      <SettingsStack.Screen name="ShareCode" component={ShareCode} />
      <SettingsStack.Screen name="ProfilePageEditor" component={ProfilePageEditor} />
      <SettingsStack.Screen name="Contacts" component={Contacts} /> */}
    </SettingsStack.Navigator>
  );
}

const ChatStack = createStackNavigator();

const ChatScreenStack = () => {
  return (

    <ChatStack.Navigator screenOptions={{ headerShown: false, animationEnabled: true }}>
      <ChatStack.Screen name="Chats" component={Chats} options={{ headerShown: false }} />
      <ChatStack.Screen name="PostStats" component={PostStats} />
      <ChatStack.Screen name="ChatRoom" component={ChatRoom} options={{ headerShown: false, tabBarVisible: false, keyboardHidesTabBar: true }} />
      <ChatStack.Screen name="NewChat" component={NewChat} /><ChatStack.Screen name="Profile" component={Profile} options={{ headerShown: false }} />
      {/* 
      
      
      <ChatStack.Screen name="Barcode" component={Barcode} /> */}
    </ChatStack.Navigator>
  )
}

const LoginStack = createStackNavigator();

const LoginScreenStack = () => {
  return (

    <LoginStack.Navigator screenOptions={{ headerShown: false, cardStyle: { flex: 1 }, animationEnabled: true }}>
      <LoginStack.Screen name="UserLogin" component={UserLogin} options={{ headerShown: false, title: "Slyde - Login" }} />
      <LoginStack.Screen name="LoginLink" component={UserLogin} options={{ headerShown: false, title: "Slyde - Login" }} />
      <LoginStack.Screen name="UserRegister" component={UserRegister} options={{ headerShown: false, tabBarVisible: false, title: 'Slyde - Register' }} />
      <LoginStack.Screen name="UserRegisterFromInvite" component={UserRegisterFromInvite} options={{ headerShown: false, tabBarVisible: false, title: 'Slyde - Register' }} />
      <LoginStack.Screen name="TermsOfService" component={TermsOfService} />
      <LoginStack.Screen name="PrivacyPolicy" component={PrivacyPolicy} />
      <LoginStack.Screen name="UserForgotPassword" component={UserForgotPassword} options={{ headerShown: false, tabBarVisible: false }} />
      <LoginStack.Screen name="Profile" component={Profile} options={{ headerShown: false }} />
      <LoginStack.Screen name="PostView" component={PostView} />
      <LoginStack.Screen name="Channel" component={Channel} />
    </LoginStack.Navigator>
  )
}

const RegisterStack = createStackNavigator();

const RegisterScreenStack = () => {
  return (

    <RegisterStack.Navigator screenOptions={{ headerShown: false, animationEnabled: true }}>
      <RegisterStack.Screen name="UserRegisterProfilePic" component={UserRegisterProfilePic} options={{ headerShown: false, tabBarVisible: false }} />
      <RegisterStack.Screen name="UserRegisterProfileColor" component={UserRegisterProfileColor} options={{ headerShown: false, tabBarVisible: false }} />
      <RegisterStack.Screen name="TermsOfService" component={TermsOfService} />
      <RegisterStack.Screen name="PrivacyPolicy" component={PrivacyPolicy} />
    </RegisterStack.Navigator>
  )
}

const Stack = createStackNavigator();

function App(props) {
  const [loading, setLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showNewPost, setShowNewPost] = useState(false);
  const [initialLocation] = useState(window.location.href);
  const [hasToken] = useState(localStorage.getItem('jwt'));

  const defaultBottomStyle = Appearance.getColorScheme() === 'darkk' ? {
    backgroundColor: "#000000",
    color: "#ffffff",
    borderTopColor: "transparent"
  } : {
    backgroundColor: "#ffffff",
    color: "#000000",
    borderTopColor: "rgba(0,0,0,0.1)"
  };

  const [bottomStyle, setBottomStyle] = useState(defaultBottomStyle);

  useEffect(() => {
    if (props.feed.items[props.feed.activeIndex] && props.feed.items[props.feed.activeIndex].style) {
      let bottomStyleNew = { ...bottomStyle };
      bottomStyleNew.backgroundColor = props.feed.items[props.feed.activeIndex].style.backgroundColor;
      bottomStyleNew.color = props.feed.items[props.feed.activeIndex].style.color;
      setBottomStyle(bottomStyleNew);
    }
  }, [props.feed.activeIndex]);

  useEffect(() => {
    if (props.settings.tabStyle) {
      let bottomStyleNew = { ...bottomStyle };
      bottomStyleNew.backgroundColor = props.settings.tabStyle.backgroundColor;
      bottomStyleNew.color = props.settings.tabStyle.color;
      setBottomStyle(bottomStyleNew);
    } else {
      setBottomStyle({ ...defaultBottomStyle });
    }
  }, [props.settings.tabStyle]);

  useEffect(() => {
    if (props.user.id) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [props.user]);

  useEffect(() => {
    let token = Storage.get('jwt').then((token) => {
      if (token) {
        // load user
        API.setJWT(token);
        props.syncUser();
        setLoading(false);
      } else {
        setLoading(false);
      }
    }, (err) => {
      console.log("Nothing!", err);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    console.log(loading, "LOADING", initialLocation, initialLocation.split(window.location.host));
    if (!loading) {
      navigationRef.current.navigate(initialLocation.split(window.location.host)[1]);
    } else {

    }
  }, [loading]);

  const homeIcon = ({ focused, color, size }) => {
    const tabStyle = focused ? { borderLeftWidth: 2, borderLeftColor: bottomStyle.color } : { };

    return (
      <View style={tabStyle}>
        <Feather name="home" size={24} color={bottomStyle.color} style={{ marginLeft: 5 }} />
      </View>
    )
  }

  const channelIcon = ({ focused, color, size }) => {
    const tabStyle = focused ? { borderLeftWidth: 2, borderLeftColor: bottomStyle.color } : { };

    return (
      <View style={tabStyle}>
        <FontAwesome5 name="hashtag" size={24} color={bottomStyle.color} style={{ marginLeft: 5 }} />
      </View>
    )
  }

  const accountIcon = ({ focused, color, size }) => {
    const tabStyle = focused ? { borderLeftWidth: 2, borderLeftColor: bottomStyle.color } : { };

    return (
      <View style={tabStyle}>
        {!props.user.personas || props.user.personas['public'].profile_pic_url == null &&
            <MaterialIcons name="account-circle" size={24} color={bottomStyle.color} style={{ marginLeft: 5 }} />
        }
        {props.user.personas && props.user.personas['public'].profile_pic_url != null &&
            <Image source={{ uri: props.user.personas['public'].profile_pic_url }} style={{ width: 24, height: 24, borderRadius: 24, marginLeft: 7 }} />
        }
      </View>
    )
  }

  const chatIcon = ({ focused, color, size }) => {
    const tabStyle = focused ? { borderLeftWidth: 2, borderLeftColor: bottomStyle.color } : { };

    return (
      <View style={tabStyle}>
        <Ionicons name="ios-chatbubbles-outline" size={24} color={bottomStyle.color} style={{ marginLeft: 5 }} />
      </View>
    )
  }

  if (loading && !props.user.id && !hasToken) {
    return (
      <View><Text>...</Text></View>
    )
  }

  if (!isLoggedIn && !loading && !hasToken) {
    return (
      <Provider store={store}>

        <ActionSheetProvider>
          <NavigationContainer ref={navigationRef}
            linking={{ config: unauthedconstRouteConfig }}>
              <View style={{ flex: 1, alignItems: "center", backgroundImage: "linear-gradient(31deg, rgba(63,55,201,1) 0%, rgba(63,55,201,1) 35%, rgba(220,0,255,1) 100%)", paddingTop: 20 }}>
              <View style={{ backgroundColor: "white", width: "100%", flex: 1, maxWidth: 800, boxShadow: "0px 0px 10px rgba(0,0,0,0.5)" }}>
              <Suspense fallback={<View><Text>...</Text></View>}>
                <LoginScreenStack />
              </Suspense>
              </View>
              </View>
          </NavigationContainer>
        </ActionSheetProvider>
      </Provider>)
  }

  if (props.user.didJustRegister) {
    return (
      <Provider store={store}>
        <NavigationContainer ref={navigationRef}
          linking={{ config: routeConfig }}>
            <View style={{ flex: 1, alignItems: "center", backgroundImage: "linear-gradient(31deg, rgba(63,55,201,1) 0%, rgba(63,55,201,1) 35%, rgba(220,0,255,1) 100%)", paddingTop: 20 }}>
              <View style={{ backgroundColor: "white", width: "100%", flex: 1, maxWidth: 800, boxShadow: "0px 0px 10px rgba(0,0,0,0.5)" }}>
          <Suspense fallback={<View><Text>...</Text></View>}>
            <RegisterScreenStack />
          </Suspense>
          </View></View>
        </NavigationContainer>
      </Provider>
    )
  }

  const newPostButton = (barProps) => {
    if (showNewPost && props.feed.activeChannel && !props.feed.activeChannel.canPost) {
      Alert.alert("Oops!", `Can't post in #${props.feed.activeChannel.tag}. This channel only let's certain people post.`);
      setShowNewPost(false);
    }
    return (
      <View style={{ flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100%", paddingLeft: 15, paddingRight: 15, paddingTop: 0 }}>
        <Pressable onPress={() => { setShowNewPost(!showNewPost) }} style={{ width: 40, height: 30, justifyContent: "center", alignItems: "center", backgroundColor: bottomStyle.color, borderRadius: 8 }}>
          <FontAwesome name="plus" size={20} color={bottomStyle.backgroundColor} />
        </Pressable>
        <Modal visible={showNewPost} onRequestClose={() => { setShowNewPost(false) }} animationType="slide" transparent={true} style={{ backgroundColor: "rgba(0,0,0,0.4)" }}>
        <View style={{ backgroundColor: "white", flex: 1, borderTopLeftRadius: 20, borderTopRightRadius: 20, marginTop: 50, flexDirection: "column", overflow: "hidden" }}>
          {/* {props.feed.activeChannel !== null && props.feed.activeChannel.canPost &&
            <ChannelNewPost goBack={() => { setShowNewPost(false) }} />
          } */}
          <NewPost goBack={() => { setShowNewPost(false) }} requestShow={() => setShowNewPost(true)} show={props.feed.activeChannel === null} />
        </View>
        </Modal>
      </View>
    )
  }

  return (
    <Provider store={store}>
      <ActionSheetProvider>
        <NavigationContainer ref={navigationRef}
          onReady={() => {
            isReadyRef.current = true;
          }}
          linking={{ config: routeConfig }}>
            <View style={{ flex: 1, alignItems: "center", backgroundImage: "linear-gradient(31deg, rgba(63,55,201,1) 0%, rgba(63,55,201,1) 35%, rgba(220,0,255,1) 100%)", paddingTop: 20 }}>
              <View style={{ width: "100%", maxWidth: 1300, flexDirection: "row"}}>
                <TopBar />
              </View>
              <View style={{ flex: 1, maxWidth: 1300, width: "100%", flexDirection: "row" }}>
                <View style={{ width: 300, height: "100%", backgroundColor: "white" }}>
                  <UserLeftBar />
                </View>
              <Suspense fallback={<View><Text>...</Text></View>}>
                <View style={{ backgroundColor: "white", flex: 1 }}>
                  <Stack.Navigator screenOptions={{ drawerType: "permanent", lazy: false, headerShown: false, tabBarShowLabel: false, tabBarActiveTintColor: bottomStyle.backgroundColor, activeTintColor: "#000000", keyboardHidesTabBar: true }}>
                    <Stack.Group>
                      <Stack.Screen name="Feed" component={Feed} options={{ headerShown: false }} />
                      <Stack.Screen name="Search" component={Search} options={{ headerShown: false }} />
                      <Stack.Screen name="Profile" component={Profile} options={{ headerShown: false, presentation: "modal" }} />
                      <Stack.Screen name="ConnectionSettings" component={ConnectionSettings} />
                      <Stack.Screen name="PostStats" component={PostStats} />
                      <Stack.Screen name="ConnectionList" component={ConnectionList} />
                      <Stack.Screen name="Chats" component={Chats} options={{ headerShown: false }} />
                      <Stack.Screen name="ChatRoom" component={ChatRoom} options={{ headerShown: false, tabBarVisible: false, keyboardHidesTabBar: true }} />
                      <Stack.Screen name="NewChat" component={NewChat} />
                      <Stack.Screen name="Settings" component={Account} />
                      <Stack.Screen name="AccountSettings" component={AccountSettings} />
                      <Stack.Screen name="NotificationPreferences" component={NotificationPreferences} />
                      <Stack.Screen name="AccountSettingsChangePassword" component={AccountSettingsChangePassword} />
                      <Stack.Screen name="AccountSettingsContentFilter" component={AccountSettingsContentFilter} />
                      <Stack.Screen name="AccountSettingsVerifyAccount" component={AccountSettingsVerifyAccount} />
                      <Stack.Screen name="AccountSettingsDeleteAccount" component={AccountSettingsDeleteAccount} />
                      <Stack.Screen name="TermsOfService" component={TermsOfService} />
                      <Stack.Screen name="PrivacyPolicy" component={PrivacyPolicy} />
                      <Stack.Screen name="EditProfile" component={EditProfile} />
                      <Stack.Screen name="EditProfileColor" component={EditProfileColor} />
                      <Stack.Screen name="EditProfileTagline" component={EditProfileTagline} />
                      <Stack.Screen name="EditProfileRealname" component={EditProfileRealname} />
                      <Stack.Screen name="EditProfileSong" component={EditProfileSong} />
                      <Stack.Screen name="EditProfilePhoto" component={EditProfilePhoto} />
                      <Stack.Screen name="ChannelList" component={Channels} options={{ headerShown: false }} />
                      <Stack.Screen name="Channel" component={Channel} options={{ headerShown: false }} />
                      <Stack.Screen name="ChannelMenu" component={ChannelMenu} options={{ headerShown: false }} />
                      <Stack.Screen name="ChannelSettings" component={ChannelSettings} options={{ headerShown: false }} />
                      <Stack.Screen name="ChannelStyle" component={ChannelStyle} options={{ headerShown: false }} />
                      <Stack.Screen name="ChannelGroups" component={ChannelGroups} options={{ headerShown: false }} />
                      <Stack.Screen name="NewChannelGroup" component={NewChannelGroup} options={{ headerShown: false }} />
                      <Stack.Screen name="ChannelGroup" component={ChannelGroup} options={{ headerShown: false }} />
                      <Stack.Screen name="NewChannel" component={NewChannel} options={{ headerShown: false }} />
                      <Stack.Screen name="ChannelListMenu" component={ChannelListMenu} options={{ headerShown: false }} />
                      <Stack.Screen name="ChannelMembers" component={ChannelMembers} options={{ headerShown: false }} />
                      <Stack.Screen name="ChannelMember" component={ChannelMember} options={{ headerShown: false }} />
                    </Stack.Group>
                    <Stack.Group screenOptions={{ presentation: "modal" }}>
                      <Stack.Screen name="PostView" component={PostView} options={{ presentation: "modal" }} />
                    </Stack.Group>
                  </Stack.Navigator>
                </View>
            </Suspense>
            <View style={{ width: 300, height: "100%", backgroundColor: "white" }}>
              <RightBar />
            </View>
            </View>
          </View>
        </NavigationContainer>
      </ActionSheetProvider>
    </Provider>
  );
}

const mapStateToProps = state => ({
  user: state.user,
  feed: state.feed,
  settings: state.settings,
  notifications: state.notifications,
  chat: state.chat
});

const mapDispatchToProps = dispatch => ({
  syncUser: () => dispatch(syncUser()),
  setShareData: (data) => dispatch(setShareData(data)),
});

const AppConnected = connect(mapStateToProps, mapDispatchToProps)(App);

function AppContainer() {
  return (
    <Provider store={store}>
      <AppConnected />
    </Provider>
  );
}

const styles = StyleSheet.create({
  loader: {
    width: 200,
    height: 200,
  }
});

export default AppContainer;