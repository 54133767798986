import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View } from 'react-native';
import './src/index.css';
import App from './src/App';
import MobileApp from './src/MobileApp'

export default function MainApp() {
  if (window.innerWidth < 1200) {
    return (<MobileApp />);
  } else {
    return(<App />);
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
