import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, TextInput, Text, View, StyleSheet, ScrollView, useWindowDimensions, SafeAreaView, Image, TouchableWithoutFeedback } from 'react-native';
import { connect } from 'react-redux';
import { MaterialCommunityIcons, Octicons } from '@expo/vector-icons';

import { getFeed } from '../../actions/FeedActions';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { useNavigation } from '@react-navigation/native';
import TwitterTextView from '../common/TextView';
import ThemeSong from './ThemeSong';
import { prettyTime } from '../../libs/Date';

const FeedItemFooter = (props) => {
    const navigation = useNavigation();
    useEffect(() => {
        // footer stuff
    }, []);

    const onPress = (e) => {
        console.log(e);
        if (e) {
            e.stopPropagation();
        }

        if (props.tapToOpen) {
            //navigation.push("PostView", { post: props.post, user: props.post.user });
        }
    }

    return (
        <View style={styles.container}>
            { !props.noName &&
                <TouchableOpacity onPress={() => { navigation.push("Profile", { username: props.parent.username }) }}>
                    <View style={styles.name}>
                        <Image source={{ uri: props.parent.profile_pic_url }} style={styles.profile_pic} resizeMode={`cover`} />
                        <View style={{ justifyContent: "center", flexDirection: "column" }}>
                            <Text style={styles.user_name}>{props.parent.real_name ? props.parent.real_name : props.parent.username}</Text>
                            <Text style={{ fontSize: 12, color: "rgba(255,255,255,0.8)" }}>{prettyTime(props.post.created_at)}</Text>
                        </View>
                        { props.parent.verified == true &&
                            <View style={{ justifyContent: "center", flexDirection: "column", marginLeft: 7, marginTop: 2 }}>
                                <Octicons name="verified" size={16} color="#4895EF" />
                            </View>
                        }
                    </View>
                </TouchableOpacity>
            }
            { props.post.channel_id != null && !props.noCaption && !props.smallMode &&
                <TouchableOpacity onPress={(e) => { navigation.push("Channel", { channelId: props.post.channel_id }) }} style={styles.caption}>
                    <TwitterTextView
                        style={{ ...styles.caption_text, fontSize: 16 }}
                        hashtagStyle={{ fontWeight: 'bold', color: '#48cae4' }}
                        mentionStyle={{ fontWeight: 'bold', color: '#4361EE' }}
                        numberOfLines={1}
                        {...props.captionProps}
                    >{`Posted in #${props.post.channel_tag}`}</TwitterTextView>
                </TouchableOpacity>
            }
            { typeof props.caption != "undefined" && props.caption !== null && props.caption.length > 0 && !props.noCaption &&
                <TouchableOpacity onPress={(e) => { onPress(e) }} style={styles.caption}>
                    <TwitterTextView
                        style={styles.caption_text}
                        hashtagStyle={{ fontWeight: 'bold', color: '#F72585' }}
                        mentionStyle={{ fontWeight: 'bold', color: '#4361EE' }}
                        numberOfLines={3}
                        onPressMention={(e, mention) => { navigation.push('Profile', { username: mention.trim().substring(1) }) }}
                        onPressHashtag={(e, hashtag) => { navigation.push('Search', { terms: hashtag.trim() }) }}
                        {...props.captionProps}
                    >{props.caption}</TwitterTextView>
                </TouchableOpacity>
            }
            { props.post.parent_post_id != null &&
                <TouchableOpacity onPress={() => { navigation.push("Profile", { username: props.post.parent_username }) }} style={styles.shared}>
                    <View style={styles.name}>
                        <Image source={{ uri: props.post.parent_profile_pic_url }} style={styles.shared_pic} resizeMode={`cover`} />
                        <View style={{ flex: 1, justifyContent: "center", flexDirection: "column" }}>
                            <Text style={styles.shared_original}><MaterialCommunityIcons name="share" size={18} color="rgba(255,255,255,0.7)" /> Originally Posted By:</Text>
                            <Text style={styles.user_name}>{props.post.parent_real_name ? props.post.parent_real_name : props.post.parent_username}</Text>
                        </View>
                        { props.post.parent_verified == true &&
                            <View style={{ justifyContent: "center", flexDirection: "column", marginLeft: 4, marginTop: 1 }}>
                                <Octicons name="verified" size={10} color="#4895EF" />
                            </View>
                        }
                    </View>
                </TouchableOpacity>
            }
            { props.parent.theme_song && props.parent.theme_song.preview && !props.noThemeSong &&
                <ThemeSong parent={props.parent} />
            }
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flexDirection: "column",
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%",
        padding: 10,
        paddingTop: 20,
        zIndex: 10,
    },
    name: {
        flex: 1,
        flexDirection: "row"
    },
    user_name: {
        color: '#ffffff',
        fontSize: 16,
        fontWeight: "bold",
    },
    profile_pic: {
        width: 40,
        height: 40,
        borderRadius: 7,
        margin: 7
    },
    caption: {
        marginBottom: 10,
        marginTop: 10,
        paddingLeft: 10,
        paddingRight: 10
    },
    caption_text: {
        color: '#ffffff',
        fontSize: 16
    },
    shared: {
        borderRadius: 10,
        marginTop: 10
    },
    shared_original: {
        fontSize: 12,
        color: "rgba(255,255,255,0.7)"
    },
    shared_pic: {
        width: 24,
        height: 24,
        borderRadius: 5,
        margin: 5
    }
});

const mapStateToProps = state => ({
    user: state.user
});

const mapDispatchToProps = dispatch => ({
    getFeed: page => dispatch(getFeed(page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedItemFooter);